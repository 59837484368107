<template>
    <div class="table" :style="{fontSize: props.style.fontSize}">
      <div class="thead">
        <div class="highlight"></div>
        <slot name="header"></slot>
        <div class="action"></div>
      </div>
      <div class="tbody" ref="tbody" :style="{'overflow-y': overflow ? 'auto' : 'hidden'}">
        <slot name="row"></slot>
      </div>
    </div>
</template>

<script setup>

import { onMounted, onUnmounted, provide, ref} from 'vue'

const emit = defineEmits(['bodyHeight']);

const props = defineProps({
  style: {
    type: Object,
    default: {}
  },
  overflow: {
    type: Boolean,
    default: false
  },
  align: {
    type: String,
    default: ''
  },
  hover: {
    type: Boolean,
    default: false
  },
  select: {
    type: Boolean,
    default: false
  },
  multiple: {
    type: Boolean,
    default: true
  },
  delete: {
    type: Boolean,
    default: false
  },
  undo: {
    type: Boolean,
    default: true
  },
  edit: {
    type: Boolean,
    default: false
  }
})

const tbody = ref(null);
const selected = ref([]);
const deleted = ref([]);

const publishHeight = () => {
  try {
    emit('bodyHeight', tbody.value.clientHeight);
  } catch (e) {}

}

onMounted(() => {
  window.addEventListener('resize', (e) => publishHeight());
  emit('bodyHeight', tbody.value.clientHeight);
});

onUnmounted(() => {
  window.removeEventListener('resize', (e) => publishHeight());
});

provide('select', props.select);
provide('delete', props.delete);
provide('hover', props.hover);
provide('undo', props.undo);
provide('edit', props.edit);
provide('multiple', props.multiple);

provide('selectState', selected.value);
provide('deleteState', deleted.value);

</script>

<style scoped lang='scss'>

  @import '../../assets/scss/variables.scss';
  @import '../../assets/scss/mixins.scss';

  .table{
    @include flex(column, flex-start, flex-start);
    width: 100%;
    height: 100%;
    .thead{
      @include flex(row, flex-start, center);
      @include rem(min-height, 40px);
      width: 100%;
      border-bottom:0.15rem solid #2a60c8;
      overflow: hidden;

      .highlight{
        @include rem(min-width, 5px);
      }

      .action{
        @include rem(min-width, 40px);
      }
    }
    .tbody{
      position: relative;
      @include flex(column, flex-start, flex-start);
      width: 100%;
      overflow: hidden;
      flex-grow: 1;
    }

    .tbody::-webkit-scrollbar {
      width: .7rem; /* Set the width of the scrollbar */
      height: .7rem;
    }

    /* Style the scrollbar thumb (the draggable part) */
    .tbody::-webkit-scrollbar-thumb {
      background-color: darkgray; /* Set the background color */
      border-radius: .5rem; /* Set the border radius for rounded corners */
    }

    .tbody::-webkit-scrollbar-thumb:active {
      background-color: gray; /* Change color when thumb is grabbed/active */
    }

  }


</style>