import { defineStore } from 'pinia';
import { reactive, ref, watch } from 'vue';
import { useConfigStore } from './configStore';
import { useOrganizationStore } from './organizationStore';
import { cloneDeep, isEqual } from 'lodash';
import { saveAs } from 'file-saver';

export const useLanguagesStore = defineStore('languages', {
	state: () => ({
		currentLanguageTab: 'generalTab',
		isLoading: ref(false),
		defaultLanguages: [],
		customLanguages: [],
		emptyTemplate: [],
		originalLanguageData: ref([]),
		displayLanguages: ref([]),
		languageDataChanged: ref(false),
		currentLanguage: ref({}),
		showNewDialog: ref(false),
		enableLanguageSelection: ref(false),
		enableLanguageView: ref(false),
		languageClass: '', // initial class
		alert: ref()
	}),

	actions: {
		async getLanguages(initialLoad = false, tabName = 'general') {
			const configStore = useConfigStore();
			const organizationStore = useOrganizationStore();

			this.isLoading = true;
			this.reset();

			let params = {
				orgId: configStore.organization.id,
				divId: '',
				buId: '',
				muId: '',
				idToQuery: organizationStore.selectedNode.id,
				queryType: "languages"
			};

			switch (organizationStore.selectedNode.type) {
				case 'division':
					params.divId = organizationStore.selectedNode.id;
					break;
				case 'businessUnit':
					params.buId = organizationStore.selectedNode.id;
					params.divId = organizationStore.selectedNode.division.id;
					break;
				case 'managementUnit':
					params.muId = organizationStore.selectedNode.id;
					params.buId = organizationStore.selectedNode.businessUnit.id;
					params.divId = organizationStore.selectedNode.division.id; ``
					break;
				case 'agent':
					params.agentId = organizationStore.selectedNode.id;
					params.buId = organizationStore.selectedNode.buId;
					break;
				default:
					break;
			}

			// Query DynamoDB
			const axios = await configStore.getAxios();
			const retVal = await axios.post('/get-cockpit-settings', params);
			// console.log(`retVal -> ${JSON.stringify(retVal, null, 2)}`);
			const getLanguagesResult = retVal.data.body.body
			// const getLanguagesResult = JSON.parse(await post('/get-cockpit-languages', params)).body;

			// Apply any custom options to the default languages
			if (getLanguagesResult.assignedLanguages.customSettings && Object.keys(getLanguagesResult.assignedLanguages.customSettings).length > 0) {
				// Merge the default values and the custom ones. We only need to check default languages as custom languages are stored as a whole language
				// Can't use lodash as we want to minimise stored JSON
				for (const iso639 of Object.keys(getLanguagesResult.assignedLanguages.customSettings)) {
					// get the language defaults
					const lang = getLanguagesResult.defaultLanguages.find(item => item.iso639 === iso639);
					const customSettings = getLanguagesResult.assignedLanguages.customSettings[iso639];
					// now check if there is a custom setting for this language
					for (const customSetting of Object.keys(customSettings)) {
						for (const tab of lang.tabs) {
							for (const section of tab.tabData) {
								const opt = section.sectionData.find(setting => setting.optionName === customSetting);
								if (opt) {
									opt.selectedValue = getLanguagesResult.assignedLanguages.customSettings[iso639][customSetting];
									break;
								}
							}
						}
					}
				}
			}

			// Setup the display languages
			this.displayLanguages = [...getLanguagesResult.defaultLanguages, ...getLanguagesResult.customLanguages];

			if (getLanguagesResult.assignedLanguages.assigned) {
				for (const language of this.displayLanguages) {
					language.assigned = getLanguagesResult.assignedLanguages.assigned.some(item => item === language.iso639);
					language.isCurrentlySelected = false;
				}
			}

			// Get any custom Languages
			for (const language of this.displayLanguages) {
				const found = this.defaultLanguages.find(item => item.iso639 === language.iso639);
				if (!found) {
					this.customLanguages.push(language);
				}
			}

			// Store all the original language data for reverting and comparisons
			this.originalLanguageData = cloneDeep(this.displayLanguages);

			this.isLoading = false;
			// console.log(`displayLanguages -> ${JSON.stringify(this.displayLanguages, null, 2)}`);
		},

		async setInitialTemplate(tabName = 'general') {
			// Use the English language as the default
			let template = cloneDeep(this.defaultLanguages.find(template => template.iso639 === 'en'));

			// Loop through all the tabs and empty the values
			for (const tab of template.tabs) {
				for (const tabData of tab.tabData) {
					for (const section of tabData.sectionData) {
						section.selectedValue = '';
					}
				}
			}
			this.emptyTemplate = template;
			this.currentLanguage = this.emptyTemplate;
			this.displayLanguages = this.defaultLanguages;
		},

		async setDisplayLanguage(iso639 = 'en') {
			this.currentLanguage = this.displayLanguages.find(item => item.iso639 === iso639);
			this.enableLanguageSelection = true;
		},

		async addLanguage(data) {
			// console.log(`addLanguage Called ${data.name} ${data.isoCode}`);
			const configStore = useConfigStore();
			const organizationStore = useOrganizationStore();
			let success = { ...this.alert, title: 'Language added', message: 'Language added succesfully.', type: 'success', enabled: true, timeout: 3000 };
			let failure = { ...this.alert, title: 'Error adding Language', message: 'Error adding Language.', type: 'failure', enabled: true, timeout: 3000 };

			this.isLoading = true;

			if (this.languageExists(data.iso639)) {
				failure = { ...this.alert, title: 'Error adding Language', message: `${data.language} already exists for ${organizationStore.selectedNode.name}.`, type: 'failure', enabled: true, timeout: 3000 };
				this.alert = failure;
			}
			else {
				let newLanguage = cloneDeep(this.defaultLanguages.find(item => item.iso639 === 'en'));
				newLanguage.language = data.language;
				newLanguage.iso639 = data.iso639;
				newLanguage.assigned = false;
				this.customLanguages.push(newLanguage);

				try {
					const params = {
						OrgId: configStore.organization.id,
						Id: organizationStore.selectedNode.id,
						RecordType: "cockpit_config",
						AttributeName: "custom_languages",
						Settings: this.customLanguages
					};

					const axios = await configStore.getAxios();
					await axios.post('/put-cockpit-settings', params)
						.then(() => {
							this.alert = success;
						})
          .catch((err) => {
            console.log(err);
            this.alert = failure;
          })
				} catch (error) {
					console.log(`Oops -> ${error}`)
				}
			}

			await this.getLanguages();
			this.setDisplayLanguage(data.iso639);
			this.isLoading = false;
		},

		async importLanguage(language) {
			const configStore = useConfigStore();
			const organizationStore = useOrganizationStore();

			let success = { ...this.alert, title: 'Language imported', message: 'Language imported succesfully.', type: 'success', enabled: true, timeout: 3000 };
			let failure = { ...this.alert, title: 'Error impoorting Language', message: 'Error impoorting Language.', type: 'failure', enabled: true, timeout: 3000 };

			this.isLoading = true;

			try {
				// Add the imported Language to the custom languages
				this.customLanguages.push(language);

				const params = {
					OrgId: configStore.organization.id,
					Id: organizationStore.selectedNode.id,
					RecordType: "cockpit_config",
					AttributeName: "custom_languages",
					Settings: this.customLanguages
				};

				const axios = await configStore.getAxios();
				await axios.post('/put-cockpit-settings', params)
					.then(() => {
						this.alert = success;
					})
          .catch((err) => {
            console.log(err);
            this.alert = failure;
          })

				// await post('/put-cockpit-settings', params);
			} catch (error) {
				console.log(`Oops -> ${error}`)
			}

			this.getLanguages();
			this.isLoading = false;
		},

		async exportLanguage(iso639) {
			let success = { ...this.alert, title: 'Language exported', message: 'Language exported succesfully.', type: 'success', enabled: true, timeout: 3000 };
			let failure = { ...this.alert, title: 'Error exporting Language', message: 'Error exporting Language.', type: 'failure', enabled: true, timeout: 3000 };

			this.isLoading = true;
			let languageToExport = this.displayLanguages.find(item => item.iso639 === iso639);
			var fileName = `${languageToExport.language}.json`;
			// Create a blob of the data
			var fileToSave = new Blob([JSON.stringify(languageToExport, undefined, 2)], {
				type: 'application/json',
				name: fileName
			});

			// this is using the fileSaver lib : https://github.com/eligrey/FileSaver.js
			try {
				saveAs(fileToSave, fileName);
				this.alert = success;
			} catch (error) {
				console.log(`Oops -> ${error}`)
				this.alert = failure;
			}
			// saveAs(fileToSave, fileName);
			this.isLoading = false;

		},

		async deleteLanguage(iso639) {
			console.log(`deleteLanguage Called ${iso639}`);
			const organizationStore = useOrganizationStore();
			const configStore = useConfigStore();
			let success = { ...this.alert, title: 'Language deleted', message: 'Language deleted succesfully.', type: 'success', enabled: true, timeout: 3000 };
			let failure = { ...this.alert, title: 'Error deleting Language', message: 'Error deleting Language.', type: 'failure', enabled: true, timeout: 3000 };

			this.isLoading = true;
			const customLanguagesToSave = this.customLanguages.filter(item => item.iso639 !== iso639);

			let params = {
				OrgId: configStore.organization.id,
				Id: organizationStore.selectedNode.id,
				RecordType: "cockpit_config",
				AttributeName: "custom_languages",
				Settings: customLanguagesToSave
			};

			const axios = await configStore.getAxios();
			await axios.post('/put-cockpit-settings', params)
			.then(() => {
				this.alert = success;
			})
			.catch((err) => {
				console.log(err);
				this.alert = failure;
			})

			this.customLanguages = customLanguagesToSave;
			this.displayLanguages = this.displayLanguages.filter(item => item.iso639 !== iso639);
			this.originalLanguageData = this.originalLanguageData.filter(item => item.iso639 !== iso639);
			if (this.currentLanguage.iso639 === iso639) {
				this.currentLanguage = {};
				this.enableLanguageSelection = false;
			}
			this.hasLanagugeDataChanged();
			this.isLoading = false;
		},

		async saveLanguages() {
			const organizationStore = useOrganizationStore();
			const configStore = useConfigStore();
			let success = { ...this.alert, title: 'Language saved', message: 'Language saved succesfully.', type: 'success', enabled: true, timeout: 3000 };
			let failure = { ...this.alert, title: 'Error saving Language', message: 'Error saving Language.', type: 'failure', enabled: true, timeout: 3000 };

			this.isLoading = true;

			// Get the assigned languages
			let assignedLanguages = [];
			for (const language of this.displayLanguages) {
				if (language.assigned) assignedLanguages.push(language.iso639);
			}

			// Get the custom settings for the default langauges
			// Create a Map to aid in searching
			const assignedLanguagesDefaultMap = new Map();
			this.defaultLanguages.forEach((language) => {
				language.tabs.forEach((tab) => {
					tab.tabData.forEach((section) => {
						section.sectionData.forEach((setting) => {
							assignedLanguagesDefaultMap.set(`${language.iso639}.${tab.tabName}.${section.sectionName}.${setting.optionName}`, setting.selectedValue);
						});
					});
				});
			});
			// Loop throught the deafult settings and compare them with what is currently set
			let customSettings = {};
			this.defaultLanguages.forEach((language) => {
				customSettings[language.iso639] = {};
				const displayLanguage = this.displayLanguages.find(item => item.iso639 === language.iso639);
				displayLanguage.tabs.forEach((tab) => {
					tab.tabData.forEach((section) => {
						section.sectionData.forEach((setting) => {
							const defaultValue = assignedLanguagesDefaultMap.get(`${language.iso639}.${tab.tabName}.${section.sectionName}.${setting.optionName}`);
							// Is the setting non-default ?
							if (defaultValue !== undefined && defaultValue !== setting.selectedValue) {
								customSettings[language.iso639][setting.optionName] = setting.selectedValue;
							}
						});
					});
				});
				if (Object.keys(customSettings[language.iso639]).length === 0) delete customSettings[language.iso639];
			});

			// Save the assigned languages to the database
			let params = {
				OrgId: configStore.organization.id,
				Id: organizationStore.selectedNode.id,
				RecordType: "agent_config",
				AttributeName: "languages",
				Settings: {
					assigned: assignedLanguages,
					customSettings
				}
			};

			const axios = await configStore.getAxios();
			await axios.post('/put-cockpit-settings', params)
			.then(() => {
				this.alert = success;
			})
			.catch((err) => {
				console.log(err);
				this.alert = failure;
			})

			// Save the custom languages to the database if they have been altered
			let customLanguagesChanged = false;
			for (const language of this.customLanguages) {
				if (!isEqual(language, this.originalLanguageData.find(item => item.iso639 === language.iso639))) {
					customLanguagesChanged = true;
					break;
				}
			}

			if (customLanguagesChanged) {
				params = {
					OrgId: configStore.organization.id,
					Id: organizationStore.selectedNode.id,
					RecordType: "cockpit_config",
					AttributeName: "custom_languages",
					Settings: this.customLanguages
				};

				await axios.post('/put-cockpit-settings', params);
			}

			this.originalLanguageData = cloneDeep(this.displayLanguages);
			this.hasLanagugeDataChanged();
			this.isLoading = false;
		},

		async revertLanguages() {
			this.displayLanguages = cloneDeep(this.originalLanguageData);
			if (Object.keys(this.currentLanguage).length > 0) this.setDisplayLanguage(this.currentLanguage.iso639);
			this.hasLanagugeDataChanged();
		},

		isLanguageAssigned(language) {
			return this.displayLanguages.find(item => item.iso639 === language.iso639).assigned;
		},

		isLanguageDefault(iso639) {
			console.log (`isLanguageDefault Called ${JSON.stringify(iso639)}`)
			const found = this.defaultLanguages.find(item => item.iso639 === iso639);
			return found ? true : false;
		},

		hasLanagugeDataChanged() {
			if (!isEqual(this.displayLanguages, this.originalLanguageData)) this.languageDataChanged = true
			else this.languageDataChanged = false;
		},

		updateLanguageMappings(addMapping, language) {
			this.displayLanguages.find(item => item.iso639 === language.iso639).assigned = addMapping;
			this.hasLanagugeDataChanged();
		},

		languageExists(iso639) {
			return this.displayLanguages.find(item => item.iso639 === iso639);
		},

		async reset() {
			this.languageDataChanged = false;
			this.enableLanguageSelection = false;
			this.displayLanguages = [];
			this.customLanguages = [];
			this.currentLanguage = {};
			this.currentLanguageDisplayValues = [];
		}
	}
})
