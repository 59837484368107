<template>
  <div>
    <select v-model="selectedItem" @change="emitSelection">
      <option disabled value="">{{ placeholder }}</option>
      <option 
        v-for="item in items" 
        :key="item[keyProperty]" 
        :value="item[keyProperty]"
      >
        {{ item[displayProperty] }}
      </option>
    </select>
  </div>
</template>

<script setup>
import { ref } from 'vue';

const props = defineProps({
  items: {
    type: Array,
    required: true
  },
  displayProperty: {
    type: String,
    required: true
  },
  keyProperty: {
    type: String,
    required: true
  },
  placeholder: {
    type: String,
    default: 'Select message...'
  }
});

const emit = defineEmits(['item-selected']);

const selectedItem = ref('');

const emitSelection = () => {
  const selectedItemData = props.items.find(item => item[props.keyProperty] === selectedItem.value);
  emit('item-selected', selectedItemData);
};

const clearSelection = () => {
  selectedItem.value = '';
  emit('item-selected', null); // Emit null to indicate that the selection was cleared
};

defineExpose({ clearSelection })
</script>

<style scoped>
select {
  padding: 5px;
  font-size: 12px;
  width: 20em;
}
</style>
