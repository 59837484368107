<template>
  <transition>
      <div v-if="alert.enabled" class="alert">
        <div class="alert__close" @click="alert.enabled=false">
          <font-awesome-icon icon="fa-light fa-xmark" />
        </div>
        <div class="alert__left">
          <font-awesome-icon v-if="alert.type=='success'" icon="fa-solid fa-bell" />
          <font-awesome-icon v-if="alert.type=='failure'" style="color:red; font-size: 25px" icon="fa-light fa-xmark" />
        </div>
        <div class="alert__right">
          <div class="alert__title">
            {{ alert.title }}
          </div>
          <div class="alert__content">
            {{ alert.message }}
          </div>
          <div class="alert_footer">
            <slot name="footer"></slot>
          </div>
        </div>
      </div>
  </transition>

</template>

<script setup>

import { toRefs, watch } from 'vue';

  const props = defineProps({
    alert: {
      type: Object,
      default: {}
    }
  });

  const { alert } = toRefs(props);

  watch(alert, (value) => {
    if(value.enabled){
      setTimeout(() => {
        alert.value.enabled = false;
      }, alert.value.timeout);
    }
  });

</script>

<style scoped lang="scss">

  @import '@/assets/scss/variables.scss';
  @import '@/assets/scss/mixins.scss';
  .alert{
    @include rem(width, 350px);
    @include rem(height, 110px);
    @include flex(row, flex-start, flex-start);
    z-index: 1000;
    position: absolute;
    top: 0;
    right: 0;
    background-color: #393e45;

    &__close{
      position: absolute;
      @include rem(font-size, 14px);
      @include rem(right, 10px);
      @include rem(top, 8px);
      cursor: pointer;
      color: #ccc;
    }

    &__close:hover{
      color: #fff;
    }

    &__left{
      @include rem(width, 40px);
      @include rem(height, 110px);
      @include flex(column, center, center);
      @include rem(font-size, 20px);
      background-color: #000;
      color: #75a8ff;

    }
    &__right{
      @include flex(column, flex-start, flex-start);
      color: #fff;
      opacity: 0.92;
      .alert__title{
        text-transform: uppercase;
        @include rem(padding, 12px);
        @include rem(font-size, 11px)
      }
      .alert__content{
        @include rem(padding, 12px)
      }
    }
  }

  .v-enter-active, .v-leave-active {
    transition: transform .5s ease;
  }

  .v-enter-from, .v-leave-to {
    transform: translateX(100%);
  }

</style>