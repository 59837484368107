<template>
  <div class="card" :style="props.style">
    <div v-if="$slots.header" class="card__header">
      <slot name="header"></slot>
    </div>
    <div class="card__body" :style="{'max-height': height}">
      <slot name="body"></slot>
    </div>
    <div v-if="$slots.footer" class="card__footer">
      <slot name="footer"></slot>
    </div>
  </div>
  </template>

  <script setup>

    const props = defineProps({
      style: {
        type: [String, Object],
        default: ''
      },
      height: {
        type: String,
        default: 'auto'
      }
    })

  </script>

  <style scoped lang="scss">

    @import '@/assets/scss/variables.scss';
    @import '@/assets/scss/mixins.scss';

    .card{
      @include flex(column, flex-start, flex-start);
      border: .1rem solid $borderColor;
      @include rem(border-radius, 5px);

      &__header{
        @include flex(row, flex-start, center);
        @include rem(height, 50px);
        @include rem(font-size, 15px);
        @include rem(padding, 10px);
        width: 100%;
        flex-shrink: 0;
        border-bottom: .1rem solid $borderColor;
      }
      &__body{
        @include flex(column, flex-start, flex-start);
        @include rem(font-size, 13px);
        @include rem(min-height, 40px);
        width: 100%;
        padding: 1rem;
        flex-grow: 1;
      }
      &__footer{
        @include flex(row, flex-end, center);
        @include rem(padding, 10px);
        @include rem(height, 50px);
        width: 100%;
        flex-shrink: 0;
        border-top: .1rem solid $borderColor;
      }
    }
  </style>