<template>
  <div class="messsages">
    <bd-card class="w-full h-30" :headerStyles="{ fontSize: '1em', fontWeight: 500, color: 'pink' }">
      <template #header>Supervisors</template>
      <template #body>
        <bd-table align="left" hover delete :undo="false" :style="{ fontSize: '1.2rem' }">
          <template #header>
            <bd-header width="10%" shrink>First Name</bd-header>
            <bd-header width="10%" shrink>Last Name</bd-header>
            <bd-header width="10%" shrink>Alias</bd-header>
            <bd-header width="10%" shrink>Phone</bd-header>
            <bd-header width="10%" shrink grow>Email</bd-header>
            <bd-header width="5%" shrink>Visible</bd-header>
            <bd-header width="5%" shrink>Sick</bd-header>
            <bd-header width="5%" shrink>Better</bd-header>
            <bd-header width="5%" shrink>Late</bd-header>
            <bd-header width="5%" shrink>Absent</bd-header>
            <bd-header width="5%" shrink>Exception</bd-header>
            <bd-header width="5%" shrink>Timeoff</bd-header>
          </template>
          <template #row>
            <bd-table-row>
              <bd-cell width="10%" shrink></bd-cell>
              <bd-cell width="10%" shrink></bd-cell>
              <bd-cell width="10%" shrink></bd-cell>
              <bd-cell width="10%" shrink></bd-cell>
              <bd-cell width="10%" shrink grow></bd-cell>
              <bd-cell width="5%" shrink><input type="checkbox" name="" id=""></bd-cell>
              <bd-cell width="5%" shrink><input type="checkbox" name="" id=""></bd-cell>
              <bd-cell width="5%" shrink><input type="checkbox" name="" id=""></bd-cell>
              <bd-cell width="5%" shrink><input type="checkbox" name="" id=""></bd-cell>
              <bd-cell width="5%" shrink><input type="checkbox" name="" id=""></bd-cell>
              <bd-cell width="5%" shrink><input type="checkbox" name="" id=""></bd-cell>
              <bd-cell width="5%" shrink><input type="checkbox" name="" id=""></bd-cell>
            </bd-table-row>
          </template>
        </bd-table>
      </template>
    </bd-card>
    <bd-card class="w-full h-30" :headerStyles="{ fontSize: '1em', fontWeight: 500 }">
      <template #header>
        <div class="title">
          <div class="column">Brad Laloli</div>
          <div class="column">Icons</div>
        </div>
      </template>
      <template #body>
        <!-- <div class="messaging-section" v-for="section in messagingStore.displayMessaging">
          <bd-card :headerStyles="{ fontSize: '1em', fontWeight: 500 }">
            <template #header>{{ section.sectionTitle }}</template>
            <template #body>
              <ul class="list-group">
                <li class="list-group-item" v-for="item in section.sectionData">
                  <div class="setting-item">
                    <Setting :data="item"></Setting>
                  </div>
                </li>
              </ul>
            </template>
          </bd-card>
        </div> -->
        <!-- <div class="grid-container">
          <div class="column"></div>
          <div class="column"></div>
        </div> -->
      </template>
    </bd-card>
  </div>
  <teleport to="body">
    <bd-alert :alert="alert"></bd-alert>
  </teleport>
</template>

<script setup>
import { toRefs } from 'vue';
import { useMessagingStore } from '@/stores/messagingStore';
import bdCard from '@/components/card/cockpitcard.vue';
import bdAlert from '@/components/alert/alert.vue';

import bdTable from '@/components/table/table.vue';
import bdHeader from '@/components/table/header.vue';
import bdTableRow from '@/components/table/row.vue';
import bdCell from '@/components/table/cell.vue';

const messagingStore = useMessagingStore();
const { alert } = toRefs(messagingStore);
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
@import '@/assets/scss/mixins';

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* Two equal-width columns */
  gap: 20px;
  /* Gap between columns */
  padding: 20px;

  .column {
    background-color: #f2f2f2;
    /* Light background for visibility */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
}
</style>
