<template>
    <div style="user-select: none">
        <div
            @click.stop="openCalendar"
            @mouseleave="mouseLeave"
            class="calendar"
            :class="{ active: isDatePicker }"
        >
            <span class="calendar__form-control">{{ currentDate }}</span>
            <span class="calendar__icon">
                <font-awesome-icon icon="fa-light fa-calendar-days"/>
            </span>
        </div>
        <div v-if="isDatePicker" class="date-picker" @mouseleave="closeCalendar">
            <table class="date-picker__calendar-table">
                <thead>
                    <tr>
                        <th @click.stop="prevMonth" class="prev">
                            <font-awesome-icon icon="fa-solid fa-arrow-left"/>
                        </th>
                        <th colspan="5" class="month">{{ currentMonth }} {{ currentYear }}</th>
                        <th @click.stop="nextMonth" class="next">
                            <font-awesome-icon icon="fa-solid fa-arrow-right"/>
                        </th>
                    </tr>
                    <tr>
                        <th v-for="weekday in weekdays" :key="weekday" class="week">
                            {{ weekday }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="week in calendar" :key="week">
                        <td
                            v-for="day in week"
                            :key="day.date.toISODate()"
                            @click.stop="selectDate(day)"
                            :class="{ active: isActiveDay(day), grey: !day.isCurrentMonth }"
                        >
                            {{ day.date.day }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script setup>
import { DateTime } from 'luxon'
import { ref, computed, toRefs } from 'vue'

const props = defineProps({
    date: {
        type: String,
        default: null
    }
})

const { date : propDate, useCurrentMonth: use } = toRefs(props);

const emit = defineEmits(['open', 'close', 'select'])

const selectedDate = ref(DateTime.fromISO(propDate.value))
const displayDate =  propDate.value == '2099-01-01' ? ref(DateTime.now()) : ref(DateTime.fromISO(propDate.value))

const isDatePicker = ref(false)

const currentDate = computed(() => toLocalDateFormat(selectedDate.value))
const currentMonth = computed(() => displayDate.value.toFormat('MMM'))
const currentYear = computed(() => displayDate.value.toFormat('yyyy'))

const weekdays = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su']
const calendar = computed(() => generateCalendar())

function generateCalendar() {
    const startDate = displayDate.value.startOf('month').startOf('week')
    const calendar = []

    for (let i = 0; i < 6; i++) {
        const week = []
        for (let j = 0; j < 7; j++) {
            const day = startDate.plus({ days: i * 7 + j })
            week.push({ date: day, isCurrentMonth: day.month === displayDate.value.month })
        }
        calendar.push(week)
    }

    return calendar
}

const toLocalDateFormat = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = new Intl.DateTimeFormat(navigator.language).format(date);
    return formattedDate;
  }


function isActiveDay(day) {
    return selectedDate.value.hasSame(day.date, 'day')
}

function prevMonth() {
    displayDate.value = displayDate.value.minus({ months: 1 })
}

function nextMonth() {
    displayDate.value = displayDate.value.plus({ months: 1 })
}

function openCalendar(){
    isDatePicker.value = !isDatePicker.value;
    emit('open', selectedDate.value.startOf('day'));
}

function closeCalendar(){
    isDatePicker.value = false;
    emit('close');
}

function mouseLeave(){
    if(!isDatePicker.value) emit('close');
}

function selectDate(day) {

    selectedDate.value = day.date

    displayDate.value = day.date

    isDatePicker.value = false

    emit('select', selectedDate.value.startOf('day'))
}

defineExpose({
    selectedDate
})

</script>

<style scoped lang="scss">
@import '@/assets/scss/mixins.scss';

$primary-color: #2a60c8;
$secondary-color: #3071a9;
$border-color: #dae1e8;
$shadow-color: rgba(34, 37, 41, 0.24);
$hover-background-color: #eee;
$icon-color: #444a52;
$disabled-text-color: #999;

.calendar {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    border: 1px solid $border-color;
    box-shadow: inset 0 0 3px 0 $shadow-color;
    background: #fdfdfd;
    @include rem(width, 130px);
    @include rem(font-size, 12px);
    height: 100%;

    &.active {
        border: 1px solid $primary-color;
        box-shadow: 0 0 2px 0 rgba(117, 168, 255, 0.72);
    }

    &:hover {
        cursor: pointer;

        i {
            color: $primary-color;
        }
    }

    &__form-control {
        width: 100%;
        @include rem(padding, 6px 12px 6px 12px);
    }

    &__icon {
        @include rem(font-size, 13px);
        @include rem(padding, 6px 12px 6px 12px);
        color: $icon-color;
    }
}

.date-picker {
    position: absolute;
    box-shadow: 0 0 2px 0 rgba(34, 37, 41, 0);
    background-color: #fdfdfd;
    border: 1px solid $border-color;
    border-radius: 4px;
    max-width: 270px;
    z-index: 100;
    @include rem(font-size, 14px);
    @include rem(padding, 8px);

    &:before {
        position: absolute;
        top: -7px;
        left: 9px;
        display: inline-block;
        border-right: 7px solid transparent;
        border-bottom: 7px solid #ccc;
        border-left: 7px solid transparent;
        border-bottom-color: #fdfdfd;
        content: '';
    }

    &__calendar-table {
        width: 100%;
        border-collapse: collapse;

        .prev,
        .next {
            i {
                font-weight: 1000;
            }
        }

        th,
        td {
            @include rem(padding, 8px);
            text-align: center;

            &.grey {
                color: $disabled-text-color;
            }

            &:hover {
                cursor: pointer;
                border-radius: 4px;
                background: $hover-background-color;

                &.month {
                    background: inherit;
                }

                &.week {
                    background: inherit;
                }
            }
        }

        .active {
            background-color: $primary-color;
            border-color: $secondary-color;
            border-radius: 4px;
            color: #fff;

            &:hover {
                background: $primary-color;
            }
        }
    }
}
</style>
