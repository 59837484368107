import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useConfigStore } from './configStore';

export const useQRStore = defineStore('qrstore', {
  state: () => ({
    gcConfig: ref({}),
    generateEnabled: ref(false),
    isLoading: ref(false),
    alert: ref()
  }),

  actions: {
    async encryptQRCode (data) {
      const configStore = useConfigStore();
      let success = { ...alert, title: 'QR Code Generated', message: 'QR Code Generated succesfully.', type: 'success', enabled: true, timeout: 3000 };
      let failure = { ...alert, title: 'Error generating QR Code', message: 'Error generating QR Code.', type: 'failure', enabled: true, timeout: 3000 };
  
      this.isLoading = true;
      let encryptedData;
  
      try {
        const axios = await configStore.getAxios();
        await axios.post('/qrcode', data)
          .then((response) => {
            encryptedData = response.data;
            this.alert = success;
          })
          .catch((err) => {
            console.log(err);
            this.alert = failure;
          })
      } catch (error) {
        console.log(`Oops -> ${error}`)
      }
  
      this.isLoading = false;
      return encryptedData;
    }
  }

})
