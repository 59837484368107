import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useConfigStore } from './configStore';
import { useOrganizationStore } from './organizationStore';

export const useBroadcastStore = defineStore('broadcast', {
  state: () => ({
    gcConfig: ref({}),
    displayNodeId: ref(''),
    showSystemMessageDialog: ref(false),
    sendEnabled: ref(false),
    isLoading: ref(false),
    alert: ref(),
    systemMessages: ref([]),
  }),

  actions: {
    async getSystemMessages() {
      const configStore = useConfigStore();
      const organizationStore = useOrganizationStore();

      this.isLoading = true;
      this.reset();

      let params = {
        orgId: configStore.organization.id,
      };

      switch (organizationStore.selectedNode.type) {
        case 'division':
          params.divId = organizationStore.selectedNode.id;
          break;
        case 'businessUnit':
          params.buId = organizationStore.selectedNode.id;
          params.divId = organizationStore.selectedNode.division.id;
          break;
        case 'managementUnit':
          params.muId = organizationStore.selectedNode.id;
          params.buId = organizationStore.selectedNode.businessUnit.id;
          params.divId = organizationStore.selectedNode.division.id;
          break;
        // case 'agent':  
        //   params.muId = organizationStore.selectedNode.id;
        //   params.buId = organizationStore.selectedNode.businessUnit.id;
        //   params.divId = organizationStore.selectedNode.division.id;
        //   break;
        default:
          break;
      }
      
      console.log(`params: ${JSON.stringify(params, null, 2)}`);

      const axios = await configStore.getAxios();
      const retVal = await axios.post('/get-system-messages', params);
      // console.log(`retVal: ${JSON.stringify(retVal, null, 2)}`);
      this.systemMessages = retVal.data;
      this.displayNodeId = organizationStore.selectedNode.id;
      this.isLoading = false;
      return;      
    },

    async saveSystemMessage(message, id, isEdit=false) {
      const configStore = useConfigStore();
      let success = { ...this.alert, title: 'Message(s) sent', message: 'System Message saved.', type: 'success', enabled: true, timeout: 3000 };
      let failure = { ...this.alert, title: 'Error saving System Message', message: 'Error saving System Message.', type: 'failure', enabled: true, timeout: 3000 };

      this.isLoading = true;

      let params = {
        orgId: configStore.organization.id,
        userId: id,
        notificationDetail: {
          sender: "system",
          title: message.title,
          type: 4,
          body: message.body,
          confirmed: 1,
          send: 1,
          time_stamp: new Date().toISOString(),
          urgent: message.urgent ? 1 : 0,
          icon: message.icon ? message.icon : "fal-fa-temp",
          colour: message.color ? message.color : "#fffff"
        },
        read: false,
        isSystemMessage: true,
        isEdit: isEdit,
        notificationId: message.id
      };
      
      try {
        const axios = await configStore.getAxios();
        // await axios.post('/send-message', params);
        await axios.post('/save-message', params)
        .then(async () => {
          this.alert = success;
          await this.getSystemMessages();
        })
        .catch((err) => {
          console.log(err);
          this.alert = failure;
        })
        .finally(() => {
          this.isLoading = false;
        });
      } catch (error) {
        console.log(error);
      }

      return;
    },

    async deleteSystemMessage(msgId) {
      const configStore = useConfigStore();
      let success = { ...this.alert, title: 'Message deleted', message: 'System Message removed.', type: 'success', enabled: true, timeout: 3000 };
      let failure = { ...this.alert, title: 'Error deleting System Message', message: 'Error deleting System Message.', type: 'failure', enabled: true, timeout: 3000 };

      this.isLoading = true;

      let params = {
        orgId: configStore.organization.id,
        notificationId: msgId
      };
      
      try {
        const axios = await configStore.getAxios();
        // await axios.post('/send-message', params);
        await axios.post('/delete-message', params)
        .then(async () => {
          this.alert = success;
          await this.getSystemMessages();
        })
        .catch((err) => {
          console.log(err);
          this.alert = failure;
        })
        .finally(() => {
          this.isLoading = false;
        });
      } catch (error) {
        console.log(error);
      }

      return;
    },

    async sendMessage(message, checkedUsers) {
      const configStore = useConfigStore();
      let success = { ...this.alert, title: 'Message(s) sent', message: 'Message(s) sent succesfully.', type: 'success', enabled: true, timeout: 3000 };
      let failure = { ...this.alert, title: 'Error sending Message(s)', message: 'Error sending Message(s).', type: 'failure', enabled: true, timeout: 3000 };

      let recipients = []

      this.isLoading = true;

      for (const item of checkedUsers) {
        if (item.type  === 'agent') {
          recipients.push(item.id);
        }
      }

      console.log(`recipients: ${JSON.stringify(recipients, null, 2)}`);
      
      let params = {
          OrgId: configStore.organization.id,
          recipients: recipients,
          notificationDetail: {
            sender: "WFM Buddy",
            title: message.title,
            type: 4,
            body: message.body,
            confirmed: 1,
            send: 1,
            time_stamp: new Date().toISOString(),
            urgent: message.urgent ? 1 : 0,
            icon: message.icon ? message.icon : "fal-fa-temp",
            colour: message.color ? message.color : "#fffff",
          },
          read: false
        };

        try {
          const axios = await configStore.getAxios();
          // await axios.post('/send-message', params);
          await axios.post('/send-message', params)
          .then(() => {
            this.alert = success;
          })
          .catch((err) => {
            console.log(err);
            this.alert = failure;
          })
          .finally(() => {
            this.isLoading = false;
          });
        } catch (error) {
          console.log(error);
        }
      return;
    },

    async reset() {
			this.displayNodeId = '';
		}
  }

})
