/* import font awesome Vue component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import specific icons */
import { faS, faUpload as fasUpload } from '@fortawesome/pro-solid-svg-icons'
import { faTrashCan as fasTrashCan } from '@fortawesome/pro-solid-svg-icons'
import { faLayerPlus as fasLayerPlus } from '@fortawesome/pro-solid-svg-icons'
import { faFloppyDisk as fasFloppyDisk } from '@fortawesome/pro-solid-svg-icons'
import { faPenToSquare as fasPenToSquare } from '@fortawesome/pro-solid-svg-icons'
import { faHistory as fasHistory } from '@fortawesome/pro-solid-svg-icons'
import { faDownload as fasDownload } from '@fortawesome/pro-solid-svg-icons'
import { faChevronRight as fasChevronRight } from '@fortawesome/pro-solid-svg-icons'
import { faCalendarAlt as fasCalendarAlt } from '@fortawesome/pro-solid-svg-icons'
import { faCalendarWeek as fasCalendarWeek } from '@fortawesome/pro-solid-svg-icons'
import { faCalendarMinus as fasCalendarMinus } from '@fortawesome/pro-solid-svg-icons'
import { faSyncAlt as fasSyncAlt } from '@fortawesome/pro-solid-svg-icons'
import { faCommentAltExclamation as fasCommentAltExclamation } from '@fortawesome/pro-solid-svg-icons'
import { faEnvelopeOpenDollar as fasEnvelopeOpenDollar } from '@fortawesome/pro-solid-svg-icons'
import { faHighlighter as fasHighlighter } from '@fortawesome/pro-solid-svg-icons'
import { faPhoneAlt as fasPhoneAlt } from '@fortawesome/pro-solid-svg-icons'
import { faFileSignature as fasFileSignature } from '@fortawesome/pro-solid-svg-icons'
import { faLanguage as fasLanguage } from '@fortawesome/pro-solid-svg-icons'
import { faUniversity as fasUniversity } from '@fortawesome/pro-solid-svg-icons'
import { faTriangle as fasTriangle } from '@fortawesome/pro-solid-svg-icons'
import { faBell as fasBell } from '@fortawesome/pro-solid-svg-icons'
import { faChevronUp as fasChevronUp } from '@fortawesome/pro-solid-svg-icons'
import { faChevronDown as fasChevronDown } from '@fortawesome/pro-solid-svg-icons'
import { faUserHeadset as fasUserHeadset } from '@fortawesome/pro-solid-svg-icons'
import { faSquare as fasSqaure } from '@fortawesome/pro-solid-svg-icons'
import { faSchool } from '@fortawesome/pro-solid-svg-icons'
import { faCircle as fasCircle } from '@fortawesome/pro-solid-svg-icons'

import { faHourglass as fadHourglass } from '@fortawesome/pro-duotone-svg-icons'
import { faHourglassHalf as fadHourglassHalf } from '@fortawesome/pro-duotone-svg-icons'
import { faRunning as fadRunning } from '@fortawesome/pro-duotone-svg-icons'
import { faCalendarMinus as fadCalendarMinus } from '@fortawesome/pro-duotone-svg-icons'
import { faCalendarPlus as fadCalendarPlus } from '@fortawesome/pro-duotone-svg-icons'
import { faSignOut as fadSignOut } from '@fortawesome/pro-duotone-svg-icons'
import { faBed as fadBed } from '@fortawesome/pro-duotone-svg-icons'
import { faArrowToRight as fadArrowToRight } from '@fortawesome/pro-duotone-svg-icons'
import { faArrowToLeft as fadArrowToLeft } from '@fortawesome/pro-duotone-svg-icons'
import { faHistory as fadHistory } from '@fortawesome/pro-duotone-svg-icons'
import { faDna as fadDna } from '@fortawesome/pro-duotone-svg-icons'
import { faTriangleExclamation as fadTriangleExclamation } from '@fortawesome/pro-duotone-svg-icons'
import { faCircleXmark as fadCircleXmark } from '@fortawesome/pro-duotone-svg-icons'
import { faCalendarEdit as fadCalendarEdit } from '@fortawesome/pro-duotone-svg-icons'

import { faClock as falClock } from '@fortawesome/pro-light-svg-icons'
import { faAlarmExclamation as falAlarmExclamation } from '@fortawesome/pro-light-svg-icons'
import { faToggleOn as falToggleOn } from '@fortawesome/pro-light-svg-icons'
import { faXmark as falXmark } from '@fortawesome/pro-light-svg-icons'
import { faGripLinesVertical as falGripLinesVertical } from '@fortawesome/pro-light-svg-icons'
import { faQrcode as falQrcode } from '@fortawesome/pro-light-svg-icons'
import { faUserHeadset as falUserHeadset } from '@fortawesome/pro-light-svg-icons'
import { faExclamation as falExclamation } from '@fortawesome/pro-light-svg-icons'
import { faQuestion as falQuestion } from '@fortawesome/pro-light-svg-icons'
import { faCommentAltLines as falCommentAltLines } from '@fortawesome/pro-light-svg-icons'
import { faPhoneAlt as falPhoneAlt } from '@fortawesome/pro-light-svg-icons'
import { faClipboardListCheck as falClipboardListCheck} from '@fortawesome/pro-light-svg-icons'
import { faTrash as falTrash} from '@fortawesome/pro-light-svg-icons'
import { faRotateLeft as falRotateLeft} from '@fortawesome/pro-light-svg-icons'


import { faEnvelope as farEnvelope } from '@fortawesome/pro-regular-svg-icons'
import { faBriefcaseMedical as farBriefcaseMedical } from '@fortawesome/pro-regular-svg-icons'
import { faBriefcase as farBriefcase } from '@fortawesome/pro-regular-svg-icons'
import { faCommentAltExclamation as farCommentAltExclamation } from '@fortawesome/pro-regular-svg-icons'
import { faBuilding as farBuilding } from '@fortawesome/pro-regular-svg-icons'
import { faUsers as farUsers } from '@fortawesome/pro-regular-svg-icons'
import { faUserCrown as farUserCrown } from '@fortawesome/pro-regular-svg-icons'
import { faGraduationCap as farGraduationCap } from '@fortawesome/pro-regular-svg-icons'
import { faToggleOn as farToggleOn } from '@fortawesome/pro-regular-svg-icons'
import { faUserPlus as farUserPlus } from '@fortawesome/pro-regular-svg-icons'
import { faClock as farClock } from '@fortawesome/pro-regular-svg-icons'
import { faHeadset as farHeadset } from '@fortawesome/pro-regular-svg-icons'
import { faTrashAlt as farTrashAlt } from '@fortawesome/pro-regular-svg-icons'
import { faUmbrellaBeach as farUmbrellaBeach } from '@fortawesome/pro-regular-svg-icons'
import { faStepForward as farStepForward } from '@fortawesome/pro-regular-svg-icons'
import { faComments as farComments } from '@fortawesome/pro-regular-svg-icons'
import { faEdit as farEdit } from '@fortawesome/pro-regular-svg-icons'
import { faListAlt as farListAlt } from '@fortawesome/pro-regular-svg-icons'
import { faPersonCarry as farPersonCarry } from '@fortawesome/pro-regular-svg-icons'
import { faUsersMedical as farUsersMedical } from '@fortawesome/pro-regular-svg-icons'
import { faRepeatAlt as farRepeatAlt } from '@fortawesome/pro-regular-svg-icons'
import { faRepeat1 as farRepeat1 } from '@fortawesome/pro-regular-svg-icons'
import { faBalanceScaleRight as farBalanceScaleRight } from '@fortawesome/pro-regular-svg-icons'
import { faIslandTropical as farIslandTropical } from '@fortawesome/pro-regular-svg-icons'
import { faUpload as farUpload } from '@fortawesome/pro-regular-svg-icons'
import { faFloppyDisk as farFloppyDisk } from '@fortawesome/pro-regular-svg-icons'
import { faTimes as farTimes } from '@fortawesome/pro-regular-svg-icons'
import { faCircleXmark as farCircleXmark } from  '@fortawesome/pro-regular-svg-icons'
import { faCalendarPen as farCalendarPen } from '@fortawesome/pro-regular-svg-icons'
import { faFaceMask as farFaceMask } from '@fortawesome/pro-regular-svg-icons'
import { faBullseyeArrow as farBullseyeArrow } from '@fortawesome/pro-regular-svg-icons'
import { faPersonRunningFast as farPersonRunningFast } from '@fortawesome/pro-regular-svg-icons'
import { faCalendarDay as farCalendarDay } from '@fortawesome/pro-regular-svg-icons'
import { faCircleInfo as farCircleInfo } from '@fortawesome/pro-regular-svg-icons'
import { faPencil as farPencil} from '@fortawesome/pro-regular-svg-icons'

import { faWhatsapp as fabWhatsapp } from '@fortawesome/free-brands-svg-icons'

import { faPen as fatPen } from '@fortawesome/pro-thin-svg-icons'

/* add icons to the library */

library.add(fasUpload, fasTrashCan, fasLayerPlus, fasFloppyDisk, fasPenToSquare, fasHistory, fasDownload, fasChevronRight, fasCalendarAlt, fasCalendarWeek,
  fasCalendarMinus, fasSyncAlt, fasCommentAltExclamation, fasEnvelopeOpenDollar, fasHighlighter, fasPhoneAlt, fasFileSignature, fasLanguage, fasUniversity,
  fasTriangle, fasBell, fasChevronUp, fasChevronDown, fasUserHeadset, fasSqaure, faSchool, fasCircle)

library.add(fadHourglass, fadHourglassHalf, fadRunning, fadCalendarMinus, fadCalendarPlus, fadSignOut, fadBed, fadArrowToRight,
  fadArrowToLeft, fadHistory, fadDna, fadTriangleExclamation, fadCircleXmark, fadCalendarEdit)

library.add(falClock, falAlarmExclamation, falToggleOn, falGripLinesVertical, falXmark, falQrcode, falUserHeadset, falExclamation, falQuestion,
  falCommentAltLines, falPhoneAlt, falClipboardListCheck, falTrash, falRotateLeft)

library.add(farEnvelope, farBriefcaseMedical, farBriefcase, farCommentAltExclamation, farBuilding, farUsers, farUserCrown, farGraduationCap, farToggleOn,
  farUserPlus, farClock, farHeadset, farTrashAlt, farUmbrellaBeach, farStepForward, farComments, farEdit, farListAlt, farPersonCarry, farUsersMedical,
  farRepeatAlt, farRepeat1, farBalanceScaleRight, farIslandTropical, farUpload, farFloppyDisk, farTimes, farCircleXmark, farCalendarPen, farFaceMask,
  farBullseyeArrow, farPersonRunningFast, farCalendarDay, farCircleInfo, farPencil)

library.add(fabWhatsapp)

library.add(fatPen)
export default FontAwesomeIcon;