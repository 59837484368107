<template>
  <div class="app-container">
    <div class="overlay" v-if="configStore.isLoading">
      <div class="overlay-content">
        <div class="loader"></div>
      </div>
    </div>
    <div class="router-container" v-if="configStore.isLoading">
      <router-view />
    </div>
    <div class="cockpit-container" v-if="!configStore.isLoading">
      <mainview />
    </div>
    <div class="footer">
      <div class="version">Cockpit Version {{ version }}</div>
    </div>
  </div>
</template>

<script setup>
import { RouterView } from 'vue-router';
import { useConfigStore } from '@/stores/configStore';
import { version } from '../package.json';
import { ref, toRefs, onMounted } from 'vue';
import mainview from '@/views/MainView.vue';

const configStore = useConfigStore();
const { clientId, location, environment, region, splashed } = toRefs(configStore);
let error = ref(false);

onMounted(() => {

  //reset local storage before we begin
  localStorage.removeItem('purecloud_auth_data');

  const locationAndEnvironment = extractLocationAndEnvironment();
  if (locationAndEnvironment) {
    location.value = locationAndEnvironment.location;
    environment.value = locationAndEnvironment.environment;
    clientId.value = locationAndEnvironment.clientId;
    region.value = locationAndEnvironment.region;

    console.log('location:', location.value, '\nregion:', region.value, '\nenvironment:', environment.value, '\nclientId:', clientId.value);
  } else {
    error.value = true;
    messages.value.push('No region and environment found, please contact your administrator.');
  }
});

const extractLocationAndEnvironment = () => {
  const regex = /https:\/\/cockpit\.([\w-]+)(?:\.(\w+))?\.wfmbuddy\.(com|cloud)(\/[^?]*)?(?:\?clientId=([\w-]+))?/;
  const match = window.location.href.match(regex);
  console.log('match:', match);
  console.log('window.location.href:', window.location.href);
  if (match) {
    let [, location, environment, , , clientId] = match;
    if (!clientId) clientId = localStorage.getItem('clientId');
    else localStorage.setItem('clientId', clientId);
    if (!environment) environment = 'prd';

    return { environment: environment, location: awsToGenesysLocation(location), region: location, clientId };
  } else {
    if (window.location.href.includes('localhost')) {
      return { environment: 'dev', location: 'mypurecloud.de', region: 'eu-central-1', clientId: 'de54d6bc-7212-46b0-ab24-442068ee4104' };
    }
    return null;
  }
};

const awsToGenesysLocation = (location) =>{
  switch (location) {
    case 'us-east-1':
      return `mypurecloud.com`;
    case 'us-east-2':
      return `use2.us-gov-pure.cloud`;
    case 'us-west-2':
      return `usw2.pure.cloud`;
    case 'ca-central-1':
      return `cac1.pure.cloud`;
    case 'sa-east-1':
      return `sae1.pure.cloud`;
    case 'eu-central-1':
      return `mypurecloud.de`;
    case 'eu-west-1':
      return `mypurecloud.ie`;
    case 'eu-west-2':
      return `euw2.pure.cloud`;
    case 'ap-south-1':
      return `aps1.pure.cloud`;
    case 'ap-northeast-1':
      return `mypurecloud.jp`;
    case 'ap-northeast-2':
      return `apne2.pure.cloud`;
    case 'ap-southeast-2':
      return `mypurecloud.com.au`;
    default:
      console.warn('Region not found. Defaulting to mypurecloud.de');
      return `mypurecloud.de`;
  }

};

async function start () {
  console.log('Starting...');
  await configStore.init();
}

if (!error.value) {
  if (!splashed.value) {
    setTimeout(() => {
      splashed.value = true;
       start();
    }, 50);
  } else {
    // configStore.getMe();
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/variables.scss';
@import '@/assets/scss/mixins';

.app-container {

  .overlay {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.2);

    .overlay-content {
      position: absolute;
      transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      top: 50%;
      left: 50%;
      text-align: center;

      .loader {
        color: $loader-color;
        font-size: 45px;
        text-indent: -9999em;
        overflow: hidden;
        width: 1em;
        height: 1em;
        border-radius: 50%;
        position: relative;
        transform: translateZ(0);
        animation: mltShdSpin 1.7s infinite ease, round 1.7s infinite ease;
      }

      @keyframes mltShdSpin {
        0% {
          box-shadow: 0 -0.83em 0 -0.4em,
            0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
            0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
        }

        5%,
        95% {
          box-shadow: 0 -0.83em 0 -0.4em,
            0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
            0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
        }

        10%,
        59% {
          box-shadow: 0 -0.83em 0 -0.4em,
            -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em,
            -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
        }

        20% {
          box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em,
            -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em,
            -0.749em -0.34em 0 -0.477em;
        }

        38% {
          box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em,
            -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em,
            -0.82em -0.09em 0 -0.477em;
        }

        100% {
          box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em,
            0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
        }
      }

      @keyframes round {
        0% {
          transform: rotate(0deg)
        }

        100% {
          transform: rotate(360deg)
        }
      }
    }
  }

  .footer {
    position: fixed;
    bottom: 0px;
    height: $footer-height;
    width: 100%;
    border-top: 1px solid $borderColor;
    background-color: $backgroundColor;

    .version {
      @include flex(row, flex-end, center);
      @include rem(font-size, 12px);
      @include rem(padding, 0 10px);
      height: 100%;
    }

  }
}
</style>
