<!-- Card.vue -->

<template>
  <div :style="cardStyles" class="card">
    <div v-if="$slots.header" :style="headerStyles" class="card-header">
      <slot name="header">Card Header</slot>
    </div>
    <div v-if="$slots.body" :style="bodyStyles" class="card-body">
      <slot name="body">Card Body</slot>
    </div>
    <div :style="footerStyles" class="card-footer">
      <slot v-if="$slots.footer" name="footer">Card Footer</slot>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue';

export default {
  name: 'Card',
  props: {
    cardStyles: {
      type: Object,
      default: () => ({}),
    },
    headerStyles: {
      type: Object,
      default: () => ({}),
    },
    bodyStyles: {
      type: Object,
      default: () => ({}),
    },
    footerStyles: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const someState = ref('Some state value');

    return {
      someState,
    };
  },
};
</script>

<style scoped>
.card {
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
  margin: 0.25em;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #f0f0f0;
}

.card-header,
.card-footer {
  /* background-color: #f0f0f0; */
  padding: .25em;
}

.card-body {
  padding: 0.25em;
}
</style>
