<template>
  <div class="splitter" ref="splitter">
    <div class="left" :style="{ width: leftWidth + 'px' }">
      <slot name="left"></slot>
    </div>
    <div class="bar" ref="splitBar" :style="barStyle" >
      <font-awesome-icon class="icon" icon="fa-light fa-grip-lines-vertical"/>
    </div>
    <div class="right">
      <slot name="right"></slot>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, toRefs, watch, onBeforeUnmount } from 'vue';

const emit = defineEmits(['update:leftWidth']);

const props = defineProps({
  id: {
    type: String,
    default: null
  },
  barStyle: {
    type: [String, Object],
    default: {backgroundColor: 'black', width: '15px'}
  },
  left: {
    type: Number,
    default: 200
  }
});

const { left, id } = toRefs(props);

const splitBar = ref(null);
const splitter = ref(null);
const leftWidth = ref(id.value != null ? parseInt(localStorage.getItem('splitter-' + id.value)) : left.value);

watch(leftWidth, (value) => {
  if(id.value != null) {
    localStorage.setItem('splitter-' + id.value, value);
  }
});

watch(leftWidth, (newValue) => {
  emit('update:leftWidth', newValue);
});

const onDragStart = (event) => {
  document.addEventListener('mousemove', onDragging);
  document.addEventListener('mouseup', onDragEnd);
};

const onDragging = (event) => {
  const newWidth = event.clientX - splitter.value.offsetLeft;
  leftWidth.value = newWidth <= left.value ? left.value : newWidth;
};

const onDragEnd = () => {
  document.removeEventListener('mousemove', onDragging);
  document.removeEventListener('mouseup', onDragEnd);
};

onMounted(() => {
  splitBar.value.addEventListener('mousedown', onDragStart);
});

onBeforeUnmount(() => {
  splitBar.value.removeEventListener('mousedown', onDragStart);
  document.removeEventListener('mousemove', onDragging);
  document.removeEventListener('mouseup', onDragEnd);
});
</script>

<style scoped lang="scss">
.splitter {
  display: flex;
  width: 100%;
  height: 100vh;

  .left {
    flex-shrink: 0;
  }

  .bar {
    display: flex;
    align-items: center;
    justify-content: center;
    color: grey;
    flex-shrink: 0;
    cursor: ew-resize;
  }

  .right {
    flex-grow: 1;
  }
}


</style>
