import { defineStore } from 'pinia';
import { reactive, ref } from 'vue';
import { useConfigStore } from './configStore';
import { useOrganizationStore } from './organizationStore';
import { cloneDeep, isEmpty } from 'lodash';

export const useMessagingStore = defineStore('messaging', {
  state: () => ({
    defaultMessaging: [],
    customMessaging: {},
    displayNodeId: ref(''),
    displayMessaging: reactive([]),
    messagingChanged: ref(false),
    isLoading: ref(false),
    alert: ref()
  }),

  actions: {
    async getMessaging() {
      const configStore = useConfigStore();
      const organizationStore = useOrganizationStore();

      this.isLoading = true;
      this.reset();

			let params = {
				orgId: configStore.organization.id,
				divId: '',
				buId: '',
				muId: '',
				idToQuery: organizationStore.selectedNode.id,
				queryType: "messaging"
			};

			switch (organizationStore.selectedNode.type) {
				case 'division':
					params.divId = organizationStore.selectedNode.id;
					break;
				case 'businessUnit':
					params.buId = organizationStore.selectedNode.id;
					params.divId = organizationStore.selectedNode.division.id;
					break;
				case 'managementUnit':
					params.muId = organizationStore.selectedNode.id;
					params.buId = organizationStore.selectedNode.businessUnit.id;
					params.divId = organizationStore.selectedNode.division.id; ``
					break;
				case 'agent':
					params.agentId = organizationStore.selectedNode.id;
					params.buId = organizationStore.selectedNode.buId;
					break;
				default:
					break;
			}

      const axios = await configStore.getAxios();
      const retVal = await axios.post('/get-cockpit-settings', params);
      console.log(`retVal -> ${JSON.stringify(retVal, null, 2)}`);
      const storedMessaging = retVal.data.body.body;
      
      // Populate the default and custom settings but only the display settings IF we have anything stored at this level.
      this.defaultMessaging = storedMessaging.defaultSettings;
      this.customMessaging = storedMessaging.customSettings;

      if (!Array.isArray(storedMessaging.customSettings) && storedMessaging.customSettings) {
        this.displayMessaging = cloneDeep(storedMessaging.defaultMessaging);
        // Merge the default values and the custom ones
        // Can't use lodash as we want to minimise stored JSON
        if (this.customMessaging) {
          for (const customOption of Object.keys(this.customMessaging)) {
            for (const displaySetting of this.displayMessaging) {
              const opt = displaySetting.sectionData.find(setting => setting.optionName === customOption);
              if (opt) {
                opt.selectedValue = this.customMessaging[customOption];
                break;
              }
            }
          }
        }
      }

      this.displayNodeId = organizationStore.selectedNode.id;
      this.isLoading = false;
      return;
    },
    async reset() {
			this.messagingChanged = false;
			this.customMessaging ={};
			this.displayNodeId = '';
			this.displayMessaging= [];
		}
  }

});