<template>
  <div class="colors">
    <div class="message" v-if="isEmpty(selectedNode) || isEmpty(displayColours)">
      <font-awesome-icon icon="far fa-circle-info" />
      <div v-if="!isLoading && isEmpty(selectedNode)">Please select an organization level to view details</div>
      <div class="none" v-if="!isLoading && !isEmpty(selectedNode) && isEmpty(displayColours)">
        <span>No colors stored for {{ selectedNode.type == 'businessUnit' ? 'business unit' : selectedNode.type == 'managementUnit' ? 'management unit' : selectedNode.type }} "{{selectedNode.name}}"</span>
        <bd-button size="xs" icon="fas fa-layer-plus" v-if="showAddColors" @click="colorStore.addColors()">Add</bd-button>
      </div>
      <div v-if="isLoading">Loading ...</div>
    </div>
    <bd-card v-if="!isEmpty(selectedNode) && !isEmpty(displayColours)">
      <template #body>
        <div class="colors-navigation">
          <bd-navigation :items="menuColorData" :nologo="true" @tabSelected="tabSelected">
            <bd-button size="sm" icon="fas fa-layer-plus" v-if="showAddColors"
              @click="colorStore.addColors()">Add</bd-button>
            <bd-button size="sm" icon="fas fa-history" v-if="selectedColorChanged"
              @click="colorStore.revertColors()">Revert</bd-button>
            <bd-button size="sm" icon="fas fa-floppy-disk" v-if="selectedColorChanged"
              @click="colorStore.saveColors(null)">Save</bd-button>
            <bd-button size="sm" icon="fas fa-history" v-if="showRestoreDefaults" @click="restoreDefaults()">Restore Defaults</bd-button>
            <bd-button size="sm" icon="fas fa-trash-can" v-if="!showAddColors
              && !selectedColorChanged
              && !isEmpty(selectedNode)" @click="showConfirmDelete = true">Delete</bd-button>
          </bd-navigation>
        </div>
        <div class="colors-container">
          <ColorSection :tabName="currentColorTab" />
        </div>
      </template>
    </bd-card>
  </div>
  <teleport to="body">
    <bd-alert :alert="alert"></bd-alert>
  </teleport>
  <teleport to="body">
    <bd-idiot v-if="showConfirmDelete" :message="[`${setDisplayMessage}`, 'Are you sure?']"
      @close="showConfirmDelete = false" @proceed="deleteSettings()"></bd-idiot>
    <!-- <bd-idiot v-if="showRestoreDefaults" :message="['This will restore ALL default colors. Are you sure?']"
      @close="showRestoreDefaults = false" @proceed="restoreDefaults()"></bd-idiot> -->
  </teleport>
</template>

<script setup>
import { toRefs, ref, watch, computed } from 'vue';
import { isEmpty } from 'lodash';
import bdNavigation from '@/components/nav/navbar.vue';
import bdButton from '@/components/button/regular/button.vue';
import bdCard from '@/components/card/cockpitcard.vue';
import bdAlert from '@/components/alert/alert.vue';
import bdIdiot from '@/components/modals/idiot/idiot.vue'
import { useConfigStore } from '@/stores/configStore';
import { useColorStore } from '@/stores/colorStore';
import { useOrganizationStore } from '@/stores/organizationStore.js';
import ColorSection from '@/views/Colors/ColorSections.vue';

const configStore = useConfigStore();
const colorStore = useColorStore();
const organizationStore = useOrganizationStore();

const { selectedNode } = toRefs(organizationStore);
const { menuColorData } = toRefs(configStore);
const { currentColorTab, showAddColors,  selectedColorChanged, displayColours, isLoading} = toRefs(colorStore);
const { alert } = toRefs(colorStore);
const showConfirmDelete = ref(false);

// This converts the tab to a name so I don't have to refactor all my old code
const tabSelected = (tab) => {
  let tabName;
  switch (tab) {
    case 'General':
      tabName = 'generalTab';
      break;
    case 'Schedule':
      tabName = 'scheduleTab';
      break;
    case 'Preferences':
      tabName = 'preferencesTab';
      break;
    case 'Time Off':
      tabName = 'timeoffTab';
      break;
      case 'Agent Info':
      tabName = 'agentInfoTab';
      break;
    default:
      console.log('#####' + tab);
      tabName = 'generalTab';
  }
  // console.log('Tab selected: ', tabName);
  colorStore.currentColorTab = tabName;
}

const setDisplayMessage = computed(() => {
  const message = `This will delete ALL the colors for ${organizationStore.selectedNode.name}`
  return message;
})

const deleteSettings = async () => {
  await colorStore.removeColors();
  showConfirmDelete.value = false;
}

const restoreDefaults = async () => {
  await colorStore.removeColors();
  await colorStore.addColors();
  showConfirmDelete.value = false;
}

const showRestoreDefaults = computed(() => {
  return !colorStore.showAddColors && !colorStore.selectedColorChanged && !isEmpty(organizationStore.selectedNode);
})

watch(currentColorTab, () => {
  colorStore.setCurrentColorDisplayValues();
});
</script>

<style scoped lang="scss">
@import '@/assets/scss/variables.scss';
@import '@/assets/scss/mixins';

.message {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  margin: 1.5rem 0 0 1rem;
  padding: 1rem;
  font-size: 1.3rem;
  font-weight: 500;
  background-color: #ff8c00;
  border-radius: 5px;
  width: 99%;
  color: rgba(255, 255, 255, 0.9);
  border: 1px solid $borderColor;
  height: 4.5rem;

  .none{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

}

.colors {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 80px);
  padding: .5rem;

  .card {
    display: flex;
    box-shadow: none;
  }
}

.colors-container{
  height: calc(100vh - 100px);
}

</style>

<style lang="scss">
.colors .card .card-body {
  flex: 1;
}
</style>
