<!-- ButtonBar.vue -->
<template>
  <div class="button-bar">
    <div v-for="(icon, index) in icons" :key="index" class="button" :class="{chosen : chosen == icon}" @click="chosen=icon">
      <font-awesome-icon class="image" :icon="icon"/>
    </div>
  </div>
</template>

<script setup>
import { useSlots, reactive, onMounted, ref, watch } from 'vue';

const emit = defineEmits(['chosen']);

const chosen = ref(null);

// Access slots
const slots = useSlots();

// Create a reactive array to hold the icons
const icons = reactive([]);

const setChosen = (iconValue) => {
  chosen.value = iconValue;
  // emit('item-selected', null); // Emit null to indicate that the selection was cleared
};

defineExpose({ setChosen })

watch(chosen, (newValue) => {
  emit('chosen', newValue);
});

// Process the slot content on mount
onMounted(() => {
  if (slots.default) {
    const slotContent = slots.default();
    slotContent.forEach((node) => {
      if(node.children) {
        if(Array.isArray(node.children)){
          node.children.forEach((child) => {
            if (child.type && child.type == 'FontAwesomeIcon') {
              icons.push(child.props.icon);
            }
          });
        }
      }
    });
  }
});
</script>

<style scoped lang="scss">
.button-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  border: .1rem solid #ccc;
  height: 3rem;
  border-radius: .5rem;
  cursor: pointer;

  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 30px;
  }

  .button:hover {
    background-color: rgba(255, 121, 0, .3);
  }

  .chosen {
    background-color: rgba(255, 121, 0, .6);
  }

}



</style>
