<template>
  <div class="qrcode" id="qrcode">
    <bd-card :headerStyles="{ fontSize: '1.5rem', fontWeight: 700, borderBottom: '1px solid #cbcbcb' }" :footerStyles="cardFooterCSS">
      <template #header>QRCode</template>
      <template #body>
        <div class="qrcode-body">
					<div class="qrcode-body-image">
						<canvas id="qrcode-image"></canvas>
					</div>
        </div>
      </template>
      <template #footer>
        <bd-button size="sm" icon="fal fa-qrcode" @click="generate">Show QRCode</bd-button>
      </template>
    </bd-card>
  </div>
  <teleport to="body">
    <bd-alert :alert="alert"></bd-alert>
  </teleport>
</template>

<script setup>
import { ref, toRefs, computed } from 'vue';
import QRCode from 'qrcode'
import bdCard from '@/components/card/cockpitcard.vue';
import bdAlert from '@/components/alert/alert.vue';
import bdButton from '@/components/button/regular/button.vue';
import { useQRStore } from '@/stores/qrcodeStore.js';
import { useConfigStore } from '@/stores/configStore';

const configStore = useConfigStore();
const qrStore = useQRStore();
const { alert } = toRefs(qrStore);
const cardFooterCSS = {
	display: 'flex',
  flexFlow: 'row-reverse'
};

let qrcodeData = ref({
	orgId: configStore.organization.id,
	action: 'encrypt',
	data: {
		type: 'genesyscloud',
		client: '',
		environment: configStore.location,
		stage: configStore.environment
	}
});

const generate = async () => {
	const encryptedString = await qrStore.encryptQRCode(qrcodeData.value);
	console.log(encryptedString);
	QRCode.toCanvas(document.getElementById("qrcode-image"), encryptedString);
}

const showGenerate = computed(() => {
  let retVal = false;
	if (qrcodeData.value.data.type && qrcodeData.value.data.clientId && qrcodeData.value.data.environment && qrcodeData.value.data.stage) retVal = true;
  // if (settingsStore.isLoading || languagesStore.isLoading || appearanceStore.isLoading || colorStore.isLoading || broadcastStore.isLoading) retVal = true;
  return retVal;
});

</script>

<style scoped lang="scss">
@import '@/assets/scss/variables.scss';
@import '@/assets/scss/mixins';

.qrcode {
	display: flex;
	padding: .5rem;

	.qrcode-body {
		display: flex;
		justify-content: space-between;
		gap: 20px;
		padding-top: 1em;

		.qrcode-body-options {
			display: flex;
			flex-direction: column;
			.qrcode-body-option {
				display: flex;
				margin-bottom: 10px;
				font-size: 12px;
				label {
					width: 200px;
					margin-right: 10px;
				}
				input {
					width: 300px;
					padding: 5px;
					border: 1px solid black;
					border-radius: 5px;
					font-size: 12px;
				}
				#intune {
					flex-basis: content;
				}
			}
		}
	}
}
</style>
<!--
"type": "genesyscloud",
"clientId": "3ca4c1de-0261-4c80-a0ca-c1d1c15c7595",
"environment": "mypurecloud.de",
"stage": "prod"
-->