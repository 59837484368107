<template>
  <div class="appearance-container">
    <div class="appearance-icon">
      <font-awesome-icon :icon="props.data.icon" />
    </div>
    <div class="appearance-detail">
      <div class="item-title">{{ data.title }}</div>
      <div class="item-details">
        <label class="toggle-switchy" data-style="rounded" data-text="false" data-size="xs">
          <input :checked="data.selectedValue" v-model="data.selectedValue" type="checkbox"
            @change="appearanceStore.appearancesChanged = true">
          <div class="toggle">
            <span class="switch"></span>
          </div>
        </label>
      </div>
    </div>
  </div>
  <!-- <div class="appearance-container">
    <div class="appearance-icon">
      <font-awesome-icon :icon="props.data.icon" />
    </div>
    <div>
      <div class="item-title">
        <div class="title">{{ data.title }}</div>
        <div v-if="data.valueType === 'truefalse'" class="toggle-button">
          <label class="toggle-switchy" data-style="rounded" data-text="false" data-size="xs">
            <input :checked="data.selectedValue" v-model="data.selectedValue" type="checkbox"
              @change="appearanceStore.appearancesChanged = true">
            <span class="toggle">
              <span class="switch"></span>
            </span>
          </label>
        </div>
      </div>
    </div>
  </div> -->
</template>

<script setup>
import { defineProps, computed } from "vue";
import { useAppearanceStore } from '@/stores/appearanceStore.js';

const appearanceStore = useAppearanceStore();

const props = defineProps({
  data: {
    type: Object,
    default: {}
  }
});

</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
@import '@/assets/scss/mixins';

.appearance-container {
  display: grid;
  grid-template-columns: 25px 1fr;
  grid-auto-flow: column;
  font-family: Roboto, sans-serif;
  font-size: 11px;
  font-weight: 400;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 0.5em;

  .appearance-icon {
    vertical-align: middle;
    font-size: 1.25em;
    color: $buddyOrange;
  }

  .appearance-detail {
    display: flex;
    align-items: center;

    .item-title {
      display: grid;
      grid-template-columns: auto 50px;

      .title {
        padding: 2px 15px 0px 0px;
        text-overflow: ellipsis;
        font-weight: 500;
      }
    }

    .item-details {
      flex: 1;
      display: flex;
      flex-direction: row-reverse;
    }
  }
}

// .appearance-container {
//   display: grid;
//   grid-template-columns: 25px 1fr;
//   grid-auto-flow: column;
//   font-family: Roboto, sans-serif;
//   font-size: 11px;
//   font-weight: 400;
//   color: #444a52;
//   cursor: pointer;
//   text-decoration: none;

//   .appearance-icon {
//     padding: 1px 0px 0px 5px;
//     vertical-align: middle;
//     font-style: normal;
//     color: $buddyOrange;
//     font-weight: 900;
//     font-size: 1.25em;
//   }

// }

// .item-title {
//   display: grid;
//   grid-template-columns: auto 50px;
// }

// .title {
//   padding: 2px 15px 0px 0px;
//   text-overflow: ellipsis;
// }

// .toggle-button {
//   padding: 0px 0px 5px 0px;
// }

// .number-setting {
//   width: 45px;
//   border-width: inherit;
// }

// .form-select {
//   border: none;
//   font-size: 12px;
//   padding: 2px;
//   background-color: transparent;
// }</style>