<template>
  <div class="treeview-container">
    <div class="treeview-items">
      <TreeViewItem v-for="rootNode in displayData" 
      :item="rootNode"
      :multiSelect="props.multiSelect"
      :activeId="active"
      @nodeLabelClicked="handleNodeLabelClicked"
      @isSelectedChanged="updateIsSelected"
      @nodeOpened="handleNodeOpened">
    </TreeViewItem>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { storeToRefs } from 'pinia';
import TreeViewItem from '@/components/treeview/treeviewitem.vue';
import { useOrganizationStore } from '@/stores/organizationStore.js';

let treeData = [];
let active = ref('');

const props = defineProps({
  items: Array,
  multiSelect: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(['nodeLabelClicked', 'getAgents', 'nodeOpened']);

const organizationStore = useOrganizationStore();
const { displayData } = storeToRefs(organizationStore);

const addParentId = (items, parentId = null) => {
  items.forEach(item => {
    item.parentId = parentId;
    if (item.children && item.children.length > 0) {
      addParentId(item.children, item.id);
    }
  });
};

const flattenData = (items) => {
  items.forEach(item => {
    treeData.push(
      {
        id: item.id,
        name: item.name,
        isSelected: item.isSelected,
        parentId: item.parentId,
      }
    );
    if (item.children && item.children.length > 0) {
      flattenData(item.children);
    }
  });

  console.log(`TreeData -> ${JSON.stringify(treeData,null,2)}`);
};

const buildTree = (items, parentId = null) => {
  console.log(`items -> ${JSON.stringify(items,null,2)}`);
  return items
    .filter(item => item.parentId === parentId)
    .map(item => {
      return {
        ...item,
        children: buildTree(items, item.id),
      };
    });
};

function findNode (id, array) {
  for (const node of array) {
    if (node.id === id) return node;
    if (node.children) {
      const child = findNode(id, node.children);
      if (child) return child;
    }
  }
}

const handleNodeLabelClicked = (node) => {
  active.value = node.id;
  emit('nodeLabelClicked', node);
}

const hasSelectedChildren= (parentNode) => {
  let retVal = false;
  if (parentNode.children && parentNode.children.length > 0) {
    const selectedChildren = parentNode.children.filter(child => child.isSelected);
    if (selectedChildren) {
      if (selectedChildren.length == parentNode.children.length) {
        retVal = true;
      }
    }
  }
  return retVal;
}

const updateChildren = (parentNode) => {
  if (parentNode.children && parentNode.children.length > 0) {
    // console.log('Has children');
    parentNode.children.forEach(child => {
      // console.log(`Child -> ${child.name}`);
      child.isSelected = parentNode.isSelected;
      organizationStore.updateCheckedNodes(child);
      updateChildren(child);
    });
  }
}

const updateParents = (parentId) => {
  const parentNode = findNode(parentId, organizationStore.displayData);
  if (parentNode) {
    // console.log(`parentNode -> ${JSON.stringify(parentNode, null, 2)}`);
    if (hasSelectedChildren(parentNode)) {
      parentNode.isSelected = true;
      organizationStore.updateCheckedNodes(parentNode);
    } else {
      parentNode.isSelected = false;
      organizationStore.updateCheckedNodes(parentNode);
    }
    if (parentNode.parentId) {
      updateParents(parentNode.parentId);
    }
  }
}

const updateIsSelected = (clickedNode) => {
  const nodeDetails = findNode(clickedNode.id, organizationStore.displayData);
  organizationStore.updateCheckedNodes(nodeDetails);
  updateChildren(nodeDetails);
  updateParents(nodeDetails.parentId);
}

const handleNodeOpened = (node) => {
  emit('nodeOpened', node);
}

onMounted(async () => {
  // await organizationStore.getOrganization();
})
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
@import '@/assets/scss/mixins';

.treeview-container {
  width: 100%;

  .treeview-items {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 0vh; // This is a hack to limit the list to the height of the parent container
  }
}


</style>