<template>
  <div class="nav-bar" :style="{height: props.height}">
    <div v-if="!nologo" class="logo" @click="goHome">
      <div class="wfm">WFM</div>
      <div class="buddy">BUDDY</div>
    </div>
    <bd-menu-item v-for="item of props.items"
      :key="`${item.id}-${item.id === chosen}`"
      :id="item.id"
      :name="item.name"
      :link="item.link"
      :height="props.height"
      :chosen="item.id === chosen"
      @chosen="emitTabSelected"
      >
    </bd-menu-item>
    <div :class="left ? 'left' : center ? 'center' : 'right'">
      <slot></slot>
    </div>
  </div>
</template>

<script setup>
  import bdMenuItem from './menuitem.vue'
  import { useRouter } from 'vue-router'
  import { ref } from 'vue'

  const emit = defineEmits(['tabSelected']);

  const emitTabSelected = (tabId, tabName) => {
    chosen.value = tabId;
    emit('tabSelected', tabName);
};

  const router = useRouter()
  const goHome = () =>{
    chosen.value = 0;
    router.push('/');
  }

  const chosen = ref(0);

  const props = defineProps({
    height: {
      type: String,
      default: '50px'
    },
    nologo: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      default: () => []
    },
    left: {
      type: Boolean,
      default: false
    },
    center: {
      type: Boolean,
      default: false
    }
  });
</script>

<style scoped lang="scss">
  @import '@/assets/scss/variables.scss';
  @import '@/assets/scss/mixins.scss';

  .nav-bar {
    @include flex(row, flex-start, center);
    background-color: $backgroundColor;
    border-bottom: 1px solid $borderColor;
    font-size: 1rem;
    user-select: none;

    .right, .left, .center{
      @include flex(row, flex-end, center);
      height: 100%;
      width: 100%;
      margin-right: 1em;
      gap: 1em;
    }

    .left {
      @include flex(row, flex-start, center);
      @include rem(padding, 0 0 0 10px);
    }
    .center {
      @include flex(row, center, center);
    }

    .logo {
      @include flex(row, space-between, center);
      font-family: 'TwentiethCenturyBold';
      font-weight: 500;
      font-size: 1.7em;
      color: $textColor;
      height: 100%;
      width: 18em;
      margin: 0 1em 0 1em;
      cursor: pointer;
      gap: .3em;

      .wfm {
        color: $buddyOrange;
        flex:1;
        text-align: right;
      }

      .buddy {
        color: #363636;
        flex: 1;
      }

    }
  }

</style>