<template>
  <div class="appearances">
    <div class="message" v-if="isEmpty(selectedNode) || isEmpty(displayAppearances)">
      <font-awesome-icon icon="far fa-circle-info" />
      <div v-if="!isLoading && isEmpty(selectedNode)">Please select an organization level to view details</div>
      <div v-if="!isLoading && !isEmpty(selectedNode) && isEmpty(displayAppearances)">
        No appearance stored for {{ selectedNode.type == 'businessUnit' ? 'business unit' : selectedNode.type == 'managementUnit' ? 'management unit' : selectedNode.type }} "{{selectedNode.name}}"
      </div>
      <div v-if="isLoading">Loading ...</div>
    </div>
    <div class="appearances-section" v-if="displayAppearances.length !== 0" v-for="section in displayAppearances">
      <bd-card :headerStyles="{ fontSize: '1em', fontWeight: 500 }">
        <template #header>{{ section.sectionTitle }}</template>
        <template #body>
          <ul class="list-group">
            <li class="list-group-item" v-for="item in section.sectionData">
              <div class="setting-item">
                <Setting :data="item"></Setting>
              </div>
            </li>
          </ul>
          </template>
      </bd-card>
    </div>
  </div>
  <teleport to="body">
    <bd-alert :alert="alert"></bd-alert>
  </teleport>
</template>

<script setup>
import { toRefs } from 'vue';
import Setting from '@/views/Appearance/AppearanceSetting.vue';
import { useAppearanceStore } from '@/stores/appearanceStore';
import bdCard from '@/components/card/cockpitcard.vue';
import bdAlert from '@/components/alert/alert.vue';
import { storeToRefs } from 'pinia';
import { isEmpty } from 'lodash';
import { useOrganizationStore } from '@/stores/organizationStore.js';

const appearanceStore = useAppearanceStore();
const { alert } = toRefs(appearanceStore);

const organizationStore = useOrganizationStore();
const { selectedNode } = storeToRefs(organizationStore);
const { isLoading, displayAppearances} = storeToRefs(appearanceStore);

</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
@import '@/assets/scss/mixins';

.message {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  margin: 1.5rem 0 0 1rem;
  padding: 1rem;
  font-size: 1.3rem;
  font-weight: 500;
  background-color: #ff8c00;
  border-radius: 5px;
  width: 99%;
  color: rgba(255, 255, 255, 0.9);
  border: 1px solid $borderColor;
  height: 4.5rem;
}

.appearances {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: flex-start;
  padding: 3px 10px 5px 10px;
  height: calc(100vh - 80px);
  overflow-x: auto;

  .appearances-section {
    max-width: 23em;
    width: calc(25% - 10px);
    box-sizing: border-box;
    padding: 10px;
    min-width: 250px;

    .card {
      margin: 0px;
      min-width: 15em;

      .setting-item {
        background-color: $bgColorExtraLight;
        padding-top: 0px;
      }
    }
  }
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

li {
  padding: 0em 0em 0.5em 0em;
}
</style>