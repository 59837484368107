<template>
  <div class="dropdown" v-click-outside="hide" :data-value="selectedOption" @click="toggleOpen">
    <!-- <div class="label">{{ selectedOption }}</div> -->
    <input type="text" v-model="searchQuery" @input="filterOptions" placeholder="Type to search">
    <div class="arrow"><font-awesome-icon icon="fas fa-triangle" :class="{ 'open': isOpen }" /></div>
  </div>
  <ul class="options-list" v-if="isOpen">
    <li v-for="option in filteredOptions" :key="option" @click="selectOption(option)">{{ option }}</li>
  </ul>
</template>"

<script setup>
import { ref, computed } from 'vue'
const emit = defineEmits(['optionSelected']);

const selectedOption = ref('Select a Language...');
const searchQuery = ref('');
const isOpen = ref(false);

const props = defineProps({
  options: {
    type: Array,
    required: true
  }
});

const filteredOptions = computed(() => {
  return props.options.filter(option => option.toLowerCase().startsWith(searchQuery.value.toLowerCase()));
});

const filterOptions = () => {
  isOpen.value = true;
};



const hide = () => {
  isOpen.value = false;
}

const toggleOpen = () => {
  isOpen.value = !isOpen.value;
}

const selectOption = (option) => {
  searchQuery.value = option;
  isOpen.value = false;
  emit('optionSelected', option);
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
@import '@/assets/scss/mixins';

.dropdown {
  display: flex;

  .label {
    flex: 1;
    // background-color: aquamarine;
  }

  .arrow {
    color: $buddyOrange;
    transform: rotate(180deg);
    line-height: 1.5;
    margin-left: 0.5em;
  }
}

.open {
  transform: rotate(180deg);
}

ul {
  position: absolute;
  list-style: none;
  max-height: 15em;
  padding: 0;
  margin: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top: none;
  overflow-y: scroll;
}

li {
  padding: 0.5rem;
  cursor: pointer;
}

li:hover {
  background-color: #f0f0f0;
}
</style>