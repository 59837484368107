<template>
  <div class="broadcast" id="broadcast">
    <div class="broadcast-top">
      <bd-card
        :headerStyles="{ padding: '1rem', fontSize: '1.5rem', fontWeight: 700, borderBottom: '1px solid #cbcbcb' }">
        <template #header>Message Settings</template>
        <template #body>
          <div class="message-settings">
            <div class="urgent">
              <input type="checkbox" id="urgent" name="urgent" v-model="message.urgent">
              <label for="urgent" class="title">Mark Urgent</label>
            </div>
            <div class="icon-settings">
              <bd-button-bar ref="buttonBar" @chosen="message.icon = $event">
                <template>
                  <FontAwesomeIcon icon="fa-light fa-user-headset" />
                  <FontAwesomeIcon icon="fa-light fa-exclamation" />
                  <FontAwesomeIcon icon="fa-light fa-question" />
                  <FontAwesomeIcon icon="fa-light fa-comment-alt-lines" />
                  <FontAwesomeIcon icon="fa-light fa-phone-alt" />
                </template>
              </bd-button-bar>
            </div>
            <div class="icon-title">
              <label for="icon-buttons" class="title">Select Icon (optional)</label>
            </div>
            <div class="color-picker">
              <MDBColorPicker id="mdb-color-picker" v-model="message.color" />
              <label class="title">Select Color (optional)</label>
            </div>
          </div>
        </template>
      </bd-card>
    </div>
    <div class="broadcast-bottom">
      <bd-card
        :headerStyles="{ padding: '1rem', fontSize: '1.5rem', fontWeight: 700, borderBottom: '1px solid #cbcbcb' }"
        :bodyStyles="messageCardBodyCSS" :footerStyles="messageCardFooterCSS">
        <template #header :class="compose - header">
          <div class="title">Compose Message</div>
          <div class="system-message">
            <div class="search" v-if="broadcastStore.systemMessages.length > 0">
              <bdDropdown ref="selectSystemMessage" :items="broadcastStore.systemMessages" displayProperty="title" keyProperty="id"
                @item-selected="handleMessageSelected" </bdDropdown>
            </div>
            <div class="delete-system-message" v-if="systemMessageSelected">
              <icon-button icon="far fa-trash-alt" size="xl" @click="showConfirmDelete = true" />
            </div>
          </div>
        </template>
        <template #body>
          <div class="broadcast-body">
            <div class="title">Title</div>
            <div class="title-text">
              <input id="broadcast-title" type="text" v-model="message.title" />
            </div>
            <div class="message">Message</div>
            <div class="message-text">
              <QuillEditor id="editor" theme="snow" contentType="html" v-model:content="message.body" />
            </div>
          </div>
        </template>

        <template #footer>
          <div class="broadcast-footer">
            <bd-button class="footer-button" size="sm" icon="far fa-trash-alt" @click="clearMessage()">Clear</bd-button>
            <bd-button class="footer-button" size="sm" icon="far fa-floppy-disk" v-if="!systemMessageSelected" @click="saveSystemMessage()"
              :enabled="allowSaveTemplate">Save Template</bd-button>
            <bd-button class="footer-button" size="sm" icon="far fa-floppy-disk" v-if="systemMessageSelected" @click="updateSystemMessage()"
              :enabled="allowSaveTemplate">Update Template</bd-button>
            <bd-button class="footer-button" size="sm" icon="far fa-envelope" @click="sendMessage()"
              :enabled="allowBroadcast && allowSaveTemplate">Send</bd-button>
          </div>
        </template>
      </bd-card>
    </div>
  </div>
  <div class="system-message" v-if="showSystemMessageDialog">
    <system-message-dialog :message="message" />
  </div>
  <teleport to="body">
    <bd-alert :alert="alert"></bd-alert>
  </teleport>
  <teleport to="body">
    <bd-idiot v-if="showConfirmDelete" :message="[`${setDisplayMessage}`, 'Are you sure?']"
      @close="showConfirmDelete = false" @proceed="deleteSystemMessage()"></bd-idiot>
  </teleport>
</template>

<script setup>
import { ref, toRefs, computed, onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import bdCard from '@/components/card/cockpitcard.vue';
import bdAlert from '@/components/alert/alert.vue';
import bdButton from '@/components/button/regular/button.vue';
import iconButton from '@/components/button/icon/button.vue'
import bdIdiot from '@/components/modals/idiot/idiot.vue';
import bdButtonBar from '@/components/buttonbar/ButtonBar.vue';
import { useBroadcastStore } from '@/stores/broadcastStore.js';
import { useOrganizationStore } from '@/stores/organizationStore.js';
import { MDBColorPicker } from "mdb-vue-color-picker";
import systemMessageDialog from '@/views/Broadcast/SystemMessageDialog.vue'
import bdDropdown from '@/components/select/general/selectSystemMessage.vue';
import { config } from '@fortawesome/fontawesome-svg-core';
import { useConfigStore } from '../../stores/configStore';

const organizationStore = useOrganizationStore();
const broadcastStore = useBroadcastStore();
const { allowBroadcast } = storeToRefs(organizationStore);
const { showSystemMessageDialog } = storeToRefs(broadcastStore);
const systemMessageSelected = ref(false);
const selectSystemMessage = ref(null);
const showConfirmDelete = ref(false);

// Ref for accessing child component instance
const buttonBar = ref(null)

const message = ref({
  title: '',
  body: '',
  urgent: false,
  icon: '',
  color: ''
});

const messageCardBodyCSS = {
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#ffffff'
};

const messageCardFooterCSS = {
  height: '6rem'
};

const { alert } = toRefs(broadcastStore);

const setDisplayMessage = computed(() => {
  const message = `This will delete the current Stored Message.`
  return message;
})

function clearMessage() {
  // Need to send a null character to clear the message
  message.value.body = String.fromCharCode(0);
  message.value.title = '';
  message.value.urgent = false;
  message.value.icon = 'fa-light fa-user-headset';
  message.value.color = '#000000';

  buttonBar.value.setChosen(message.value.icon);
  systemMessageSelected.value = false;
  selectSystemMessage.value.clearSelection();
}

function deleteSystemMessage() {
  clearMessage();
  console.log('message.value', message.value.id);
  broadcastStore.deleteSystemMessage(message.value.id);
  showConfirmDelete.value = false;
}

const handleMessageSelected = (selectedItem) => {
  console.log('selectedItem', selectedItem);
  if (selectedItem) {
    message.value.id = selectedItem.id;
    message.value.title = selectedItem.title;
    message.value.body = selectedItem.body;
    message.value.urgent = selectedItem.urgent;
    message.value.icon = selectedItem.icon;
    message.value.color = selectedItem.color;
    message.value.recieverId = selectedItem.recieverId;
    buttonBar.value.setChosen(selectedItem.icon);
    systemMessageSelected.value = true;
  }
};
async function sendMessage() {
  await broadcastStore.sendMessage(message.value, organizationStore.checkedNodes);
}

async function saveSystemMessage() {
  showSystemMessageDialog.value = true;
  // await broadcastStore.saveSystemMessage(message.value, organizationStore.checkedNodes);
}

async function updateSystemMessage() {
  await broadcastStore.saveSystemMessage(message.value, message.value.recieverId , true);
}

const allowSaveTemplate = computed(() => {
  let retVal = true; 
  if (message.value.title === '' || message.value.body === '') {
    retVal = false;
  }
  return retVal;
});

onMounted(() => {
  buttonBar.value.setChosen('fa-light fa-user-headset');
});
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
@import '@/assets/scss/mixins';

.broadcast {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 80px);

  .broadcast-top {
    font-size: 1.3rem;
    padding: .5rem;

    .message-settings {
      display: flex;
      flex-direction: row;

      .title {
        margin-left: 1em;
      }

      .urgent {
        display: flex;
        align-items: center;
        margin: 1rem 0;

        input[type="checkbox"] {
          width: 1.5rem;
          height: 1.5rem;
          margin-left: 0.5rem;
        }
      }

      .icon-title {
        align-content: center;
      }

      .icon-settings,
      .color-picker {
        display: flex;
        flex-direction: row;

        .title {
          margin-top: 1.5em;
        }
      }

      .icon-settings .title {
        margin-top: 0.75em;
      }

      .icon-settings {
        margin: 0.5em 0em 0.5em 7em;
      }

      .color-picker {
        padding-left: 5em;
      }
    }
  }

  .broadcast-bottom {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: .5rem;

    .card {
      display: flex;
      flex-direction: column;
      flex: 1;
      // background-color: aqua;

      .system-message {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        // padding: 1rem 0;

        .search {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-right: 1em;
          // font-size: 12px !important;
          // width: 20em;
        }

        .delete-system-message {
          display: flex;
          flex-direction: row;
          align-items: center;
        }
      }

      .broadcast-body {
        flex: 1;
        display: flex;
        flex-direction: column;
        padding: 1rem;

        .title {
          height: 2em;
          padding: 1em 0em 2em 0em;
          font-weight: 500;
        }


        .title-text {
          #broadcast-title {
            width: -webkit-fill-available;
            border: 1px solid #d1d5db;
            height: 2.5rem;
            padding-left: 1rem;
          }
        }

        .message {
          height: 2em;
          padding: 1em 0em 2em 0em;
          font-weight: 500;
        }

        .message-text {
          display: flex;
          flex-direction: column;
          flex: 1;
        }
      }

      .broadcast-footer {

        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        padding-top: 1rem;

        .footer-button {
          margin-right: 1em;
        }
      }
    }
  }
}
</style>

<style lang="scss">
.form-control {
  width: 2.5em;
  height: 2.5em;
  cursor: pointer;
  align-self: center;
}

.ql-editor {
  background: #ffffff;
}

.ql-toolbar {
  background: rgba(255, 255, 255, 0.71);
}

.broadcast-bottom .card .card-header {
  display: flex;
  justify-content: space-between;
}

// .card-body {
//   height: 100%;
//   display: flex;
//   flex-direction: column;
//   background-color: #ffffff;
// }

// .card-footer { 
//   height: 6rem;
// }

#editor {
  flex: 1;
  display: flex;
  flex-direction: column;
}
</style>
