<template>
<div class="button-icon" :class="props.size, { pressed: isPressed }, {noshadow : props.noshadow}" @mousedown="press" @mouseup="release">
  <font-awesome-icon class="button-image" :icon="props.icon"/>
</div>
</template>

<script setup>

import { ref } from 'vue';

const props = defineProps({
  size: {
    type: String,
    default: 'md'
  },
  icon: {
    type: String,
    default: 'fa-light fa-chevron-left'
  },
  noshadow: {
    type: Boolean,
    default: false
  }
})

const isPressed = ref(false);

function press() {
  isPressed.value = true;
  setTimeout(() => {
    isPressed.value = false;
  }, 500); // 200 milliseconds
};

function release() {
  isPressed.value = false;
};

</script>

<style scoped lang="scss">

  @import '../../../assets/scss/variables.scss';
  @import '../../../assets/scss/mixins.scss';

  .button-icon{
    font-size: 1rem;
    @include flex(row, center, center);
    border: .1em solid $borderColor;
    border-radius: .5em;
    box-shadow: 0 .2em .4em rgba(0, 0, 0, 0.1);
    color: $dividerColor;
    cursor: pointer;
    .button-image{
      pointer-events: none;
      height: -webkit-fill-available;
      padding: 0.5em;
      color: $buddyOrange;
    }
    &.xl{
      height: 3em;
      width: 3em;
    }
    &.lg{
      height: 2.4em;
      width: 2.4em;
    }
    &.md{
      height: 2.1em;
      width: 2.1em;
    }
    &.sm{
      height: 1.9em;
      width: 1.9em;
    }

    &.pressed {
      box-shadow: 0 0 .2em rgba(0, 0, 0, 0.1);
      background-color: rgba(255, 121, 0, .3);
    }
    &.noshadow{
      box-shadow: none;
    }
    &.noshadow:hover{
      box-shadow: 0 0 .2em rgba(0, 0, 0, 0.1);
    }
  }


</style>