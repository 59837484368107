<template>
  <div class="td" :class="{calendar}" :style="{'justify-content':align, 'flex-basis': width, 'flex-grow': grow ? 1 : '', 'flex-shrink' : shrink ? 0 : ''}" @mouseover="mouseover" @mouseleave="mouseleave" @click="openCalendar">
    <span v-if="!widget">
      <slot></slot>
    </span>

    <div v-if="pen && !widget" class="pen">
      <font-awesome-icon icon="fa-thin fa-pen" />
    </div>
    <!-- <div v-if="plus && !widget" class="plus">
      <font-awesome-icon icon="fa-light fa-calendar-plus" />
    </div> -->
    <bd-calendar v-if="widget" :date="date" @select="select" @open="$emit('open')" @close="widget=false; pen=false"/>
  </div>
</template>

<script setup>

  import bdCalendar from '@/components/calendar/calendar.vue';
  import { ref, toRefs } from 'vue';

  const emit = defineEmits(['select', 'open'])

  const props = defineProps({
    align: {
      type: String,
      default: ''
    },
    date: {
      type: String,
      default: ''
    },
    calendar: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: '100%'
    },
    grow: {
      type: Boolean,
      default: false
    },
    shrink: {
      type: Boolean,
      default: false
    }
  })

  const { align, date, calendar } = toRefs(props);
  const pen = ref(false);
  const widget = ref(false);

  const mouseover = () => {
    if(calendar.value) pen.value = true;
  }

  const mouseleave = () => {
    pen.value = false;
  }

  const openCalendar = () => {
    if(calendar.value) widget.value = true;
  }

  const select = (date) => {
    widget.value = false;
    pen.value = false;
    emit('select', date);
  }
</script>

<style scoped lang='scss'>

    @import '../../assets/scss/variables.scss';
    @import '../../assets/scss/mixins.scss';

    .td{
      @include flex(row, flex-start, center);
      @include rem(height, 40px);
      @include rem(padding, 0 10px);

      span{
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        min-width: 0;
      }

      &.calendar{
        cursor: pointer;
      }

      .pen{
        color: gray;
        @include rem(margin-left, 10px);
      }
    }
</style>