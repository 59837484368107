<template>
  <div class="leftpanel">
    <div class="leftpanel-top">
      <bd-card style="width:100%">
        <template #header>
          <div class="title">Organization Structure</div>
        </template>

        <template #body>
          <bd-treeview :multiSelect="enableMultiSelect()" @nodeLabelClicked="treeviewNodeChanged($event)"
            @nodeOpened="handleNodeOpened($event)" />
        </template>
      </bd-card>
    </div>
    <div class="leftpanel-bottom" v-if="currentTab === 'languagesTab'">
      <bd-card>

        <template #header>
          <div class="title">Selected Languages</div>
        </template>

        <template #body>
          <div class="languages">
            <ul class="languages-list" id="languages-list">
              <li v-for="language in languagesStore.displayLanguages" :key="language.iso639">
                <div :class="['languages-list-item', { 'active': activeLanguageId === language.iso639 }]">
                  <input class="form-check-input me-1" type="checkbox"
                    :checked="languagesStore.isLanguageAssigned(language)"
                    @change="(e) => { languagesStore.updateLanguageMappings(e.target.checked, language) }" />
                  <div class="languageTitle">
                    {{ language.language }}
                  </div>
                  <div class="language-buttons">
                    <bd-button size="sm" icon="fas fa-upload"
                      @click="languagesStore.exportLanguage(language.iso639)"></bd-button>
                    <bd-button size="sm" v-if="!languagesStore.isLanguageDefault(language.iso639)"
                      @click="languageToDelete = language; showConfirmDelete = true"
                      icon="fas fa-trash-can"></bd-button>
                    <bd-button size="sm" icon="fas fa-pen-to-square"
                      @click="editLanguage(language.iso639)"></bd-button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </template>
      </bd-card>
    </div>
  </div>
  <teleport to="body">
    <bd-idiot v-if="showConfirmDelete" :message="[`${setDisplayMessage}`, 'Are you sure?']"
      @close="showConfirmDelete = false" @proceed="deleteLanguage"></bd-idiot>
  </teleport>
</template>

<script setup>
import { onMounted, computed, onUpdated, watch, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useOrganizationStore } from '@/stores/organizationStore.js';
import { useSettingsStore } from '@/stores/settingsStore.js';
import { useLanguagesStore } from '@/stores/languagesStore.js';
import { useAppearanceStore } from '@/stores/appearanceStore.js';
import { useColorStore } from '@/stores/colorStore.js'
import { useBroadcastStore } from '@/stores/broadcastStore.js';
import { useMessagingStore } from '@/stores/messagingStore.js';
import bdTreeview from '@/components/treeview/treeview.vue';
import bdCard from '@/components/card/card.vue';
import bdButton from '@/components/button/regular/button.vue';
import bdIdiot from '@/components/modals/idiot/idiot.vue'
import { isEmpty } from 'lodash';

const organizationStore = useOrganizationStore();
const settingsStore = useSettingsStore();
const languagesStore = useLanguagesStore();
const appearanceStore = useAppearanceStore();
const colorStore = useColorStore();
const broadcastStore = useBroadcastStore();
const messagingStore = useMessagingStore();
const activeLanguageId = ref();

const currentTab = computed(() => organizationStore.currentTab);
const { selectedNode } = storeToRefs(organizationStore);
const { currentLanguage } = storeToRefs(languagesStore);
const languageToDelete = ref({});
const showConfirmDelete = ref(false);

const setDisplayMessage = computed((language) => {
  const languageObject = languageToDelete.value;
  const message = `This will delete the ${languageObject.language} language for ${organizationStore.selectedNode.name}`
  return message;
})

const computedClass = computed(() => {
  return isActive.value ? 'active-class' : 'inactive-class';
});

const deleteLanguage = () => {
  const languageObject = languageToDelete.value;
  languagesStore.deleteLanguage(languageObject.iso639);
  showConfirmDelete.value = false;
}

function enableMultiSelect() {
  if (organizationStore.currentTab === 'broadcastTab') return true;
  else return false;
}

function disableDivAndChildren(divId) {
  const parentDiv = document.getElementById(divId);
  if (parentDiv) {
    parentDiv.classList.add('disabled');

    const childElements = parentDiv.querySelectorAll('*');
    childElements.forEach(element => {
      element.disabled = true;
    });
  }
}

function enableDivAndChildren(divId) {
  const parentDiv = document.getElementById(divId);
  if (parentDiv) {
    parentDiv.classList.remove('disabled');

    const childElements = parentDiv.querySelectorAll('*');
    childElements.forEach(element => {
      element.disabled = false;
    });
  }
}

async function clearCurrentSettings() {
  const tabNames = ['settingsTab', 'colorsTab', 'appearanceTab', 'messagingTab', 'broadcastTab', 'languagesTab'];
  const tab = organizationStore.currentTab;
  for (const tabName of tabNames) {
    if (tabName != tab) {
      switch (tabName) {
        case 'settingsTab':
          // settingsStore.displaySettings = [];
          await settingsStore.reset();
          break;
        case 'colorsTab':
          await colorStore.reset();
          break;
        case 'appearanceTab':
          appearanceStore.displayAppearances = [];
          await appearanceStore.reset();
          break;
        case 'messagingTab':
          break;
        case 'broadcastTab':
          break;
        case 'languagesTab':
          await languagesStore.reset();
          languagesStore.setInitialTemplate();
          break;
      }
    }
  }
}

async function treeviewNodeChanged(event) {
  const nodeObject = event;
  if (event != organizationStore.selectedNode) {
    organizationStore.selectedNode = nodeObject;
    switch (organizationStore.currentTab) {
      case 'settingsTab':
        if (nodeObject.type != 'division') await settingsStore.getSettings()
        else settingsStore.displaySettings = [];
        break;
      case 'colorsTab':
        await colorStore.getColors();
        break;
      case 'appearanceTab':
        await appearanceStore.getAppearances();
        break;
      case 'messagingTab':
        await messagingStore.getMessaging();
        break;
      case 'broadcastTab':
        if ((nodeObject.type != 'agent') && (nodeObject.type != 'managementUnit')) await broadcastStore.getSystemMessages();
        break;
      case 'languagesTab':
        await languagesStore.getLanguages();
        languagesStore.showSelection = true;
        break;
    }
    clearCurrentSettings();
  }
}

async function handleNodeOpened(node) {
  // console.log(`GetAgents -> ${JSON.stringify(node, null, 2)}`);
  await treeviewNodeChanged(node);
  // Check if there are already children for this node
  if (node.children && node.children.length > 0) {
    // If there are children, then we don't need to make the API call
    return;
  }
  await organizationStore.addAgentsToMU(node);
}

const editLanguage = (iso639) => {
  activeLanguageId.value = iso639;
  languagesStore.setDisplayLanguage(iso639);
  // setActiveLanguageId(iso639);
};

watch(selectedNode, (value) => {
  enableDivAndChildren('languages-list');
})

watch(currentLanguage, (language) => {
  if (!isEmpty(language)) {
    activeLanguageId.value = language.iso639;
    // setActiveLanguageId(language.iso639);
  }
})

onMounted(() => {
  disableDivAndChildren('languages-list');
})

onUpdated(() => {
  if (Object.keys(organizationStore.selectedNode).length === 0) disableDivAndChildren('languages-list');
})

</script>

<style scoped lang="scss">
@import '@/assets/scss/variables.scss';
@import '@/assets/scss/mixins';

.leftpanel {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 80px);
  background-color: $bgColorExtraLight;
  user-select: none;

  .leftpanel-top {
    display: flex;
    flex: 1;
    padding: .8rem;
  }

  .leftpanel-bottom {
    display: flex;
    flex: 1;
    padding: .8rem;

    .languages {
      display: flex;
      flex-direction: row;
      width: 100%;

      .languages-list {
        flex: 1;
        list-style: none;
        padding: unset;

        .languages-list-item {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 0.75em 0em 0.75em 0em;
          border-bottom: 1px solid $borderColor;

          .languageTitle {
            flex: 1;
            padding-left: 0.25em;
          }

          .language-buttons {
            display: flex;
            // flex-direction: row;
            // align-items: center;
            padding-right: 0.25em;
            gap: 0.25em;
          }
        }
      }
    }
  }
}

.languages-list-item.active {
  color: $btn-regular-bgcolor;
  font-weight: 700;
}
</style>

<style lang="scss">
@import '@/assets/scss/variables.scss';
@import '@/assets/scss/mixins';

.leftpanel .card {
  background-color: $bgColorMedium;

  .card__header {
    display: flex;
    flex-direction: row;
    height: 2.5rem;
    border-bottom: none;

    .title {
      flex: 1;
      font-weight: 700;
      text-align: center;
    }
  }

  .card__body {
    overflow-y: auto;
  }
}
</style>