<template>
  <div class="languages" id="languages">
    <bd-card>
      <template #body>
        <div class="languages-navigation">
          <bd-navigation :items="menuLanguageData" :nologo="true" @tabSelected="tabSelected">
            <bd-button size="sm" icon="fas fa-floppy-disk" v-if="languagesStore.languageDataChanged"
              @click="languagesStore.saveLanguages(null)">Save</bd-button>
            <bd-button size="sm" icon="fas fa-history" v-if="languagesStore.languageDataChanged"
              @click="languagesStore.revertLanguages()">Revert</bd-button>
            <bd-button size="sm" icon="fas fa-layer-plus" v-if="!languagesStore.languageDataChanged"
              @click="languagesStore.showNewDialog = true">New</bd-button>
            <bd-button size="sm" icon="fas fa-download" v-if="!languagesStore.languageDataChanged"
              @click="importLanguage()">Import</bd-button>
          </bd-navigation>
        </div>
        <div class="languages-sections" id="languages-sections">
          <languageTab />
        </div>
      </template>
    </bd-card>
  </div>
  <div class="new-language" v-if="showNewDialog">
    <new-language-dialog />
  </div>
  <teleport to="body">
    <bd-alert :alert="alert"></bd-alert>
  </teleport>
  <teleport to="body">
    <bd-idiot v-if="showConfirmDelete" :message="[`${setDisplayMessage}`, 'Are you sure?']"
      @close="showConfirmDelete = false" @proceed="deleteSettings"></bd-idiot>
  </teleport>
  <input style="display:none" id="inputLanguage" type="file" accept=".json, .js" @change="uploadLanguage($event)">
</template>

<script setup>
import { toRefs, ref, watch, computed, onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import ISO6391 from 'iso-639-1';
import { isEmpty } from 'lodash';
import bdNavigation from '@/components/nav/navbar.vue';
import bdButton from '@/components/button/regular/button.vue';
import bdCard from '@/components/card/cockpitcard.vue';
import bdAlert from '@/components/alert/alert.vue';
import bdIdiot from '@/components/modals/idiot/idiot.vue';
import newLanguageDialog from '@/views/Languages/LanguageNewDialog.vue';
import { useConfigStore } from '@/stores/configStore';
import { useLanguagesStore } from '@/stores/languagesStore';
import { useOrganizationStore } from '@/stores/organizationStore.js';
import languageTab from '@/views/Languages/LanguageSections.vue';

const configStore = useConfigStore();
const languagesStore = useLanguagesStore();
const organizationStore = useOrganizationStore();

const { menuLanguageData } = toRefs(configStore);
const { selectedNode } = storeToRefs(organizationStore);
const { currentLanguageTab } = storeToRefs(languagesStore);
const { enableLanguageSelection, showNewDialog } = storeToRefs(languagesStore);
const { alert } = toRefs(languagesStore);

const showConfirmDelete = ref(false);

// This converts the tab to a name so I don't have to refactor all my old code
const tabSelected = (tab) => {
  let tabName;
  switch (tab) {
    case 'General':
      tabName = 'generalTab';
      break;
    case 'PopupForms':
      tabName = 'popupFormsTab';
      break;
    case 'Login':
      tabName = 'loginTab';
      break;
    case 'Availability':
      tabName = 'availabilityTab';
      break;
    case 'TimeOff':
      tabName = 'timeoffTab';
      break;
    case 'Trades':
      tabName = 'tradesTab';
      break;
    case 'Scheduling':
      tabName = 'schedulingTab';
      break;
    case 'Overtime':
      tabName = 'overtimeTab';
      break;
    default:
      tabName = 'generalTab';
  }
  // console.log('Tab selected: ', tabName);
  languagesStore.currentLanguageTab = tabName;
}

function importLanguage() {
  document.getElementById('inputLanguage').click();
}

function uploadLanguage(event) {
  var reader = new FileReader();
  reader.onload = function (evt) {
    var result;

    try {
      result = JSON.parse(evt.target.result);
    } catch (error) {
      app.dialog.alert("File does not contains a vaild json structure.");
    }

    if (typeof result != 'undefined') {
      console.log(`result -> ${JSON.stringify(result, null, 2)}`)
      languagesStore.importLanguage(result);
    }
  };

  reader.readAsText(event.target.files.item(0));
  event.target.value = '';
}

function disableDivAndChildren(divId) {
  const parentDiv = document.getElementById(divId);
  if (parentDiv) {
    parentDiv.classList.add('disabled');

    const childElements = parentDiv.querySelectorAll('*');
    childElements.forEach(element => {
      element.disabled = true;
    });
  }
}

function enableDivAndChildren(divId) {
  const parentDiv = document.getElementById(divId);
  if (parentDiv) {
    parentDiv.classList.remove('disabled');
    const childElements = parentDiv.querySelectorAll('*');
    childElements.forEach(element => {
      element.disabled = false;
    });
  }
}

watch(selectedNode, (newVal) => {
  if (Object.keys(organizationStore.selectedNode).length > 0) enableDivAndChildren('languages');
  else disableDivAndChildren('languages');
});

onMounted(() => {
  var element = document.getElementById("languages");
  if (element == null) return;
  else {
    if (Object.keys(organizationStore.selectedNode).length > 0) enableDivAndChildren('languages');
    else disableDivAndChildren('languages');
  }
});
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
@import '@/assets/scss/mixins';

.languages{
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 80px);
  padding: .5rem;
}

.disabled {
  cursor: not-allowed;
  user-select: none;
  pointer-events: none;
  opacity: 0.4;
}
</style>