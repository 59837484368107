<template>
  <div class="tr" @mouseover="mouseover=true" @mouseleave="mouseover=false" :style="style" @click="selectRow">
    <div class="marker" :style="{backgroundColor:marker}">
    </div>
    <slot></slot>
    <div class="action">
      <div class="delete" v-if="del && hovered" @click.stop="deleteRow">
        <font-awesome-icon icon="fa-light fa-trash"/>
      </div>
      <div class="delete" v-if="deleted && undo" @click.stop="undoMe">
        <font-awesome-icon icon="fa-light fa-rotate-left"/>
      </div>
      <div class="edit" v-if="edit && hovered" @click="editMe">
        <font-awesome-icon icon="fa-thin fa-pen" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, inject, computed } from 'vue';

const props = defineProps({
  align: {
    type: String,
    default: ''
  },
  id: {
    type: String,
    default: ''
  }
})

const emit = defineEmits(['delete', 'undo', 'select', 'edit']);

const hover = inject('hover');
const select = inject('select');
const multiple = inject('multiple');
const del = inject('delete');
const undo = inject('undo');
const edit = inject('edit');
const selState = inject('selectState');
const delState = inject('deleteState');

const deleted = computed(() => delState.includes(props.id) ? true : false);
const mouseover = ref(false);
const selected = computed(() => selState.includes(props.id) ? true : false);


const deleteRow = () => {
  if(undo) deleted.value = true;
  if(undo) delState.push(props.id);
  emit('delete');
}

const undoMe = () => {
  delState.splice(delState.indexOf(props.id), 1);
  deleted.value = false;
  emit('undo');
}

const editMe = () => {
  emit('edit');
}

const selectRow = () => {
  if(select && !deleted.value){
    if(!multiple && selState.length > 0){
      selState.splice(0, selState.length);
    }
    if(selState.includes(props.id)){
      selState.splice(selState.indexOf(props.id), 1);
    }else{
      selState.push(props.id);
    }

    selected.value = !selected.value;
    emit('select');
  }
}

const marker = computed(() => {
  if(selected.value) return '#ff8d2a';
  if(deleted.value) return 'red';
  if(hovered.value) return 'blue';
})

const style = computed(() => {
  if(deleted.value) return 'background-color: rgba(255, 0, 0, 0.1)';
  if(hovered.value) return select ? 'background-color: #eaf0fe; cursor: pointer' : 'background-color: #eaf0fe';
  if(selected.value) return 'background-color: rgba(255, 141, 42, .1); cursor: pointer';
  return '';
})

const hovered = computed(() => {
  if(hover && mouseover.value && !deleted.value && !selected.value) return true
  return false;
})

</script>

<style scoped lang='scss'>

@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

  .tr{
    @include flex(row, flex-start, center);
    @include rem(height, 40px);
    border-bottom:0.15rem solid $borderColor;
    width: 100%;

    .marker{
      @include rem(min-width, 5px);
      @include rem(height, 40px);
      background-color: none;
    }

    .action{
      @include rem(min-width, 40px);
      @include rem(height, 40px);
      @include flex(row, center, center);
      color: grey;

      .delete, .edit{
        @include rem(width, 40px);
        @include flex(row, center, center);
        top: 0;
        color: gray;
        cursor: pointer;
      }
    }

  }
  .tr:nth-child(even) {
    background-color: #f5f5f5;
  }


</style>