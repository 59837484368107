import { defineStore } from 'pinia';
import { reactive, ref } from 'vue';
import { useConfigStore } from '@/stores/configStore';
import { useOrganizationStore } from '@/stores/organizationStore';
import { cloneDeep } from 'lodash';

export const useSettingsStore = defineStore('settings', {
  state: () => ({
    defaultSettings: [],
    customSettings: {},
    displaySettings: reactive([]),
    displayNodeId: ref(''),
    settingsChanged: ref(false),
    isLoading: ref(false),
    alert: ref()
  }),

  actions: {
    async getSettings() {
      const configStore = useConfigStore();
      const organizationStore = useOrganizationStore();

      this.isLoading = true;
      this.reset();

			let params = {
				orgId: configStore.organization.id,
				divId: '',
				buId: '',
				muId: '',
				idToQuery: organizationStore.selectedNode.id,
				queryType: "settings",
        AccessToken: configStore.client.authData.accessToken
			};

			switch (organizationStore.selectedNode.type) {
				case 'division':
					params.divId = organizationStore.selectedNode.id;
					break;
				case 'businessUnit':
					params.buId = organizationStore.selectedNode.id;
					params.divId = organizationStore.selectedNode.division.id;
					break;
				case 'managementUnit':
					params.muId = organizationStore.selectedNode.id;
					params.buId = organizationStore.selectedNode.businessUnit.id;
					params.divId = organizationStore.selectedNode.division.id; ``
					break;
				case 'agent':
					params.agentId = organizationStore.selectedNode.id;
					params.buId = organizationStore.selectedNode.buId;
					break;
				default:
					break;
			}

			const axios = await configStore.getAxios();
			const retVal = await axios.post('/get-cockpit-settings', params);
      // console.log(`retVal -> ${JSON.stringify(retVal, null, 2)}`);
      const storedSettings = retVal.data.body.body;

      // Populate the default and custom settings but only the display settings IF we have anything stored at this level.
      this.defaultSettings = storedSettings.defaultSettings;
      this.customSettings = storedSettings.customSettings;

      if (!Array.isArray(storedSettings.customSettings) && storedSettings.customSettings) {
        this.displaySettings = cloneDeep(storedSettings.defaultSettings);

        // Merge the default values and the custom ones
        // Can't use lodash as we want to minimise stored JSON
        if (this.customSettings) {
          for (const customSetting of Object.keys(this.customSettings)) {
            for (const displaySetting of this.displaySettings) {
              const opt = displaySetting.sectionData.find(setting => setting.optionName === customSetting);
              if (opt) {
                opt.selectedValue = this.customSettings[customSetting];
                break;
              }
            }
          }
        }
      }

      this.displayNodeId = organizationStore.selectedNode.id;
      this.isLoading = false;
      return;
    },

    async saveSettings(removeEmpty = false) {
      const organizationStore = useOrganizationStore();
      let success = { ...this.alert, title: 'Settings saved', message: 'Settings saved succesfully.', type: 'success', enabled: true, timeout: 3000 };
      let failure = { ...this.alert, title: 'Error saving Settings', message: 'Error saving Settings.', type: 'failure', enabled: true, timeout: 3000 };

      try {
        const configStore = useConfigStore();
        this.isLoading = true;
        this.customSettings = [];
        // Create a Map to aid in searching
        const defaultSettingsMap = new Map();
        this.defaultSettings.forEach((defaultSection) => {
          defaultSection.sectionData.forEach((defaultSetting) => {
            defaultSettingsMap.set(`${defaultSection.sectionName}.${defaultSetting.optionName}`, defaultSetting.selectedValue);
          });
        });

        let currentSection = '';
        let customSection = {};
        // Loop throught the deafult settings and compare them with what is currently set
        this.displaySettings.forEach((displaySection) => {
          if (currentSection != displaySection.sectionName) currentSection = displaySection.sectionName;
          displaySection.sectionData.forEach((displaySetting) => {
            const defaultValue = defaultSettingsMap.get(`${displaySection.sectionName}.${displaySetting.optionName}`);
            // Is the setting non-default?
            if (defaultValue !== undefined && defaultValue !== displaySetting.selectedValue) {
              // Non-Default value found. Add the custom setting and value
              if(displaySetting.visible ?? true) customSection[displaySetting.optionName] = displaySetting.selectedValue;
            }
          });
          this.customSettings = customSection;
        });

        // console.log(`New this.customSettings -> ${JSON.stringify(this.customSettings, null, 2)}`);
        // If we are removing the settings from this level then send an empty object
        if (removeEmpty) this.customSettings = {};

        const params = {
          OrgId: configStore.organization.id,
          Id: organizationStore.selectedNode.id,
          RecordType: "agent_config",
          AttributeName: "settings",
          Settings: this.customSettings
        };

        const axios = await configStore.getAxios();
        await axios.post('/put-cockpit-settings', params)
          .then(() => {
            this.alert = success;
          })
          .catch((err) => {
            console.log(err);
            this.alert = failure;
          })
          .finally(() => {
            this.isLoading = false;
            this.settingsChanged = false;
          });

        return;
      } catch (error) {
        console.error(`Oops -> ${error}`);
      }
    },

    async removeSettings() {
      const configStore = useConfigStore();
      const organizationStore = useOrganizationStore();
      let success = { ...this.alert, title: 'Settings removed', message: 'Settings removed succesfully.', type: 'success', enabled: true, timeout: 3000 };
      let failure = { ...this.alert, title: 'Error removing Settings', message: 'Error removing Settings.', type: 'failure', enabled: true, timeout: 3000 };

      this.isLoading = true;
      this.displaySettings = [];

      let params = {
        OrgId: configStore.organization.id,
        IdToRemove: organizationStore.selectedNode.id,
        AttributeName: "settings",
        RecordType: "agent_config"
      };

      const axios = await configStore.getAxios();
      await axios.post('/delete-cockpit-settings', params)
      .then(() => {
        this.displaySettings = [];
        this.customSettings = {};
        this.alert = success;
      })
      .catch((err) => {
        console.log(err);
        this.alert = failure;
      })
      .finally(() => {
        this.isLoading = false;
      });

      return;
    },

    async showDefaultSettings() {
      this.displaySettings = cloneDeep(this.defaultSettings);
      this.settingsChanged = true;
    },

    async reset() {
      this.settingsChanged = false;
      this.customSettings = {};
      this.displayNodeId = '';
      this.displaySettings = [];
    }
  },
})
