import { defineStore } from 'pinia';
import { ref, reactive } from 'vue';
import { cloneDeep, isEmpty } from 'lodash';
import { useConfigStore } from './configStore';
import { useOrganizationStore } from './organizationStore';

export const useColorStore = defineStore('color', {
	state: () => ({
		currentColorTab: 'generalTab',
		displayColours: reactive([]),
		displayNodeId: ref(''),
		originalColorData: ref([]),
		currentColorDisplayValues: reactive([]),
		customColorOptions: {},
		isLoading: ref(false),
		selectedColorChanged: ref(false),
		showAddColors: ref(false),
		alert: ref()
	}),

	actions: {
		async getColors(initialLoad = false, tabName = 'general') {
			// console.log(`getColors -> ${tabName}`);
			const configStore = useConfigStore();
			const organizationStore = useOrganizationStore();

			this.isLoading = true;
			this.reset();

			let params = {
				orgId: configStore.organization.id,
				divId: '',
				buId: '',
				muId: '',
				idToQuery: organizationStore.selectedNode.id,
				queryType: "colors"
			};

			switch (organizationStore.selectedNode.type) {
				case 'division':
					params.divId = organizationStore.selectedNode.id;
					break;
				case 'businessUnit':
					params.buId = organizationStore.selectedNode.id;
					params.divId = organizationStore.selectedNode.division.id;
					break;
				case 'managementUnit':
					params.muId = organizationStore.selectedNode.id;
					params.buId = organizationStore.selectedNode.businessUnit.id;
					params.divId = organizationStore.selectedNode.division.id; ``
					break;
				case 'agent':
					params.agentId = organizationStore.selectedNode.id;
					params.buId = organizationStore.selectedNode.buId;
					break;
				default:
					break;
			}

			const axios = await configStore.getAxios();
			const retVal = await axios.post('/get-cockpit-settings', params);
			// console.log(`retVal -> ${JSON.stringify(retVal, null, 2)}`);

			const storedColors = retVal.data.body.body;
			// console.log(`storedColors -> ${JSON.stringify(storedColors, null, 2)}`);

			// Populate the default and custom settings but only the display settings IF we have anything stored at this level.
			this.customColorOptions = storedColors.customSettings;
			// console.log(`this.customColorOptions -> ${JSON.stringify(this.customColorOptions, null, 2)}`);
			
			if (!Array.isArray(storedColors.customSettings)) {
				this.displayColours = cloneDeep(storedColors.defaultSettings);
				// Merge the default values and the custom ones
				// Can't use lodash as we want to minimise stored JSON
				if (this.customColorOptions) {
					for (const customColor of Object.keys(this.customColorOptions)) {
						for (const tab of this.displayColours) {
							for (const section of tab.tabData) {
								const opt = section.sectionData.find(setting => setting.optionName === customColor);
								if (opt) {
									opt.selectedValue = this.customColorOptions[customColor];
									break;
								}
							}
						}
					} 
				}
				this.originalColorData = cloneDeep(this.displayColours);
				const tabs = this.displayColours.find(tab => tab.tabName === tabName);
				this.currentColorDisplayValues = tabs ? tabs : {};
			}
			else {
				this.originalColorData = cloneDeep(storedColors.defaultSettings);
				this.showAddColors = true;
			}

			// console.log(`this.displayColours -> ${JSON.stringify(this.displayColours, null, 2)}`);
			this.isLoading = false;
			this.displayNodeId = organizationStore.selectedNode.id;
			return;
		},

		async setInitialValues(tabName = 'general') {
			const tabs = this.displayColours.find(tab => tab.tabName === tabName);
			this.currentColorDisplayValues = tabs ? tabs : {};
		},

		async setCurrentColorDisplayValues() {
			const tab = this.displayColours.find(tab => tab.tabName === this.currentColorTab.slice(0, -3));
			this.currentColorDisplayValues = tab ? tab : {};
		},

		async addColors() {
			this.displayColours = cloneDeep(this.originalColorData);
			this.currentColorDisplayValues = this.displayColours.find(tab => tab.tabName === this.currentColorTab.slice(0, -3));
			this.selectedColorChanged = true;
			this.showAddColors = false;
			// this.saveColors();
		},

		async saveColors() {
			const organizationStore = useOrganizationStore();

			try {
				const configStore = useConfigStore();
				this.isLoading = true;
				this.customColorOptions = {};
				let success = { ...this.alert, title: 'Colors saved', message: 'Colors saved succesfully.', type: 'success', enabled: true, timeout: 3000 };
				let failure = { ...this.alert, title: 'Error saving Colors', message: 'Error saving Colors.', type: 'failure', enabled: true, timeout: 3000 };

				// Create a Map to aid in searching
				const colorsDefaultMap = new Map();

				this.originalColorData.forEach((tab) => {
					tab.tabData.forEach((section) => {
						section.sectionData.forEach((setting) => {
							colorsDefaultMap.set(`${tab.tabName}.${section.sectionName}.${setting.optionName}`, setting.selectedValue);
						});
					});
				});

				let updatedColors = {};
				// Loop throught the deafult settings and compare them with what is currently set
				this.displayColours.forEach((tab) => {
					tab.tabData.forEach((section) => {
						section.sectionData.forEach((setting) => {
							const defaultValue = colorsDefaultMap.get(`${tab.tabName}.${section.sectionName}.${setting.optionName}`);
							// Is the setting non-default ?
							if (defaultValue !== undefined && defaultValue !== setting.selectedValue) {
								updatedColors[setting.optionName] = setting.selectedValue;
							}
						});
					});
				});

				const params = {
					OrgId: configStore.organization.id,
					Id: organizationStore.selectedNode.id,
					RecordType: "agent_config",
					AttributeName: "colors",
					Settings: updatedColors
				};

				const axios = await configStore.getAxios();
				await axios.post('/put-cockpit-settings', params)
					.then(() => {
						this.alert = success;
					})
          .catch((err) => {
            console.log(err);
            this.alert = failure;
          })
					.finally(() => {
						this.isLoading = false;
						this.selectedColorChanged = false;
						this.showAddColors = false;
					});

				return;
			} catch (error) {
				console.error(`Oops -> ${error}`);
			}
		},

		async removeColors() {
			const configStore = useConfigStore();
			const organizationStore = useOrganizationStore();
			let success = { ...this.alert, title: 'Colors removed', message: 'Colors removed succesfully.', type: 'success', enabled: true, timeout: 3000 };
			let failure = { ...this.alert, title: 'Error removing Colors', message: 'Error removing Colors.', type: 'failure', enabled: true, timeout: 3000 };

			this.isLoading = true;
			// this.displaySettings = [];

			let params = {
				OrgId: configStore.organization.id,
				IdToRemove: organizationStore.selectedNode.id,
				AttributeName: "colors",
				RecordType: "agent_config"
			};

			const axios = await configStore.getAxios();
			// await axios.post('/delete-cockpit-settings', params)
			await axios.post('/delete-cockpit-settings', params)
				.then(() => {
					this.displayAppearances = [];
					this.customAppearances = {};
					this.alert = success;
				})
				.catch((err) => {
					console.log(err);
					this.alert = failure;
				})
				.finally(() => {
					this.reset();
					this.showAddColors = true;
					this.isLoading = false;
				});

			return;
		},

		async revertColors() {
			// Replace the current displayed colors with the original mapped colors
			this.displayColours = cloneDeep(this.originalColorData);
			// Reset the display values
			this.setCurrentColorDisplayValues();
			this.selectedColorChanged = false;
		},

		async reset() {
			this.displayColours = [];
			this.displayNodeId = '';
			this.currentColorDisplayValues = [];
			this.selectedColorChanged = false;
			this.showAddColors = false;
		}
	}

})
