import { defineStore } from 'pinia';
import { reactive, ref } from 'vue';
import { useConfigStore } from './configStore';
import { useOrganizationStore } from './organizationStore';
import { cloneDeep, isEmpty } from 'lodash';

export const useAppearanceStore = defineStore('appearance', {
  state: () => ({
    defaultAppearances: [],
    customAppearances: {},
    displayNodeId: ref(''),
    displayAppearances: reactive([]),
    appearancesChanged: ref(false),
    isLoading: ref(false),
    alert: ref()
  }),

  actions: {
    async getAppearances() {
      const configStore = useConfigStore();
      const organizationStore = useOrganizationStore();

      this.isLoading = true;
      this.reset();

			let params = {
				orgId: configStore.organization.id,
				divId: '',
				buId: '',
				muId: '',
				idToQuery: organizationStore.selectedNode.id,
				queryType: "appearance"
			};

			switch (organizationStore.selectedNode.type) {
				case 'division':
					params.divId = organizationStore.selectedNode.id;
					break;
				case 'businessUnit':
					params.buId = organizationStore.selectedNode.id;
					params.divId = organizationStore.selectedNode.division.id;
					break;
				case 'managementUnit':
					params.muId = organizationStore.selectedNode.id;
					params.buId = organizationStore.selectedNode.businessUnit.id;
					params.divId = organizationStore.selectedNode.division.id; ``
					break;
				case 'agent':
					params.agentId = organizationStore.selectedNode.id;
					params.buId = organizationStore.selectedNode.buId;
					break;
				default:
					break;
			}

      const axios = await configStore.getAxios();
      const retVal = await axios.post('/get-cockpit-settings', params);
      // console.log(`retVal -> ${JSON.stringify(retVal, null, 2)}`);
      const storedAppearances = retVal.data.body.body;
      // const storedAppearances = JSON.parse(await post('/get-cockpit-appearance', params)).body;

      // Populate the default and custom settings but only the display settings IF we have anything stored at this level.
      this.defaultAppearances = storedAppearances.defaultSettings;
      this.customAppearances = storedAppearances.customSettings;

      if (!Array.isArray(storedAppearances.customSettings) && storedAppearances.customSettings) {
        this.displayAppearances = cloneDeep(storedAppearances.defaultSettings);
        // console.log(`this.displayAppearances -> ${JSON.stringify(this.displayAppearances, null, 2)}`);
        // Merge the default values and the custom ones
        // Can't use lodash as we want to minimise stored JSON
        if (this.customAppearances) {
          for (const customAppearance of Object.keys(this.customAppearances)) {
            for (const displaySetting of this.displayAppearances) {
              const opt = displaySetting.sectionData.find(setting => setting.optionName === customAppearance);
              if (opt) {
                opt.selectedValue = this.customAppearances[customAppearance];
                break;
              }
            }
          }
        }
      }

      this.displayNodeId = organizationStore.selectedNode.id;
      this.isLoading = false;
      return;
    },

    async saveAppearances(removeEmpty = false) {
      const organizationStore = useOrganizationStore();
      let success = { ...this.alert, title: 'Appearances saved', message: 'Appearances saved succesfully.', type: 'success', enabled: true, timeout: 3000 };
      let failure = { ...this.alert, title: 'Error saving Appearances', message: 'Error saving Appearances.', type: 'failure', enabled: true, timeout: 3000 };

      try {
        const configStore = useConfigStore();
        this.isLoading = true;
        this.customAppearances = [];
        // Create a Map to aid in searching
        const defaultAppearancesMap = new Map();
        this.defaultAppearances.forEach((defaultSection) => {
          defaultSection.sectionData.forEach((defaultSetting) => {
            defaultAppearancesMap.set(`${defaultSection.sectionName}.${defaultSetting.optionName}`, defaultSetting.selectedValue);
          });
        });

        console.log(`defaultAppearancesMap -> ${JSON.stringify(defaultAppearancesMap, null, 2)}`);

        let currentSection = '';
        let customSection = {};
        // Loop throught the deafult settings and compare them with what is currently set
        this.displayAppearances.forEach((displaySection) => {
          if (currentSection != displaySection.sectionName) currentSection = displaySection.sectionName;
          displaySection.sectionData.forEach((displaySetting) => {
            const defaultValue = defaultAppearancesMap.get(`${displaySection.sectionName}.${displaySetting.optionName}`);
            // Is the setting non-default ?
            if (defaultValue !== undefined && defaultValue !== displaySetting.selectedValue) {
              // Non-Default value found. Check if we have already created a section for this setting
              customSection[displaySetting.optionName] = displaySetting.selectedValue;
            }
          });
          this.customAppearances = customSection;
        });

        // If we are removing the settings from this level then send an empty object
        if (removeEmpty) this.customAppearances = {};

        const params = {
          OrgId: configStore.organization.id,
          Id: organizationStore.selectedNode.id,
          RecordType: "agent_config",
          AttributeName: "appearance",
          Settings: this.customAppearances
        };

        const axios = await configStore.getAxios();
        await axios.post('/put-cockpit-settings', params)
          .then(() => {
            this.alert = success;
          })
          .catch((err) => {
            console.log(err);
            this.alert = failure;
          })
          .finally(() => {
            this.isLoading = false;
            this.appearancesChanged = false;
          });

        return;
      } catch (error) {
        console.error(`Oops -> ${error}`);
      }
    },

    async removeAppearances() {
      const configStore = useConfigStore();
      const organizationStore = useOrganizationStore();
      let success = { ...this.alert, title: 'Appearances removed', message: 'Appearances removed succesfully.', type: 'success', enabled: true, timeout: 3000 };
      let failure = { ...this.alert, title: 'Error removing Appearances', message: 'Error removing Appearances.', type: 'failure', enabled: true, timeout: 3000 };

      this.isLoading = true;
      let params = {
        OrgId: configStore.organization.id,
        IdToRemove: organizationStore.selectedNode.id,
        AttributeName: "appearance",
        RecordType: "agent_config"
      };

      const axios = await configStore.getAxios();
      await axios.post('/delete-cockpit-settings', params)
      .then(() => {
        this.displayAppearances = [];
        this.customAppearances = {};
        this.alert = success;
      })
      .catch((err) => {
        console.log(err);
        this.alert = failure;
      })
      .finally(() => {
        this.isLoading = false;
      });

      return;
    },

    async showDefaultAppearances() {
      this.displayAppearances = cloneDeep(this.defaultAppearances);
      this.appearancesChanged = true;
    },

    async reset() {
			this.appearancesChanged = false;
			this.customAppearances ={};
			this.displayNodeId = '';
			this.displayAppearances= [];
		}
  }
})
