<template>
  <div class="overlay">
    <div class="new-language">
      <div class="new-language-content">
        <div class="title">
          <h5>Create a new Language</h5>
          <button class="close-button" title="Close" @click="closeDialog()">
            <font-awesome-icon icon="fad fa-circle-xmark" />
          </button>
        </div>
        <div class="item">
          <div class="item-label">
            <label>Name:</label>
          </div>
          <div class="item-content">
            <dropdown :options="languageOptions" @optionSelected="optionSelected"></dropdown>
            <!-- <MDBSelect v-model:options="isoLanguages" v-model:selected="selectedLanguage" filter/> -->
          </div>
        </div>
      </div>
      <div class="new-language-footer">
        <button title="Save" @click="createLanguage" id="create-language-button">Create
          Language...</button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, defineEmits, ref } from 'vue';
import ISO6391 from 'iso-639-1';
import { useLanguagesStore } from '@/stores/languagesStore.js';
import dropdown from '@/components/select/dropdown.vue';

const selectedOption = ref('');

const languagesStore = useLanguagesStore();

const closeDialog = () => {
  languagesStore.showNewDialog = false;
}

const languageOptions = computed(() => {
  return ISO6391.getAllNames();
});

const optionSelected = (option) => {
  selectedOption.value = option;
  console.log('option selected', option);
}

const createLanguage = () => {
  console.log('create language', selectedOption.value);
  const isoCode = ISO6391.getCode(selectedOption.value);

  if (isoCode) {
    const params = {
      language: selectedOption.value,
      iso639: isoCode
    };
    console.log (`params: ${params.language}, ${params.iso639}`)
    languagesStore.addLanguage(params);
  }

  closeDialog();
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
@import '@/assets/scss/mixins';

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;

  .new-language {
    width: 20em;
    // top: 200px;
    // left: 30%;
    // right: 30%;
    background-color: #fff;
    color: #7F7F7F;
    padding: 10px;
    border: 2px solid #ccc;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    -khtml-border-radius: 5px;
    -moz-box-shadow: 0 1px 5px #333;
    -webkit-box-shadow: 0 1px 5px #333;
    z-index: 101;

    .new-language-content {
      display: flex;
      flex-direction: column;
      width: 100%;

      .title {
        display: flex;
        height: 2em;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #ccc;
        color: black;

        .close-button {
          background-color: transparent;
          border: none;
          cursor: pointer;
          outline: none;
          align-items: center;
          margin-bottom: 10px;
        }
      }

      .item {
        display: flex;
        justify-content: space-between;
        padding: 0.5em;
        align-items: center;
        flex-direction: row;
        border-bottom: solid;
        border-width: 1px;
        border-color: lightgrey;
        font-size: 12px;

        .item-label {
          width: 20%;
          color: #2a60c8;
          font-weight: 500;
          font-size: 14px;
        }

        .item-content {
          flex: 1;
        }
      }
    }

    .new-language-footer {
      display: flex;
      font-size: 14px;
      padding-top: 10px;
    }
  }
}

.language-input {
  border: none;
  font-size: 14px;
}

::placeholder {
  color: rgb(189, 189, 189);
}

.language-isocode {
  width: 25%;
}

.new-language-footer button {
  background-color: #e4e9f0;
  flex: 1;
  border-radius: 4px;
}

.new-language-footer button:hover:enabled {
  background-color: #d3dae2;
}

textarea:focus,
input:focus {
  outline: none;
}
</style>
