<template>
  <div class="c-sections">
    <div class="color-section" v-if="currentColorDisplayValues.length !== 0"
      v-for="section in currentColorDisplayValues.tabData">
      <bd-card :headerStyles="{ fontSize: '1em', fontWeight: 500 }">
        <template #header>{{ section.sectionTitle }}</template>
        <template #body>
          <ul class="list-group">
            <li class="list-group-item" v-for="item in section.sectionData">
              <div class="setting-item">
                <Color :data="item"></Color>
              </div>
            </li>
          </ul>
        </template>
      </bd-card>
    </div>
  </div>
</template>

<script setup>
import Color from '@/views/Colors/ColorSetting.vue';
import bdCard from '@/components/card/cockpitcard.vue';
import { useColorStore } from '@/stores/colorStore.js';
import { useOrganizationStore } from '@/stores/organizationStore.js';
import { computed } from 'vue';
import { storeToRefs } from 'pinia';
import { isEmpty } from 'lodash';

const colorStore = useColorStore();
const organizationStore = useOrganizationStore();
const { currentColorDisplayValues } = storeToRefs(colorStore);
const { selectedNode } = storeToRefs(organizationStore);

// Create a computed property that depends on the currentTab property in the store
const data = computed(() => colorStore.displayColours);

const props = defineProps({
  tabName: {
    type: String,
    default: 'generalTab'
  }
});

</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
@import '@/assets/scss/mixins';

.c-sections {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: flex-start;
  padding: 10px 10px 5px 10px;
  height: calc(100vh - 140px);
  overflow-x: auto;
  gap: 2rem;

  .color-section {
    width: 17em;

    .card {
      margin: 0px;
      min-width: 15em;

      .setting-item {
        background-color: $bgColorExtraLight;
      }
    }
  }
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

li {
  padding: 0em 0em 0.5em 0em;
}

.no-options {
  padding: 2px;
  font-size: 1.5rem;
  font-weight: 700;

  .no-options-body {
    font-size: 12px;
    font-weight: 400;
  }
}
</style>