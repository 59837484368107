<template>
  <div class="setting-container">
    <div class="setting-icon" @click="data.valueType === 'modal' ? isModalOpen=true : {}">
      <font-awesome-icon :icon="props.data.icon" />
    </div>
    <div class="setting-detail">
      <div class="item-title" @click="data.valueType === 'modal' ? isModalOpen=true : {}">
        <div class="title">{{ data.title }}</div>
        <div v-if="data.valueType === 'modal'" style="margin-right:1rem">
          <font-awesome-icon icon="far fa-pencil" />
        </div>
        <div v-if="data.valueType === 'truefalse'" class="toggle-button">
          <label class="toggle-switchy" data-style="rounded" data-text="false" data-size="xs">
            <input :checked="data.selectedValue" v-model="data.selectedValue" type="checkbox"
              @change="settingsChanged = true">
            <span class="toggle">
              <span class="switch"></span>
            </span>
          </label>
        </div>
      </div>
      <div v-if="data.valueType === 'dropdown'" class="item-value">
        <select class="form-select" v-model="data.selectedValue" @change="settingsChanged = true">
          <option v-for="option in data.values" :value="option.id">
            {{ option.name }}
          </option>
        </select>
      </div>
      <div v-if="data.valueType === 'pulldown'" class="item-value">
        <select class="form-select" v-model="data.selectedValue" @change="settingsChanged = true">
          <option v-for="option in data.values" :value="option.id">
            {{ option.name }}
          </option>
        </select>
      </div>
      <div v-if="data.valueType === 'pulldown'" class="item-value">
        <select class="form-select" v-model="data.selectedValue" @change="settingsStore.settingsChanged = true">
          <option v-for=" option  in  data.values " :value="option.id">
            {{ option.name }}
          </option>
        </select>
        <!-- <Dropdown :setting="data" :items="data.values" :selectedId="data.selectedValue"></Dropdown> -->
      </div>
      <div v-if="data.valueType === 'number' || data.valueType === 'string'" class="item-value">
        <!-- {{ data.selectedValue }} -->
        <input v-model="data.selectedValue" v-if="data.valueType === 'number'" type="number" min="0" max="999"
          @change="settingsChanged = true" class="number-setting">
        <!-- <input type="text"> -->
      </div>
    </div>
  </div>
  <teleport to="body">
    <bd-modal v-if="isModalOpen" @close="isModalOpen=false">
      <template v-slot:header>
        <div class="modal-header">
          <font-awesome-icon :icon="data.icon" />
          <div>{{ data.title }}</div>
          <font-awesome-icon :icon="['far', 'circle-xmark']" @click="isModalOpen=false" style="cursor:pointer"/>
        </div>
      </template>
      <template v-slot:content>
        <div class="activity-codes">
          <div class="code" v-for="code of sortedActivityCodes">
            <div>{{code.name}}</div>
            <div class="toggle-button">
              <label class="toggle-switchy" data-style="rounded" data-text="false" data-size="xs">
                <input :checked="data.selectedValue.includes(code.id)" type="checkbox" @change.stop="addRemoveItem(data, code)">
                <span class="toggle">
                  <span class="switch"></span>
                </span>
              </label>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <bd-button @click="isModalOpen=false" size="lg">Proceed</bd-button>
      </template>
    </bd-modal>
  </teleport>

</template>

<script setup>
import { ref, toRefs, onMounted, computed} from "vue";
import { useSettingsStore } from '@/stores/settingsStore.js';
import { useOrganizationStore } from '@/stores/organizationStore.js';
import { useConfigStore } from '@/stores/configStore.js';
import BdModal from '@/components/modals/regular/regular.vue';
import BdButton from '@/components/button/regular/button.vue';

const isModalOpen = ref(false);
const organizationStore = useOrganizationStore();
const settingsStore = useSettingsStore();
const configStore = useConfigStore();

const { selectedNode } = toRefs(organizationStore);
const { settingsChanged } = toRefs(settingsStore);
const { activityCodes } = toRefs(configStore);

const props = defineProps({
  data: {
    type: Object,
    default: {}
  }
});

const sortedActivityCodes = computed(() => {
  return activityCodes.value.sort((a, b) => a.name.localeCompare(b.name));
});

const addRemoveItem = (data, code) => {
  if (data.selectedValue.includes(code.id)) {
    data.selectedValue = data.selectedValue.filter(item => item !== code.id);
  } else {
    data.selectedValue.push(code.id);
  }
  settingsChanged.value = true;
};

onMounted(() => {
  if (props.data.valueType === 'modal') {
    const buId = selectedNode.value.type == 'businessUnit' ? selectedNode.value.id : selectedNode.value.parentId;
    configStore.getActivityCodes(buId);
  }
});


</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
@import '@/assets/scss/mixins';

.setting-container {
  display: grid;
  grid-template-columns: 25px 1fr;
  grid-auto-flow: column;
  font-size: 11px;
  font-weight: 400;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 0.5em;

  .setting-icon {
    vertical-align: middle;
    font-size: 1.25em;
    color: $buddyOrange;
  }

  .setting-detail {
    .item-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .title {
        padding: 2px 15px 0px 0px;
        text-overflow: ellipsis;
        font-weight: 500;
      }
    }

    .item-value {
      display: flex;
    }
  }
}

.number-setting {
  width: 45px;
  border-width: inherit;
}

.form-select {
  border: none;
  font-size: 12px;
  padding: 2px;
  background-color: transparent;
  flex: 1;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5em;
  border-bottom: 1px solid #ccc;
}

.activity-codes {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 40rem;
  width: 48rem;
  margin: 1rem;
  overflow-y: auto;
  border: 1px solid #ccc;
  background-color: rgba($color: #ffffff, $alpha: .5);
  border-radius: 5px;
  .code{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
    border-bottom: 1px solid #ccc;
  }
}
</style>