import { ref, computed, onMounted, watch, toRefs } from 'vue'
import { defineStore } from 'pinia'
import purecloud from 'purecloud-platform-client-v2';
import axios from 'axios';
import { useOrganizationStore } from '@/stores/organizationStore';
import { useSettingsStore } from '@/stores/settingsStore';
import { useLanguagesStore } from '@/stores/languagesStore';
import { useAppearanceStore } from '@/stores/appearanceStore';
import logo from '../assets/images/logo.png';
import { isEmpty } from 'lodash';

export const useConfigStore = defineStore('config', () => {
  const splashed = ref(false);
  const me = ref(null);
  const name = ref('');
  const image = ref('');
  // const alert = ref({});
  const environment = ref(null);
  const client = ref(null);
  const clientId = ref(null);
  const location = ref(null);
  const region = ref(null);
  const isLoading = ref(false);
  const apiVersion = ref('v1')
  const errors = ref([]);
  const organization = ref(null);
  const hasPermissions = ref(true);
  const missingPermissions = ref([]);
  const showPermissionsError = ref(false);
  const activityCodes = ref([]);

  const path = computed(() => {
    return environment.value + '/cockpit-service/' + apiVersion.value;
  })

  //topleveldomain
  const tld = computed(() => {
    return environment.value == 'prd' ? 'com' : 'cloud';
  });

  const baseUrl = computed(() => {
    return `https://${region.value}.wfmbuddy.${tld.value}/${path.value}`;
  });

  const apikey = computed(() => {
    return environment.value == 'dev' ? 'cAkeFR5jAify6QV24diE4UGhDrKoqH4BEpfKY090' : environment.value == 'tst' ? 'RyCydgjcPL8YxfgxjRLVN1HNlWMjE4G971syHow1' : environment.value == 'acc' ? 'IVp2arpm8A1QjXhvkIXtB684eWIVvRNn8RoQFrSJ' : 'ZW9W99w7VY7MGxdf4O8qN4N5NmI9zhhX2KR2VhYZ';
  });

  const logout = () => {
    localStorage.removeItem('purecloud_auth_data');
    client.value.logout();
  };

  const getActivityCodes = async (buId) => {
    const wfm = new purecloud.WorkforceManagementApi();
    try {
      const data = await wfm.getWorkforcemanagementBusinessunitActivitycodes(buId);
      activityCodes.value = data.entities
        .filter((item) => item.active)
        .map((item) => ({
          ...item,
          name: item.name || item.category
        }));
    } catch (error) {
      console.log(error);
    }
  };


  const getGCOrganization = async () => {
    const org = new purecloud.OrganizationApi();
    await org.getOrganizationsMe().then((data) => {
      organization.value = data;
    }).catch((error) => { console.log(error) });
  }

  const buildOrgTree = async () => {
    const organizationStore = useOrganizationStore();
    organizationStore.isLoading = true;
    let params = {
      OrgId: organization.value.id,
      AccessToken: client.value.authData.accessToken
    };

    const axios = await getAxios();
    const response = await axios.post('/get-organisation', params);
    let orgTreeData = JSON.parse(response.data).body;

    orgTreeData = sortData(orgTreeData);

    organizationStore.setOrgTreeData(orgTreeData);
  }

  const sortData = (data) => {
    // Sort function
    const sortByName = (a, b) => a.name.localeCompare(b.name);

    // Recursive sort and filter
    const recursiveSortAndFilter = (items) => {
      return items
        .filter(item => item.authorized !== false) // filter out items with authorized: false
        .sort(sortByName)
        .map(item => ({
          ...item,
          children: item.children ? recursiveSortAndFilter(item.children) : []
        }));
    };

    // Initial sort
    if (!isEmpty(data)) return recursiveSortAndFilter(data).sort(sortByName)
    else return {};
  };

  const setDefaultSettings = async () => {
    // TODO - wrap with Try/Catch
    const axios = await getAxios();
    const retVal = await axios.get('/get-default-settings');

    const settingsStore = useSettingsStore();
    settingsStore.defaultSettings = retVal.data.settings;
    const languagesStore = useLanguagesStore();
    languagesStore.defaultLanguages = retVal.data.languages;
    languagesStore.setInitialTemplate();
    const appearanceStore = useAppearanceStore();
    appearanceStore.defaultAppearances = retVal.data.appearance;
  }

  const getAxios = () => {
    console.log(`baseUrl: ${baseUrl.value}`);
    return new Promise((resolve, reject) => {
      getToken().then((token) => {
        resolve(axios.create({
          baseURL: baseUrl.value,
          headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
            'X-Api-Key': apikey.value
          }
        }));
      }).catch((error) => {
        reject(error);
      });
    });
  };

  const getToken = () => {
    return new Promise((resolve, reject) => {

      const storage = localStorage.getItem('purecloud_auth_data');
      const token = storage ? JSON.parse(storage).accessToken : null;
      const expire = storage ? JSON.parse(storage).tokenExpiryTime : null;

      client.value = purecloud.ApiClient.instance;
      client.value.setEnvironment(location.value);

      if (token != null && expire != null && expire > Date.now()) {
        resolve(token);
      } else {
        client.value.setPersistSettings(true);
        client.value.loginImplicitGrant(clientId.value, window.location.origin).then((data) => {
          resolve(data.accessToken);
        }).catch((error) => {
          reject(error);
        });
      }
    });
  };

  const init = async (callback) => {

    const storage = localStorage.getItem('purecloud_auth_data');
    const token = storage ? JSON.parse(storage).accessToken : null;
    const expire = storage ? JSON.parse(storage).tokenExpiryTime : null;

    apikey.value = environment.value == 'dev' ? 'dev-portal-1234567890' : environment.value == 'tst' ? 't4LY@fHTx$XusTkTv*Ar*' : environment.value == 'acc' ? 'acc-portal-1234567890' : 'cJ7e$vsoM9x@44FDnnCp8';

    client.value = purecloud.ApiClient.instance;
    client.value.setEnvironment(location.value);

    if (token != null && expire != null && expire > Date.now()) {
      client.value.setAccessToken(token);
      if (typeof callback === 'function') callback();
    } else {

      client.value.setPersistSettings(true);
      client.value.loginImplicitGrant(clientId.value, window.location.origin).then((data) => {
        client.value.setAccessToken(data.accessToken);
        if (typeof callback === 'function') callback();
      }).catch((error) => {
        console.log(error)
      });
    }

    try {
      isLoading.value = true;
      await getMe();
      // Get the Genesys Cloud Organization details
      await getGCOrganization();
      // Build the Tree Structure of the Genesys Cloud Organization
      await buildOrgTree();
      // Set the default settings for the Pinia Stores
      await setDefaultSettings();
      isLoading.value = false;
    }
    catch (error) {
      console.error(error);
      isLoading.value = false;
      // if (error instanceof ValidationError) {
      //   alert(error, 'Error');
      // }
    }
  };

  // Helper function to convert a wildcard string (with *) into a regular expression
  function wildcardToRegExp(wildcard) {
    const regexString = wildcard.replace(/\*/g, '.*'); // Replace * with .*
    return new RegExp(`^${regexString}$`); // Anchor regex to match the entire string
  }

  const getMe = () => {

    return new Promise((resolve, reject) => {
      const instance = new purecloud.UsersApi();
      let opts = {
        expand: ["organization", "authorization"]
      };

      instance.getUsersMe(opts)
        .then((data) => {
          me.value = data;
          name.value = data.name;
          image.value = data.images ? data.images[1].imageUri : logo;

          // Check for required permissions
          const specifiedValues = [
            "authorization:division:view",
            "groups:team:view:*",
            "ui:myOrganization:view",
            "wfm:businessUnit:view:*",
            "wfm:managementUnit:view:*"
          ];
          // Check if all specified wildcard values exist in the permissions array
          specifiedValues.forEach(specifiedValue => {
            const regex = wildcardToRegExp(specifiedValue); // Convert wildcard to regex
            if (!data.authorization.permissions.some(permission => regex.test(permission))) missingPermissions.value.push(specifiedValue);
          });

          if(missingPermissions.value.length === 0) resolve();
          else {
            hasPermissions.value = false;
            showPermissionsError.value = true;
            reject();
          }
        })
        .catch((err) => {
          console.error("There was a failure calling getUsersMe");
          console.error(err);
          reject(err);
        });
    });
  };

  const menu = ref([
    { id: 0, name: 'Settings', link: 'settings', active: true },
    { id: 1, name: 'Colors', link: 'colors', active: false },
    { id: 2, name: 'Appearance', link: 'appearance', active: false },
    // { id: 3, name: 'Languages', link: 'languages', active: false },
    // { id: 4, name: 'Messages', link: 'messages', active: false },
    { id: 5, name: 'Broadcast', link: 'broadcast', active: false },
    { id: 6, name: 'QRCode', link: 'qrcode', active: false },
  ]);

  const menuData = computed(() => {
    const arrayWithActiveSet = [];
    menu.value.forEach((item, index) => {
      if (index === activeItem.value) {
        item.active = true;
      } else {
        item.active = false;
      }
      arrayWithActiveSet.push(item);
    });

    return arrayWithActiveSet;

  });

  const menuColorData = computed(() => {
    const arrayWithActiveSet = [];
    colorMenu.value.forEach((item, index) => {
      if (index === activeItem.value) {
        item.active = true;
      } else {
        item.active = false;
      }
      arrayWithActiveSet.push(item);
    });

    return arrayWithActiveSet;

  });

  const menuLanguageData = computed(() => {
    const arrayWithActiveSet = [];
    languageMenu.value.forEach((item, index) => {
      if (index === activeItem.value) {
        item.active = true;
      } else {
        item.active = false;
      }
      arrayWithActiveSet.push(item);
    });

    return arrayWithActiveSet;

  });

  const colorMenu = ref([
    { id: 0, name: 'General', link: 'colors-general', active: true },
    { id: 1, name: 'Schedule', link: 'colors-schedule', active: false },
    //{ id: 2, name: 'Preferences', link: 'colors-preferences', active: false },
    { id: 3, name: 'Time Off', link: 'colors-timeoff', active: false },
    { id: 4, name: 'Agent Info', link: 'colors-agentinfo', active: false }
  ]);

  const languageMenu = ref([
    { id: 1, name: 'General', link: 'language-general', active: true },
    { id: 2, name: 'PopupForms', link: 'language-popupForms', active: false },
    { id: 3, name: 'Login', link: 'language-login', active: false },
    { id: 4, name: 'Availability', link: 'language-availability', active: false },
    { id: 5, name: 'TimeOff', link: 'language-timeoff', active: false },
    { id: 6, name: 'Trades', link: 'language-trades', active: false },
    { id: 7, name: 'Scheduling', link: 'language-scheduling', active: false },
    { id: 8, name: 'Overtime', link: 'language-overtime', active: false },
  ]);

  const activeItem = ref(0);

  return {
    me, splashed, menuData, menuColorData, menuLanguageData, clientId, location, environment, region, baseUrl, apikey, errors, organization,
    isLoading, client, hasPermissions, missingPermissions, showPermissionsError, activityCodes, getAxios, init, logout, getMe, getActivityCodes
  }
})
