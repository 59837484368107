import './assets/scss/main.scss'
import './assets/fonts.css'
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { QuillEditor } from '@vueup/vue-quill'
import clickOutside from '@/directives/clickOutside'

import App from './App.vue'
import router from './router'

// import fontawesome icons 
import FontAwesomeIcon from '../src/js/fontawesomeicons.js';

import '@vueup/vue-quill/dist/vue-quill.snow.css';

const app = createApp(App)

app.directive('click-outside', clickOutside)

app.use(createPinia())
app.component('font-awesome-icon', FontAwesomeIcon)
app.component('QuillEditor', QuillEditor)
app.use(router)

app.mount('#app')
