<template>
  <div class="error">
    <svg width="400" height="400" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.15" d="M100 168C137.555 168 168 137.555 168 100C168 62.4446 137.555 32 100 32C62.4446 32 32 62.4446 32 100C32 137.555 62.4446 168 100 168Z" fill="#0090F6"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M112.416 43.6042C112.011 40.8495 112.355 37.9978 114.072 35.6347C119.019 28.8261 127.923 36.5677 127.6 41.3624C127.276 46.1571 121.352 55.1295 118.466 54.2193C117.316 53.8563 115.597 51.91 114.26 49.2691L109.631 59.6786L101.669 54.7035L112.416 43.6042Z" fill="#B28B67"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M127.343 43.4456C127.555 42.9306 127.686 42.2929 127.806 41.755C127.96 41.0616 127.977 40.3598 127.945 39.6684C127.884 38.3667 127.643 37.0748 127.236 35.8733C126.492 33.6787 125.004 31.7512 122.955 30.7946C121.92 30.3116 120.757 30.0962 119.58 30.212C118.517 30.3166 117.318 30.6994 116.353 30.1773C115.302 29.6091 114.934 28.135 114.469 27.0756C113.944 25.8812 113.255 24.8194 112.297 23.9752C110.691 22.5611 108.631 22.1168 106.477 22.8188C104.185 23.5658 101.794 25.2681 101.359 27.8835C101.278 28.3697 101.28 28.8239 101.408 29.2786C101.524 29.6955 101.774 30.2176 101.638 30.6816C101.492 31.1747 100.804 31.4985 100.432 31.793C99.8986 32.2149 99.3832 32.6855 99.0709 33.3112C98.5581 34.3388 98.5873 35.6438 98.8306 36.6818C99.0782 37.7381 99.6493 38.612 100.4 39.3196C100.902 39.792 101.448 40.2211 102.057 40.542C102.359 40.7013 102.665 40.7802 102.985 40.8912C103.296 40.9991 103.403 41.1667 103.541 41.4609C104.179 42.8278 105.165 43.8183 106.469 44.4894C107.57 45.0557 108.935 45.5549 110.238 45.3693C111.684 45.1632 112.353 44.0504 112.865 42.7348C113.028 42.9999 113.636 43.4811 113.538 42.7689C113.499 42.4868 113.254 42.2287 113.124 41.9955C112.972 41.7241 112.84 41.4133 112.79 41.0941C112.587 39.8048 114.383 38.5605 115.469 38.8494C117.083 39.2786 116.187 41.3878 116.843 42.4883C117.22 43.121 118.045 43.0939 118.71 42.9555C119.601 42.7701 120.423 42.3003 121.201 41.7856C121.842 41.3618 122.469 40.9179 123.113 40.5003C123.413 40.3053 124.345 39.805 124.316 39.3538C125.407 39.7658 126.234 40.8446 126.738 41.8963C126.946 42.331 127.059 42.737 127.001 43.2387C126.989 43.3462 126.921 43.6462 127.022 43.7237C127.211 43.8679 127.29 43.575 127.343 43.4456Z" fill="#00416F"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M82.0051 84.4001L96.3156 133.835L103.046 170.951H109.802L103.059 84.4001H82.0051Z" fill="#997659"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M75.574 84.4001C76.5726 110.081 76.1788 124.088 75.9553 126.421C75.7318 128.755 74.4631 144.381 65.8175 171.734H72.8441C84.2938 145.493 88.2907 129.867 89.6039 126.421C90.9171 122.976 94.7955 108.969 100.458 84.4001H75.574Z" fill="#B28B67"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M81.6982 84.4001C85.4206 101.411 91.8587 128.564 101.013 165.86H110.894C111.624 127.579 108.519 102.384 103.142 84.4001H81.6982Z" fill="#0066AE"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M75.524 84.4001C76.502 110.081 73.9676 135.382 66.117 166.259H76.6596C88.1303 140.41 96.3517 115.235 101.97 84.4001H75.524Z" fill="#0090F6"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M64.816 176.765L65.2064 170.525C67.4925 171.188 70.3559 170.928 73.7966 169.745C77.4923 172.34 82.1436 174.113 87.7507 175.063L87.7507 175.063C88.1863 175.137 88.4796 175.55 88.4057 175.986C88.4008 176.015 88.3942 176.043 88.3861 176.072L87.8532 177.935H73.7966H65.5969L64.816 176.765Z" fill="#191847"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M101.539 176.765L101.929 170.525C104.215 171.188 107.079 170.928 110.519 169.745C114.215 172.34 118.866 174.113 124.473 175.063L124.473 175.063C124.909 175.137 125.202 175.55 125.128 175.986C125.123 176.015 125.117 176.043 125.109 176.072L124.576 177.935H110.519H102.32L101.539 176.765Z" fill="#191847"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M147.072 83.6543L147.072 88.0543L149.472 84.0543" fill="#E58E3E"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M126.574 79.1888L141.982 80.6318C145.35 79.6751 148.357 79.1959 151.001 79.1942C151.712 79.3376 152.778 79.8531 150.785 80.85C148.793 81.8468 146.742 82.9851 146.87 83.7935C146.999 84.602 148.619 84.6881 148.615 85.8849C148.613 86.6828 146.058 86.2199 140.949 84.4961L127.729 86.4542L126.574 79.1888ZM75.0984 76.4684L82.7078 79.4238C72.159 92.8397 66.5184 99.9413 65.7858 100.729C64.1375 102.5 64.698 106.378 64.8033 108.386C61.888 108.546 63.7385 104.518 60.0396 104.776C56.6633 105.012 53.5563 106.595 51.033 103.279C50.7228 102.871 50.6405 101.753 52.0016 101.427C55.3926 100.613 60.4979 98.8704 61.4281 98.0088C62.6965 96.8339 67.2533 89.6538 75.0984 76.4684Z" fill="#B28B67"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M147.072 83.6544L147.072 71.2L155.2 73.2L153.527 76.3695L149.472 84.0544L147.072 83.6544Z" fill="#E58E3E"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M110.947 58.5813C118.718 74.4614 127.938 80.5866 138.608 76.9568C138.608 76.9568 136.541 83.0945 132.408 95.3697C121.296 93.5089 113.489 87.0955 108.987 76.1294C103.354 62.4079 104.476 45.3569 110.947 58.5813Z" fill="#0066AE"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M80.0661 87.1721L108.146 97.9512C107.726 79.6028 109.642 64.8889 113.895 53.8093L105.91 49.2931C95.1343 57.4666 88.1837 69.9544 80.0661 87.1721Z" fill="#DDE3E9"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M105.728 49.2302C105.73 49.2286 105.732 49.227 105.734 49.2255L106.286 49.4377C106.939 49.7145 107.931 50.1425 109.262 50.7216L109.059 57.3687C106.532 64.5983 102.268 79.4857 96.2667 102.031L77.8595 94.965C77.3336 96.4793 76.8336 98.0196 76.3596 99.5859L62.6729 94.3321C73.605 71.9722 87.9552 56.9354 105.723 49.2216L105.728 49.2302Z" fill="#0090F6"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M86.4226 76.2923C83.3672 85.8177 82.2114 92.7489 82.9552 97.0862L77.8093 95.1108C80.1591 88.3158 83.0302 82.043 86.4226 76.2924L86.4226 76.2923Z" fill="black" fill-opacity="0.1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M86.2502 85.353L96.9499 84.6087L95.3268 88.8371L86.2502 85.353Z" fill="white" fill-opacity="0.2"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M134.798 176H177.4C177.133 174.133 175.8 173.2 173.4 173.2C169.8 173.2 169.8 166.8 161.8 166C153.8 165.2 149.602 170.8 142.2 172.4C134.798 174 127.226 176 134.798 176Z" fill="#781DD4"/>
      <path d="M135.8 181.2C137.126 181.2 138.2 180.752 138.2 180.2C138.2 179.648 137.126 179.2 135.8 179.2C134.475 179.2 133.4 179.648 133.4 180.2C133.4 180.752 134.475 181.2 135.8 181.2Z" fill="#781DD4"/>
      <path d="M157.6 82C158.263 82 158.8 81.5523 158.8 81C158.8 80.4477 158.263 80 157.6 80C156.938 80 156.4 80.4477 156.4 81C156.4 81.5523 156.938 82 157.6 82Z" fill="#781DD4"/>
      <path d="M158.8 86.6C159.021 86.6 159.2 86.421 159.2 86.2C159.2 85.9791 159.021 85.8 158.8 85.8C158.579 85.8 158.4 85.9791 158.4 86.2C158.4 86.421 158.579 86.6 158.8 86.6Z" fill="#781DD4"/>
      <path d="M153.4 143.6C154.063 143.6 154.6 143.063 154.6 142.4C154.6 141.737 154.063 141.2 153.4 141.2C152.737 141.2 152.2 141.737 152.2 142.4C152.2 143.063 152.737 143.6 153.4 143.6Z" fill="#781DD4"/>
      <path d="M177.8 182C179.788 182 181.4 181.373 181.4 180.6C181.4 179.827 179.788 179.2 177.8 179.2C175.812 179.2 174.2 179.827 174.2 180.6C174.2 181.373 175.812 182 177.8 182Z" fill="#781DD4"/>
      <path d="M159.8 185.6C161.788 185.6 163.4 184.973 163.4 184.2C163.4 183.427 161.788 182.8 159.8 182.8C157.812 182.8 156.2 183.427 156.2 184.2C156.2 184.973 157.812 185.6 159.8 185.6Z" fill="#781DD4"/>
      <path d="M142.2 167.6C144.188 167.6 145.8 166.973 145.8 166.2C145.8 165.427 144.188 164.8 142.2 164.8C140.211 164.8 138.6 165.427 138.6 166.2C138.6 166.973 140.211 167.6 142.2 167.6Z" fill="#781DD4"/>
      <path d="M169.4 170C171.388 170 173 169.373 173 168.6C173 167.827 171.388 167.2 169.4 167.2C167.412 167.2 165.8 167.827 165.8 168.6C165.8 169.373 167.412 170 169.4 170Z" fill="#781DD4"/>
      <path d="M158 180.8C158.994 180.8 159.8 180.442 159.8 180C159.8 179.558 158.994 179.2 158 179.2C157.006 179.2 156.2 179.558 156.2 180C156.2 180.442 157.006 180.8 158 180.8Z" fill="#781DD4"/>
      <path d="M147.4 168C148.063 168 148.6 167.821 148.6 167.6C148.6 167.379 148.063 167.2 147.4 167.2C146.737 167.2 146.2 167.379 146.2 167.6C146.2 167.821 146.737 168 147.4 168Z" fill="#781DD4"/>
      <path d="M139.8 179.2C140.462 179.2 141 179.021 141 178.8C141 178.579 140.462 178.4 139.8 178.4C139.137 178.4 138.6 178.579 138.6 178.8C138.6 179.021 139.137 179.2 139.8 179.2Z" fill="#781DD4"/>
      <path d="M173.4 178.8C174.063 178.8 174.6 178.621 174.6 178.4C174.6 178.179 174.063 178 173.4 178C172.737 178 172.2 178.179 172.2 178.4C172.2 178.621 172.737 178.8 173.4 178.8Z" fill="#781DD4"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M148.8 71.6C148.8 71.8189 150.933 72.3522 155.2 73.2L154.8 74C154.951 76.4 155.351 77.3334 156 76.8C156.974 76 156.974 72.4 156 72C155.026 71.6 148.8 71.2718 148.8 71.6Z" fill="#781DD4"/>
    </svg>
    <div class="message" v-for="message of messages">{{message}}</div>
  </div>
</template>

<script setup>

import { computed } from 'vue';
import { useRoute } from 'vue-router';

const route = useRoute();
const messages = computed(() => route.query.messages || '[]');

</script>

<style scoped lang="scss">
  @import '@/assets/scss/variables.scss';
  @import '@/assets/scss/mixins.scss';

  .error{
    @include flex(column, center, center);
    @include rem(margin-top, 150px);
    svg{
      width: 25%;
      height: 25%;
    }
    .message{
      font-weight: bold;
    }
  }
</style>