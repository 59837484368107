<template>
  <div class="settings">
    <div class="message" v-if="!loading && (isEmpty(selectedNode) || isEmpty(displaySettings))">
      <font-awesome-icon icon="far fa-circle-info" />
      <div v-if="!isLoading && isEmpty(selectedNode)">Please select an organization level to view details</div>
      <div v-if="!isLoading && !isEmpty(selectedNode) && isEmpty(displaySettings)">
        No settings stored for {{ selectedNode.type == 'businessUnit' ? 'business unit' : selectedNode.type == 'managementUnit' ? 'management unit' : selectedNode.type }} "{{selectedNode.name}}"
      </div>
      <div v-if="isLoading">Loading ...</div>
    </div>
    <div class="settings-section" v-if="displaySettings.length !== 0" v-for="section in settings">
      <bd-card :headerStyles="{ fontSize: '1em', fontWeight: 500 }">
        <template #header>{{ section.sectionTitle }}</template>
        <template #body>
          <ul class="list-group">
            <template v-for="item in section.sectionData">
              <li v-if="item.visible ?? true" class="list-group-item">
                <div class="setting-item">
                  <Setting :data="item"></Setting>
                </div>
              </li>
            </template>
          </ul>
        </template>
      </bd-card>
    </div>
  </div>
  <teleport to="body">
    <bd-alert :alert="alert"></bd-alert>
  </teleport>
</template>

<script setup>
import { toRefs, computed, ref } from 'vue';
import Setting from '@/views/Settings/Setting.vue';
import { storeToRefs } from 'pinia';
import { isEmpty } from 'lodash';
import { useSettingsStore } from '@/stores/settingsStore';
import { useOrganizationStore } from '@/stores/organizationStore.js';
import { useConfigStore } from '@/stores/configStore';
import bdCard from '@/components/card/cockpitcard.vue';
import bdAlert from '@/components/alert/alert.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

const settingsStore = useSettingsStore();
const organizationStore = useOrganizationStore();
const configStore = useConfigStore();
const { isLoading : loading } = toRefs(configStore);
const { selectedNode} = storeToRefs(organizationStore);
const { isLoading } = storeToRefs(settingsStore);

const { alert, displaySettings } = toRefs(settingsStore);

const settings = computed(() => {
  const result = [];
  for (let section of displaySettings.value) {
    let settings = section.sectionData;
    for (let setting of settings) {
      if (setting.reference) {
        const referenceOption = settings.find(item => item.optionName == setting.reference);
        if (referenceOption && referenceOption.selectedValue != setting.referenceId) {
          setting.visible = false;
        } else {
          setting.visible = true;
        }
      }
    }

    result.push(section);
  }
  return result;
});


</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
@import '@/assets/scss/mixins';

.message {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  margin: 1.5rem 0 0 1rem;
  padding: 1rem;
  font-size: 1.3rem;
  font-weight: 500;
  background-color: #ff8c00;
  border-radius: 5px;
  width: 99%;
  color: rgba(255, 255, 255, 0.9);
  border: 1px solid $borderColor;
  height: 4.5rem;
}

.settings {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: flex-start;
  padding: 3px 10px 5px 5px;
  height: calc(100vh - 80px);
  overflow-x: auto;

  .settings-section {
    max-width: 23em;
    width: calc(25% - 10px);
    box-sizing: border-box;
    padding: 10px;
    min-width: 250px;

    .card {
      margin: 0px;
      min-width: 15em;

      .setting-item {
        background-color: $bgColorExtraLight;
      }
    }
  }
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

li {
  padding: 0em 0em 0.5em 0em;
}
</style>