<template>
  <div class="overlay">
    <div class="message-container">
      <bd-card :headerStyles="{ fontSize: '1.5rem', fontWeight: 700, borderBottom: '1px solid #cbcbcb' }">
        <template #header>Save System Message...</template>
        <template #body>
          <div class="body">
            <div class="division-label"><label>Select a Division</label></div>
            <bdDropdown ref="selectDivision" :items="divisions" displayProperty="text" keyProperty="value" placeholder="Select a division..."
              @item-selected="updateDivisionId"></bdDropdown>
            <!-- ref="selectSystemMessage" :items="broadcastStore.systemMessages" displayProperty="title" keyProperty="id"
                @item-selected="handleMessageSelected" -->
          </div>
        </template>
        <template #footer>
          <div class="footer">
            <bd-button size="sm" icon="far fa-save" @click="saveSystemMessage()">Save</bd-button>
            <bd-button size="sm" icon="far fa-times" @click="closeDialog()">Cancel</bd-button>
          </div>
        </template>
      </bd-card>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, onMounted } from 'vue';
import { useBroadcastStore } from '@/stores/broadcastStore.js';
import { useOrganizationStore } from '@/stores/organizationStore.js';
// import bdSelect from '@/components/select/general/select.vue';
import bdDropdown from '@/components/select/general/selectSystemMessage.vue';
import bdCard from '@/components/card/cockpitcard.vue';
// import bdAlert from '@/components/alert/alert.vue';
import bdButton from '@/components/button/regular/button.vue';
// import iconButton from '@/components/button/icon/button.vue'

const divisionId = ref('');

const props = defineProps({
    message: {
      type: Object,
      default: {}
    }
  });

const broadcastStore = useBroadcastStore();
const organizationStore = useOrganizationStore();

const closeDialog = () => {
  broadcastStore.showSystemMessageDialog = false;
}

const divisions = computed(() => {
  let retVal = [];
  organizationStore.orgTreeData.forEach((division) => {
    retVal.push({ value: division.id, text: division.name });
  });
  return retVal;
});

const saveSystemMessage = async () => {
  await broadcastStore.saveSystemMessage(props.message, divisionId.value);
  closeDialog();
}

function updateDivisionId(event) {
  console.log(JSON.stringify(event));
  divisionId.value = event.value;
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
@import '@/assets/scss/mixins';

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;

  .message-container {
    // display: flex;
    // flex-direction: column;
    min-width: 30rem;

    .body {
      display: flex;
      flex-direction: row;
      padding: 1rem;

      .division-label {
        align-content: center;
        padding-right: 1rem;
      }
    }

    .footer {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      padding: 1rem;
      gap: 0.5rem;
    }
  }
}
</style>
