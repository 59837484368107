import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useConfigStore } from './configStore';
import { cloneDeep, isEmpty } from 'lodash';

export const useOrganizationStore = defineStore('organization', () => {
  const isLoading = ref(false);
  const allowBroadcast = ref(false);
  const currentTab = ref('settingsTab');
  const orgTreeData = ref([]);
  const displayData = ref([]);
  const selectedNode = ref({});
  const checkedNodes = ref([]);

  const getIcon = (type) => {
    switch (type) {
      case 'division':
        return 'fal fa-globe';
      case 'businessUnit':
        return 'fal fa-city';
      case 'managementUnit':
        return 'fal fa-building';
      case 'agent':
        return 'fal fa-user';
      case 'workteam':
        return 'fal fa-users';
      default:
        return '';
    }
  };

  const updateIcons = (node) => {
    if (!node) return;
    node.icon = getIcon(node.type);
    if (node.children) {
      for (const child of node.children) {
        updateIcons(child);
      }
    }
  };

  const setOrgTreeData = async (data) => {
    isLoading.value = true;
    if (data.length > 0) {
      orgTreeData.value = data;
      displayData.value = cloneDeep(data);
      displayData.value.forEach(item => (item.isSelected = false));
      orgTreeData.value.forEach(item => updateIcons(item));
    }
    isLoading.value = false;
  };

  const addAgentsToMU = async (node) => {
    const configStore = useConfigStore();
    isLoading.value = true;
    configStore.preload = true;

    const params = {
      ManagementUnitId: node.id,
      Host: `api.${configStore.location}`,
      AccessToken: configStore.client.authData.accessToken,
    };

    const axios = await configStore.getAxios();
    const retVal = await axios.post('/get-mu-agents', params);
    const agents = retVal.data;

    if (!isEmpty(agents[0])) {
      const updateValue = (arr, val) => {
        for (const obj of arr) {
          if (obj.id === val) return (obj.children = agents);
          if (obj.children) {
            const result = updateValue(obj.children, val);
            if (result) return result;
          }
        }
        return undefined;
      };
      updateValue(orgTreeData.value, node.id);
      displayData.value = orgTreeData.value;
    }
    isLoading.value = false;
    configStore.preload = false;
  };

  const updateCheckedNodes = (node) => {
    if (node.isSelected) {
      if (!checkedNodes.value.some(item => item.id === node.id)) checkedNodes.value.push(node);
    } else {
      checkedNodes.value = checkedNodes.value.filter(item => item.id !== node.id);
    }
    allowBroadcast.value = checkedNodes.value.length > 0;
  };

  return {
    isLoading,
    allowBroadcast,
    currentTab,
    orgTreeData,
    displayData,
    selectedNode,
    checkedNodes,
    getIcon,
    setOrgTreeData,
    addAgentsToMU,
    updateCheckedNodes,
  };
});
