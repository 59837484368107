<template>
  <div class="mainview-container">
    <div class="overlay" v-if="showLoading">
      <div class="overlay-content">
        <div class="loader"></div>
      </div>
    </div>
    <div class="nav-container">
      <bd-navigation :items="menuData" @tabSelected="tabSelected">
        <bd-button size="sm" icon="fas fa-layer-plus" v-if="!hasDataSet &&
          !isEmpty(organizationStore.selectedNode)
          && showAdd()" @click="showDefaults()">Add</bd-button>
        <bd-button size="sm" icon="fas fa-floppy-disk" v-if="hasDataSet
          && organizationStore.currentTab != 'languagesTab'
          && (appearanceStore.appearancesChanged || settingsStore.settingsChanged)" @click="save()">Save</bd-button>
        <bd-button size="sm" icon="fas fa-trash-can" v-if="allowDelete" @click="showConfirmDelete = true">Delete</bd-button>
      </bd-navigation>
    </div>
    <div class="splitter-container">
      <bd-splitter :barStyle="{ backgroundColor: '#f5f5f5', width: '10px' }" :left="250" id="main-splitter">
        <template #left>
          <left-panel />
        </template>
        <template #right>
          <right-panel />
        </template>
      </bd-splitter>
    </div>
  </div>
  <teleport to="body">
    <bd-idiot v-if="showConfirmDelete" :message="[`${setDisplayMessage}`, 'Are you sure?']"
      @close="showConfirmDelete = false" @proceed="deleteSettings"></bd-idiot>
  </teleport>
  <teleport to="body">
    <bd-popup v-if="showPermissionsError">
      <template #header>
        Permission Required!
      </template>
      <template #body>
        <p>You do not have the correct permissions for WFMBuddy Cockpit. Please contact your administrator to request access to the following:</p>
        <ul>
          <li v-for="permission in missingPermissions">{{ permission }}</li>
        </ul>
      </template>
      <template #footer>
        <div class="permissions-footer">
          <bd-button size="sm" @click="showPermissionsError = false">Close</bd-button>
        </div>
      </template>
    </bd-popup>
  </teleport>
</template>

<script setup>
import { toRefs, ref, watch, computed } from 'vue';
import { storeToRefs } from 'pinia';
import { isEmpty } from 'lodash';
import bdNavigation from '@/components/nav/navbar.vue';
import bdSplitter from '@/components/splitter/splitter.vue'
import bdButton from '@/components/button/regular/button.vue';
import bdIdiot from '@/components/modals/idiot/idiot.vue'
import leftPanel from '@/views/LeftPanel.vue';
import rightPanel from '@/views/RightPanel.vue';
import { useConfigStore } from '@/stores/configStore';
import { useSettingsStore } from '@/stores/settingsStore.js';
import { useOrganizationStore } from '@/stores/organizationStore.js';
import { useLanguagesStore } from '@/stores/languagesStore.js';
import { useAppearanceStore } from '@/stores/appearanceStore.js';
import { useColorStore } from '@/stores/colorStore.js';
import { useBroadcastStore } from '@/stores/broadcastStore.js';
import { useQRStore } from '@/stores/qrcodeStore.js';
import { useMessagingStore } from '@/stores/messagingStore.js';
import bdPopup from '@/components/popup/popupNew.vue'

const configStore = useConfigStore();
const organizationStore = useOrganizationStore();
const settingsStore = useSettingsStore();
const languagesStore = useLanguagesStore();
const appearanceStore = useAppearanceStore();
const colorStore = useColorStore();
const broadcastStore = useBroadcastStore();
const qrStore = useQRStore();
const messagingStore = useMessagingStore();

const { showPermissionsError, missingPermissions } = storeToRefs(configStore);

const { currentTab } = storeToRefs(organizationStore);
watch(currentTab, (newTab) => {
  // organizationStore.getOrganisation(newTab);
  if (Object.keys(organizationStore.selectedNode).length > 0) getSettings();
})

const { menuData } = toRefs(configStore);
const showConfirmDelete = ref(false);
const allowDelete = computed(() => {
  if (hasDataSet && (organizationStore.currentTab === 'messagesTab' || organizationStore.currentTab === 'languagesTab')) return false;
  else return true;
});

const showLoading = computed(() => {
  let retVal = false;
  if (settingsStore.isLoading || languagesStore.isLoading || appearanceStore.isLoading || messagingStore.isLoading || colorStore.isLoading || broadcastStore.isLoading || qrStore.isLoading) retVal = true;
  return retVal;
});

const hasDataSet = computed(() => {
  // Check a node from the OrgTree is selected first
  switch (organizationStore.currentTab) {
    case 'settingsTab':
      return !isEmpty(settingsStore.displaySettings);
    case 'messagesTab':
      return !isEmpty(languagesStore.displayLanguages);
    case 'colorsTab':
      return !isEmpty(appearanceStore.displayColours);
    case 'appearanceTab':
      return !isEmpty(appearanceStore.displayAppearances);
    case 'broadcastTab':
      return !isEmpty(settingsStore.displayBroadcast);
    case 'languagesTab':
      return !isEmpty(languagesStore.displayLanguages);
    default:
      // console.log('Default')
      return false;
  }
});

const showAdd = () => {
  let retVal = false;

  switch (organizationStore.currentTab) {
    case 'settingsTab':
      if (organizationStore.selectedNode.type != 'division') retVal = true;
      break;
    case 'appearanceTab':
      retVal = true;
      break;
    default:
      break;
  }
  return retVal;
}

const getSettings = () => {
  switch (organizationStore.currentTab) {
    case 'settingsTab':
      if (organizationStore.selectedNode != 'division' && organizationStore.selectedNode.id != settingsStore.displayNodeId) return settingsStore.getSettings();
      else return;
    case 'messagesTab':
      if (organizationStore.selectedNode.id != messagingStore.displayNodeId) return messagingStore.getMessaging();
      else return;
    case 'colorsTab':
      if (organizationStore.selectedNode.id != colorStore.displayNodeId) return colorStore.getColors();
      else return;
    case 'appearanceTab':
      if (organizationStore.selectedNode.id != appearanceStore.displayNodeId) return appearanceStore.getAppearances();
      else return;
    case 'broadcastTab':
      if (organizationStore.selectedNode.id != broadcastStore.displayNodeId) return broadcastStore.getSystemMessages();
      else return;
    case 'languagesTab':
      if (organizationStore.selectedNode.id != languagesStore.displayNodeId) return languagesStore.getLanguages();
      else return;
    default:
      console.log('Default')
      return false;
  }
}

const save = () => {
  switch (organizationStore.currentTab) {
    case 'settingsTab':
      return settingsStore.saveSettings();
    case 'messagesTab':
    // return languagesStore.saveLanguages();
    case 'colorsTab':
      return appearanceStore.saveColours();
    case 'appearanceTab':
      return appearanceStore.saveAppearances();
    case 'broadcastTab':
      return settingsStore.saveBroadcast();
    case 'languagesTab':
    // return languagesStore.saveLanguages();
    default:
      // console.log('Default')
      return false;
  }
}

const deleteSettings = async () => {
  showConfirmDelete.value = false;
  switch (organizationStore.currentTab) {
    case 'settingsTab':
      await settingsStore.removeSettings();
      break;
    case 'appearanceTab':
      await appearanceStore.removeAppearances();
      break;
    case 'broadcastTab':
      return settingsStore.showDefaultBroadcast();
    case 'languagesTab':
      return languagesStore.showDefaultLanguages();
    default:
      // console.log('Default')
      return false;
  }
}

const showDefaults = () => {
  switch (organizationStore.currentTab) {
    case 'settingsTab':
      return settingsStore.showDefaultSettings();
    case 'messagesTab':
      return languagesStore.showDefaultLanguages();
    case 'colorsTab':
      return appearanceStore.showDefaultColours();
    case 'appearanceTab':
      return appearanceStore.showDefaultAppearances();
    case 'broadcastTab':
      return settingsStore.showDefaultBroadcast();
    case 'languagesTab':
      return languagesStore.showDefaultLanguages();
    default:
      // console.log('Default')
      return false;
  }
}

const setDisplayMessage = computed(() => {
  const message = `This will delete the ${organizationStore.currentTab.slice(0, -3)} for ${organizationStore.selectedNode.name}`
  return message;
})

// This converts the tab to a name so I don't have to refactor all my old code
const tabSelected = (tab) => {
  let tabName;
  switch (tab) {
    case 'Settings':
      tabName = 'settingsTab';
      break;
    case 'Colors':
      tabName = 'colorsTab';
      break;
    case 'Appearance':
      tabName = 'appearanceTab';
      break;
    case 'Messages':
      tabName = 'messagesTab';
      break;
    case 'Broadcast':
      tabName = 'broadcastTab';
      break;
    case 'Languages':
      tabName = 'languagesTab';
      break;
    case 'QRCode':
      tabName = 'qrcodeTab';
      break;
    default:
      tabName = 'settingsTab';
  }
  // console.log('Tab selected: ', tabName);
  organizationStore.currentTab = tabName;
}

function formatMessage() {
  let message = '';
  if (missingPermissions.value.length > 0) {
    message = `You do not have the correct permissions for WFMBuddy Cockpit.\n\rPlease contact your administrator to request access to the following: ${missingPermissions.value.join(', ')}`;
  }
  return message;
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/variables.scss';
@import '@/assets/scss/mixins';

.overlay {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.2);

  .overlay-content {
    position: absolute;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    top: 50%;
    left: 50%;
    text-align: center;

    .loader {
      color: $loader-color;
      font-size: 45px;
      text-indent: -9999em;
      overflow: hidden;
      width: 1em;
      height: 1em;
      border-radius: 50%;
      position: relative;
      transform: translateZ(0);
      animation: mltShdSpin 1.7s infinite ease, round 1.7s infinite ease;
    }

    @keyframes mltShdSpin {
      0% {
        box-shadow: 0 -0.83em 0 -0.4em,
          0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
          0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
      }

      5%,
      95% {
        box-shadow: 0 -0.83em 0 -0.4em,
          0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
          0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
      }

      10%,
      59% {
        box-shadow: 0 -0.83em 0 -0.4em,
          -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em,
          -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
      }

      20% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em,
          -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em,
          -0.749em -0.34em 0 -0.477em;
      }

      38% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em,
          -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em,
          -0.82em -0.09em 0 -0.477em;
      }

      100% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em,
          0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
      }
    }

    @keyframes round {
      0% {
        transform: rotate(0deg)
      }

      100% {
        transform: rotate(360deg)
      }
    }
  }
}

.permissions-footer {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}
</style>

<style>
.nav-bar .logo .wfm {
  flex: none !important;
}
</style>