<template>
  <div class="idiot" @click="$emit('close')">
    <div class="box">
      <div v-if="!isArray" class="text">
        {{message}}
      </div>
      <div v-else class="text" v-for="item of message">
        {{item}}
      </div>
      <div class="buttons">
        <bd-button @click.stop="$emit('proceed')" size="lg">OK</bd-button>
        <bd-button @click.stop="$emit('close')" size="lg">Cancel</bd-button>
      </div>
    </div>
  </div>
</template>

<script setup>

import { toRefs, ref } from 'vue';
import bdButton from '@/components/button/regular/button.vue';

const props = defineProps({
  message: {
    type: [String, Array],
    default: 'Are you sure?',
  }
});

const { message } = toRefs(props);
const isArray = ref(Array.isArray(message.value));

</script>

<style scoped lang="scss">

@import '@/assets/scss/variables.scss';
@import '@/assets/scss/mixins.scss';

.idiot{
  position: absolute;
  @include flex(column, center, center);
  width: 100vw;
  height: 100vh;
  background-color: rgba(117, 168, 255, 0.5);
  z-index: 1000;
  top: 0;

  .box{
    @include flex(column, center, center);
    @include rem(min-width, 300px);
    @include rem(min-height, 200px);
    background-color: #fff;
    font-size: 2rem;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 5px 0 rgba(34, 37, 41, 0.24);


    .text{
      @include rem(padding, 0 20px 0 20px);
      color: #000;
    }
    .buttons{
      @include flex(row, center, center);
      @include rem(padding, 20px 0 0 0);
      @include rem(gap, 10px);
      width: 100%;

    }
  }
}

</style>