<template>
  <div class="setting-container">
    <div class="item-title">{{ data.title }}</div>
    <div class="item-value">
      <input id="setting-value" v-model="data.selectedValue" type="text" class="string-setting" @change="languagesStore.hasLanagugeDataChanged">
    </div>
  </div>
</template>

<script setup>
import { defineProps, computed, watch } from "vue";
import { useLanguagesStore } from '@/stores/languagesStore.js';

const languagesStore = useLanguagesStore();

const props = defineProps({
  data: {
    type: Object,
    default: {}
  }
});
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
@import '@/assets/scss/mixins';

.setting-container {
  display: flex;
  font-size: 11px;
  font-weight: 400;
  user-select: none;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 0.5em;
  align-items: center;

  .item-title {
    text-overflow: ellipsis;
    font-weight: 500;
    width: -webkit-fill-available;
  }

  .item-value .string-setting {
    border: 0;
    font-size: 12px;
    text-overflow:ellipsis;
  }
}

// .setting-container {
//   display: flex;
//   flex: 1;
//   flex-direction: row;
//   align-items: baseline;

//   .title {
//     font-size: 1.25rem;
//     font-weight: 500;
//     margin-bottom: 0.25em;
//     width: 100%;
//     padding-right: 1em;
//   }

//   .string-setting {
//     font-size: 1.25rem;
//     text-overflow:ellipsis;
//     border: none;
//     color: #72a6ff;
//     cursor: pointer;
//   }
// }
// .title {
//   color: #2a60c8;
//   font-family: Roboto, sans-serif;
//   font-size: 12px;
//   font-weight: 700;
//   /* background-color: aqua; */
// }

// .string-setting {
//   border: 0;
//   font-family: Roboto, sans-serif;
//   font-size: 12px;
//   text-overflow:ellipsis;
//   /* background-color: blue; */
// }</style>
