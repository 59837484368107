import { createRouter, createMemoryHistory } from 'vue-router'
import Splash from '@/views/Splash.vue'
import Error from '@/views/Error.vue'
import MainView from '@/views/MainView.vue'
import Settings from '@/views/Settings/SettingsView.vue'
import Colors from '@/views/Colors/ColorsView.vue'
import Appearance from '@/views/Appearance/AppearanceView.vue'
import Messages from '@/views/Messages/MessagesView.vue'
import Broadcast from '@/views/Broadcast/BroadcastView.vue'
import Languages from '@/views/Languages/LanguagesView.vue'
import QRCode from '@/views/QRCode/QRCodeView.vue'

const router = createRouter({
  history: createMemoryHistory(),
  routes: [
    {
      path: '/',
      component: Settings
    },
    {
      name: 'error',
      path: '/error',
      component: Error,
      props: true
    },
    {
      name: 'main',
      path: '/main',
      component: MainView,
    },
    {
      name: 'settings',
      path: '/settings',
      component: Settings
    },
    {
      name: 'colors',
      path: '/colors',
      component: Colors,
      children: [
        {
          path: '/colors-general',
          component: Colors
        },
        {
          path: '/colors-schedule',
          component: Colors
        },
        {
          path: '/colors-preferences',
          component: Colors
        },
        {
          path: '/colors-timeoff',
          component: Colors
        },
        {
          path: '/colors-agentinfo',
          component: Colors
        }
      ]
    },
    {
      name: 'appearance',
      path: '/appearance',
      component: Appearance
    },
    {
      name: 'broadcast',
      path: '/broadcast',
      component: Broadcast
    },
    {
      name: 'languages',
      path: '/languages',
      component: Languages,
      children: [
        {
          path: '/language-general',
          component: Languages
        },
        {
          path: '/language-popupForms',
          component: Languages
        },
        {
          path: '/language-login',
          component: Languages
        },
        {
          path: '/language-availability',
          component: Languages
        },
        {
          path: '/language-timeoff',
          component: Languages
        },
        {
          path: '/language-trades',
          component: Languages
        },
        {
          path: '/language-scheduling',
          component: Languages
        },
        {
          path: '/language-overtime',
          component: Languages
        }
      ]
    },
    {
      name: 'messages',
      path: '/messages',
      component: Messages
    },
    {
      name: 'qrcode',
      path: '/qrcode',
      component: QRCode
    }
  ]
})

export default router