<template>
  <div class="color-container">
    <div class="color-detail">
      <div class="color-detail-title">{{ data.title }}</div>
      <div class="color-detail-body">
        <div class="colorpicker">
          <MDBColorPicker v-model="data.selectedValue" @onChange="setColorValue($event)" />
        </div>
        <div class="input-text">
          <input type="text" class="form-control" v-model="data.selectedValue" @change="setColorValue($event)" />  
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, computed, watch } from "vue";
import { MDBColorPicker } from "mdb-vue-color-picker";
import { useColorStore } from '@/stores/colorStore.js';

const colorStore = useColorStore();

const props = defineProps({
  data: {
    type: Object,
    default: {}
  }
});

const setColorValue = (event) => {
  colorStore.selectedColorChanged = true;
};

</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
@import '@/assets/scss/mixins';

.color-container {
  display: flex;
  flex-direction: column;
  font-family: Roboto, sans-serif;
  font-size: 11px;
  font-weight: 400;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 0.5em;

  .color-detail {
    .color-detail-title {
      padding: 2px 15px 0px 0px;
      text-overflow: ellipsis;
      font-weight: 500;
    }

    .color-detail-body {
      display: flex;
      align-items: center;

      .input-text {
        padding-left: 5px;
        font-size: 1.25rem;
        flex: 1;
      }
    }
  }
}
</style>

<style lang="scss">
.color-detail-body .form-control {
  border: none !important;
  background-color: #ffffff;
  width: 3rem;
  height: 3.2rem;
  cursor: pointer;
}

.input-text .form-control {
  width: 5em;
  text-transform: uppercase
}
</style>
