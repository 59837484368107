<template>
  <div class="treeview-item">
    <div class="treeview-item-toggle" @click="toggleNode(); emitNodeToggled(props.item)" :class="{ 'open': isOpen }">
      <font-awesome-icon icon="fas fa-triangle" v-if="showToggle" class="expand-icon"/>
    </div>
    <div class="treeview-item-checkbox">
      <input v-if="props.multiSelect" type="checkbox" v-model="props.item.isSelected" @change="updateParent(props.item)"/>
    </div>
    <div class="treeview-item-label" @click="labelClicked(props.item)" :style="style">{{ props.item.name }}</div>
  </div>
  <div class="treeview-item-children">
    <treeviewitem v-if="isOpen" v-for="child in props.item.children"
      :item="child"
      :multiSelect="props.multiSelect"
      :activeId="props.activeId"
      @isSelectedChanged="updateParent"
      @nodeLabelClicked="labelClicked"
      @nodeOpened="emitNodeToggled"/>
  </div>
  <div class="loader" v-if="item.type=='managementUnit' && isOpen && isLoading">
    <font-awesome-icon icon="fas fa-circle" class="fa-beat" />
    <font-awesome-icon icon="fas fa-circle" class="fa-beat" />
    <font-awesome-icon icon="fas fa-circle" class="fa-beat" />
    <font-awesome-icon icon="fas fa-circle" class="fa-beat" />
  </div>

</template>

<script setup>
import { ref, computed, toRefs } from 'vue';
import { useOrganizationStore } from '@/stores/organizationStore';

const emit = defineEmits(['isSelectedChanged', 'nodeLabelClicked', 'nodeOpened']);

const organizationStore = useOrganizationStore();
const { isLoading } = toRefs(organizationStore);

const props = defineProps({
  item: Object,
  activeId: String,
  multiSelect: {
    type: Boolean,
    default: false
  }
});

const isOpen = ref(false);

const toggleNode = (item) => {
  isOpen.value = !isOpen.value;
};

const emitNodeToggled = (item) => {
  if (isOpen.value) {
    emit('nodeOpened', item);
  }
};

const updateParent = (item) => {
  emit('isSelectedChanged', { id: item.id, isSelected: item.isSelected });
};

const labelClicked = (item) => {
  emit('nodeLabelClicked', item);
};

const style = computed(() => {
  if (props.activeId == props.item.id) {
    return {
      'background-color': 'rgba(18,102,241,.05)',
      'color': '#1266f1 !important'
    }
  }
});

const showToggle = computed(() => {
  let retVal = true;
  if (props.item.type == 'agent') retVal = false;
  if (props.item.children) {
    if ((props.item.children.length == 0) && (props.item.type != 'managementUnit')) retVal = false;
  }
  else if (props.item.type != 'managementUnit') retVal = false; // Management units may have agents assigned which we haven't queried yet

  return retVal;
});

</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
@import '@/assets/scss/mixins';

.loader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-left: 2rem;
  height: 20px;
  font-size: 7px;
  gap: 3px;
  color: $buddyOrange;

  .fa-beat:nth-child(1) {
    animation-delay: 0s;
  }
  .fa-beat:nth-child(2) {
    animation-delay: 0.2s;
  }
  .fa-beat:nth-child(3) {
    animation-delay: 0.4s;
  }
  .fa-beat:nth-child(4) {
    animation-delay: 0.6s;
  }

}

.treeview-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 20px;
  cursor: pointer;

  .treeview-item-toggle {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: rotate(0deg);
    transition-duration: 0.3s;

    .expand-icon {
      height: 10px;
      width: 10px;
      color: $buddyOrange;
      transform: rotate(90deg);
    }
  }

  .open {
      transform: rotate(90deg);
      transition-duration: 0.3s;
    }

  .treeview-item-label {
    font-size: 12px;
    padding-left: 3px;
    width: -webkit-fill-available;
    border-radius: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .treeview-item-label:hover {
    background-color: rgba(38, 38, 38, .05);
  }
}

.treeview-item-children {
  padding-left: 1rem;
}
</style>