<template>
  <router-link :to="'/'+ link">
    <div :style="{height: props.height}" class="item" @click="publish">
        {{ name }}
        <div v-if="active || chosen" class="active"></div>
    </div>
  </router-link>
</template>

<script setup>

  import { RouterLink } from 'vue-router'
  import { ref, onMounted } from 'vue';

  const active = ref(false);
  const chosen = ref(false);

  const props = defineProps({
    id: Number,
    name: String,
    link: String,
    height: String,
    chosen: {
      type: Boolean,
      default: false
    }
  })

  const emit = defineEmits(['chosen', 'selectedTab']);

  const publish = () => {
    chosen.value = true;
    emit('chosen', props.id, props.name);
  };

  onMounted(() => {
    chosen.value = props.chosen;
  })

</script>

<style scoped lang="scss">

  @import '@/assets/scss/variables.scss';
  @import '@/assets/scss/mixins.scss';

  .item {
    position: relative;
    @include flex(column, center, center);
    font-size: 1.3rem;
    font-weight: 700;
    cursor: pointer;
    color: $textColor;
    min-width: 6em;
    margin-left: 1.1em;

    .active {
      position: absolute;
      bottom: 0;
      height: .4em;
      width: 100%;
      background-color: $activeColor;
    }
  }

  .item:hover {
    color: #000000;
  }

</style>