<template>
  <div class="language-container">
    <div class="language-sections" v-for="section in sections">
      <bd-card :headerStyles="{ fontSize: '1em', fontWeight: 500 }">
        <template #header>{{ section.sectionTitle }}</template>
        <template #body>
          <ul class="list-group">
            <li class="list-group-item" v-for="item in section.sectionData">
              <div class="setting-item">
                <Setting :data="item"></Setting>
              </div>
            </li>
          </ul>
        </template>
      </bd-card>
    </div>
  </div>
  <!-- <div class="language-sections">
    <div class="section" v-for="section in sections">
      <bd-card :headerStyles="{ fontSize: '1em', fontWeight: 700, borderBottom: '1px solid #cbcbcb', padding: '10px 0px 10px 5px' }">
        <template #header>{{ section.sectionTitle }}</template>
        <template #body>
          <div class="section-list">
          <ul class="list-group">
            <li class="list-group-item" v-for="item in section.sectionData">
              <div class="setting-item">
                <Setting :data="item"></Setting>
              </div>
            </li>
          </ul>
          </div>
        </template>
      </bd-card>
    </div>
  </div> -->
</template>

<script setup>
import Setting from '@/views/Languages/LanguageSetting.vue';
import bdCard from '@/components/card/cockpitcard.vue'
import { useLanguagesStore } from '@/stores/languagesStore.js';
import { watch, computed } from 'vue';
import { storeToRefs } from 'pinia';

const languagesStore = useLanguagesStore();

const sections = computed(() => {
  let retVal = [];
  if (Object.keys(languagesStore.currentLanguage).length > 0) {
    const tab = languagesStore.currentLanguage.tabs.find((tab) => tab.tabName === languagesStore.currentLanguageTab.slice(0, -3));
    if (tab) retVal = tab.tabData;
  }
  return retVal;
});

// watch(() => languagesStore.currentLanguageTab, () => {
//   console.log('LanguageSections: currentLanguageTab changed');
//   const tab = languagesStore.currentLanguage.tabs.find((tab) => tab.tabName === languagesStore.currentLanguageTab.slice(0, -3));
//     if (tab) retVal = tab.tabData;
// });

const props = defineProps({
  tabName: {
    type: String,
    default: 'generalTab'
  }
});
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
@import '@/assets/scss/mixins';

.language-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: flex-start;
  padding: 3px 10px 5px 10px;
  height: calc(100vh - 155px);
  overflow-x: auto;

  .language-sections {
    max-width: 23em;
    width: calc(25% - 10px);
    box-sizing: border-box;
    padding: 10px;
    min-width: 250px;

    .card {
      margin: 0px;
      min-width: 15em;

      .setting-item {
        background-color: $bgColorExtraLight;
      }
    }
  }
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

li {
  padding: 0em 0em 0.5em 0em;
}
// .language-sections {
//   display: flex;
//   flex-direction: column;
//   flex-wrap: wrap;
//   flex-wrap: wrap;
//   align-content: flex-start;
//   padding: 3px 10px 5px 10px;
//   // background-color: lawngreen;
//   height: calc(100vh - 145px);
//   overflow-x: auto;

//   .section {
//     // width: calc(10% - 10px);
//     box-sizing: border-box;
//     padding: 10px;
//     min-width: 250px;

//     .card {
//       background-color: #ffffff;
//       // border-bottom: 1px solid #cbcbcb;
//       .card-body {
//       .section-list {
//         display: flex;
//         // flex-direction: column;
//       }

//       .list-group {
//         list-style-type: none;
//         flex: 1;
//         .list-group-item .setting-item {
//           display: flex;
//           flex-direction: row;
//           // background-color: aquamarine;
//           height: 100%;
//           align-items: center;
//         }
//       }
//     }
//     }
    

//   }

// }
</style>